import React from "react";
import dashboard from "../assets/Images/Dashboard.png";
import deletee from "../assets/Images/Delete.png";
import login from "../assets/Images/Login1.png";
import setting from "../assets/Images/Setting.png";


function Please() {
    return (
        <div className="Main">
            <div className="guideHeader">Please Follow instruction </div>
            <div className="guideBoxs">
                <div className="guideBox">
                    <div>
                        <div className="stepBox">1</div>
                        <div className="guideImage">
                            <img src={login} />
                        </div>
                    </div>
                    <div className="guideText">
                        <h5>Step 1 : </h5>
                        <p>
                        You can Login or signup in our website with your credentials.
                        </p>
                    </div>
                </div>
                <div className="guideBoxE">
                    <div className="guideTextBoxE">
                        <h5>Step 2 :</h5>
                        <p>Visit your account dashboard</p>
                    </div>
                    <div className="guideImageBox">
                        <div className="stepBoxE">2</div>
                        <div className="guideImageE">
                            <img src={dashboard} />
                        </div>
                    </div>
                </div>
                <div className="guideBox">
                    <div>
                        <div className="stepBox">3</div>
                        <div className="guideImage">
                            <img src={setting} />
                        </div>
                    </div>
                    <div className="guideText">
                        <h5>Step 3 : </h5>
                        <p>
                        Go to the setting option of dashboard
                        </p>
                    </div>
                </div>
                <div className="guideBoxE">
                    <div className="guideTextBoxE">
                        <h5>Step 4 :</h5>
                        <p>On setting option you find delete account button</p>
                    </div>
                    <div className="guideImageBox">
                        <div className="stepBoxE">4</div>
                        <div className="guideImageE">
                            <img src={deletee} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    );
}

export default Please;

import { Link, useLocation, useNavigate } from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import { useState, useEffect, useRef } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Swal from "sweetalert2";
import axiosClient from "../../Services/Axios/axios";
import { Button, Modal } from "react-bootstrap";
import ScannerModal from "../Events/ScannerModal";
import NotificationTabModal from "./NotificationTabModal";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

const Header = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const [borderShow, setBorderShow] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userDetail, setUserDetail] = useState({});

  const [show, setShow] = useState(false);
  const [notificationModal, setNotificationModal] = useState(false);
  const [showscan, setShowscan] = useState(false);
  const [userNewData, setUserNewData] = useState({});
  const [meriData, setMeriData] = useState();
  const [allNotificationData, setALLNotificationData] = useState([]);

  const [unreadNotificationData, setUnreadNotificationData] = useState([]);
  const modalRef = useRef(null);

  // const userNewData = JSON.parse(localStorage.getItem("userData"));
  const cameracl = () => setShowscan(true);
  const handleClose1 = () => setShowscan(false);
  const videoshow = () => {
    setShow(true);
    localStorage.removeItem("userData");
  };

  const userData = localStorage.getItem("userData");

  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  /* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {
    const header = document.querySelector(".header-section");
    const scrollTop = window.scrollY;
    scrollTop >= 82
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleNotification = () => {
    setNotificationModal(!notificationModal);
  };

  const hundleFilterModal = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setNotificationModal(false);
      // setFilterModalOpen(!filterModalOpen);
    }
  };
  const handleLogout = () => {
    Swal.fire({
      title: "Do you want to logout?",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        navigation("/");
        localStorage.clear();
        Swal.fire({
          title: "Log out",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    });
  };

  const handleProfile = async () => {
    try {
      const response = await axiosClient.get("/users/getByID");
      const userData = response?.data?.data;

      if (response?.data?.error || !userData) return;

      localStorage.setItem("userData", JSON.stringify(userData));
      setUserNewData(userData);
      navigation("/profile");

      // if (
      //   [5, 7].includes(userData.role_type) ||
      //   ([8, 9].includes(userData.role_type) && userData.profile_complete === 1)
      // ) {
      //   navigation("/impact");
      // } else if ([8, 9].includes(userData.role_type)) {
      //   navigation("/profile");
      // }

      getMeritsData();
    } catch (error) {
      console.error("Error fetching profile:", error);
      localStorage.clear();
      navigation("/");
    }
  };

  const getMeritsData = async () => {
    const response = await axiosClient.get(
      "/users/getMeritListing?language=en&pageSize=10&pageNo=1"
    );
    if (!response.data.error) {
      setMeriData(response.data.MeritPoints);
    } else {
      localStorage.clear();
      navigation("/");
    }
  };

  const getAllNotifications = async () => {
    const response = await axiosClient.get("/web/getUserNotification");
    if (!response.data.error) {
      console.log(response.data, "response.data");
      setALLNotificationData(response.data.All);
      setUnreadNotificationData(response.data.Unread);
    }
  };
  useEffect(() => {
    if (userData) {
      const data = JSON.parse(userData);
      setUserDetail(data);
      getAllNotifications();
	  getMeritsData();
    }
  }, [userData]);

  useEffect(() => {
    if (location.pathname === "/") {
      setBorderShow(false);
    } else {
      setBorderShow(true);
    }
  }, [location]);

  useEffect(() => {
    if (notificationModal) {
      document.addEventListener("mousedown", hundleFilterModal);
    } else {
      document.removeEventListener("mousedown", hundleFilterModal);
    }

    // Cleanup on component unmount
    return () => {
      document.removeEventListener("mousedown", hundleFilterModal);
    };
  }, [notificationModal]);
  return (
    <>
      <header
        className={`header-section alother_pgs ${
          borderShow === true ? "bor_botmm" : ""
        }`}
        id="fixedds"
      >
        <div className="container">
          <nav>
            <div className="line justify-content-between">
              <div className="lgo_mg">
                <Link to="/">
                  <img src="/images/w_logo.png" alt="" className="lgo_parts" />
                </Link>
              </div>

              <div className={`top-nav s-12 l-12 ${isMenuOpen ? "open" : ""}`}>
                {userData !== null && (
                  <>
                    {" "}
                    <div className="user_prfilss">
                      <div className="us_mgsss">
                        <img
                          src={`${
                            userDetail?.profile_image
                              ? userDetail?.profile_image
                              : "/images/user_dummy1.png"
                          }`}
                          alt=""
                        />
                      </div>
                      <h4>{userDetail?.first_name} {userDetail?.last_name}</h4>
                      <p>{userDetail?.email}</p>
                    </div>
                    <ul className="usr_linkss_lgo">
                    {(userDetail?.profile_complete === 1 ||
                        userDetail?.role_type === 5 ||
                        userDetail?.role_type === 7) && (
                        <li>
                          <Link to="/impact" onClick={toggleMenu}>
                            <span className="spss_are">
                              <img src="/images/mb_v1.png" alt="" />
                            </span>{" "}
                            Impact
                          </Link>
                        </li>
                        )}

                      <li>
                        <Link to="/Profile" onClick={toggleMenu}>
                          <span className="spss_are">
                            <img src="/images/mb_v2.png" alt="" />
                          </span>{" "}
                          Profile
                        </Link>
                      </li>

                      {/* <li>
                        <Link to="/kyc" onClick={toggleMenu}>
                          <span className="spss_are">
                            <img src="/images/mb_v3.png" alt="" />
                          </span>{" "}
                          KYC
                        </Link>
                      </li> */}

                      <li>
                        <Link
                          to={
                            userDetail?.role_type === 8 ||
                            userDetail?.role_type === 9
                              ? "/kyb"
                              : "/kyc"
                          }
                          onClick={toggleMenu}
                          className={`${
                            location.pathname === "/kyc" ? "act" : ""
                          }`}
                        >
                          <span className="spss_are">
                            <img src="/images/side_m_ic10.png" alt="" />
                          </span>{" "}
                          {userDetail?.role_type === 8 ||
                          userDetail?.role_type === 9
                            ? "KYB"
                            : "KYC"}
                        </Link>
                      </li>


                      {/* <li>
                        <Link to="/tax-information" onClick={toggleMenu}>
                          <span className="spss_are">
                            <img src="/images/mb_v4.png" alt="" />
                          </span>{" "}
                          Tax Information
                        </Link>
                      </li> */}

                        {(userDetail?.profile_complete === 1 ||
                        userDetail?.role_type === 5 ||
                        userDetail?.role_type === 7) && (<li>
                        <Link to="/gratitude-system" onClick={toggleMenu}>
                          <span className="spss_are">
                            <img src="/images/mb_v5.png" alt="" />
                          </span>{" "}
                          Gratitude
                        </Link>
                      </li>)}
                      

                      {(userDetail?.role_type === 8 ||
                        userDetail?.role_type === 9) && (
                        <>
                          {" "}
                          <li>
                            <Link
                              to="/additional-information"
                              className={`${
                                location.pathname === "/additional-information"
                                  ? "act"
                                  : ""
                              }`}
                              onClick={toggleMenu}
                            >
                              <span className="spss_are">
                                <img src="/images/adit_infor1.png" alt="" />
                              </span>{" "}
                              Additional Information
                            </Link>
                          </li>
                        </>
                      )}

                      {/* <li>
                        <Link to="/my-events" onClick={toggleMenu}>
                          <span className="spss_are">
                            <img src="/images/mb_v7.png" alt="" />
                          </span>{" "}
                          Events
                        </Link>
                      </li> */}


                      {(userDetail?.profile_complete === 1 ||
                        userDetail?.role_type === 5 ||
                        userDetail?.role_type === 7) && (
                        <li>
                          <Link to="/my-events" onClick={toggleMenu}>
                            <span className="spss_are">
                              <img src="/images/mb_v7.png" alt="" />
                            </span>{" "}
                            Events
                          </Link>
                        </li>
                      )}

    {userDetail?.role_type === 5 ||
       userDetail?.role_type === 7 ||
         userDetail?.profile_complete === 2|| /*add by me */
          userDetail?.profile_complete === 0 ? null :  (
                        <>
                          {" "}
                          <li>
                            <Link to="/campaign" onClick={toggleMenu}>
                              <span className="spss_are">
                                <img src="/images/mb_v6.png" alt="" />
                              </span>{" "}
                              Campaigns
                            </Link>
                          </li> 
                         
                        </>
                      )}

                      <li>
                        <Link to="/referral" onClick={toggleMenu}>
                          <span className="spss_are">
                            <img src="/images/mb_v9.png" alt="" />
                          </span>{" "}
                          Referral
                        </Link>
                      </li>
                      <li>
                        <Link to="/notification" onClick={toggleMenu}>
                          <span className="spss_are">
                            <img src="/images/notification.png" alt="" />
                          </span>{" "}
                          Notification
                        </Link>
                      </li>

                      <li>
                        <Link to="/settings" onClick={toggleMenu}>
                          <span className="spss_are">
                            <img src="/images/side_m_ic8.png" alt="" />
                          </span>{" "}
                          Settings
                        </Link>
                      </li>
                    </ul>
                  </>
                )}

                <ul className="front_web">
                  <li>
                    <Link to="/" onClick={toggleMenu}>
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/about-us" onClick={toggleMenu}>
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/donate" onClick={toggleMenu}>
                      Donate{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="/events" onClick={toggleMenu}>
                      Events
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" onClick={toggleMenu}>
                      Contact Us
                    </Link>
                  </li>
                  {userData === null ? (
                    <>
                      <li className="mb_view_show">
                        <Link to="/login" onClick={toggleMenu}>
                          Login
                        </Link>
                      </li>
                      <li className="mb_view_show">
                        <Link to="/auth-screen" onClick={toggleMenu}>
                          Sign up
                        </Link>
                      </li>
                    </>
                  ) : (
                    <>
                      <li id="mb_view_show" className="tb_viw_show">
                        <Link to="/" onClick={handleLogout}>
                          Logout
                        </Link>
                      </li>
                    </>
                  )}
                </ul>

                {userDetail &&
        (userDetail?.profile_complete === 1 ||
          userDetail?.role_type === 5 ||
          userDetail?.role_type === 7) && (
                  <div id="point_scan_arae">
                    <div className="merit_point">
                      <div className="pnt_logos">
                        <img src="/images/point_logo.png" alt="" />
                      </div>
                      <div className="cnt_textxt">
                        <h2>{meriData === null ? 0 : meriData}</h2>
                        <p>Merit Points</p>
                      </div>
                    </div>

                    <div className="scanss">
                      <div className="scansses">
                        <h5 onClick={cameracl}>
                          Scan Now{" "}
                          <span className="scaan">
                            <img src="/images/scanner.png" alt="" />
                          </span>
                        </h5>
                      </div>
                      <p>Note : scan now for your events</p>
                    </div>
                    <ScannerModal open={showscan} onClose={handleClose1} />
                  </div>
                )} 
                <div className="bk_now_flds mb_view_hide">
                  {/* <div id="loging_userss">
                    <div className="notif_tion">
                      <span className="countss">2</span>
                      <img
                        src="//images/notification.png"
                        alt=""
                        className="lgo_parts"
                      />
                    </div>
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <div className="usr_sm_mgss">
                          <img
                            src="//images/userr_sm_img.png"
                            alt=""
                            className="lgo_parts"
                          />
                        </div>
                        Jackson
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="">Profile</Dropdown.Item>
                        <Dropdown.Item href="">Logout</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div> */}

                  {userData === null ? (
                    <ul>
                      <li className="withoutlogin">
                        <Link to="/login">Login</Link>{" "}
                        <span className="spss_lst">/</span>{" "}
                        <Link to="/auth-screen">Sign up</Link>
                      </li>
                    </ul>
                  ) : (
                    userDetail && (
                      <div id="loging_userss">
                        <div
                          className="notif_tion"
                          onClick={handleNotification}
                        >
                          <span className="countss">
                            {unreadNotificationData?.totalElement}
                          </span>
                          <img
                            src="/images/notification.png"
                            alt=""
                            className="lgo_parts"
                          />
                          {notificationModal && (
                            <>
                              <NotificationTabModal
                                modalRef={modalRef}
                                allNotificationData={allNotificationData}
                                unreadNotificationData={unreadNotificationData}
                                getAllNotifications={getAllNotifications}
                              />
                            </>
                          )}
                        </div>

                        <Dropdown>
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                          >
                            <div className="usr_sm_mgss">
                              <img
                                src={
                                  userDetail.profile_image
                                    ? userDetail.profile_image
                                    : "/images/user_dummy1.png"
                                }
                                alt=""
                                className="lgo_parts"
                              />
                            </div>
                            <span className="usr_titles">
                              {userDetail.first_name}
                            </span>
                            {/* smit */}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item onClick={handleProfile}>
                              Profile
                            </Dropdown.Item>
                            {/* <Link to="/profile">Profile</Link> */}
                            <Dropdown.Item onClick={handleLogout} href="">
                              Logout
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    )
                  )}
                </div>
              </div>
              <button className="hamburger" onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
              </button>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;

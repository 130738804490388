import React, { useEffect, useRef, useState } from "react";
import ProfileSidebar from "./common/ProfileSidebar";
import { Link } from "react-router-dom";
import CommanPagination from "./common/CommanPagination/CommanPagination";
import axiosClient from "../Services/Axios/axios";

const timeAgo = (date) => {
  const now = new Date();
  const seconds = Math.floor((now - date) / 1000);

  if (seconds < 60) return `${seconds} sec. ago`;
  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) return `${minutes} min. ago`;
  const hours = Math.floor(minutes / 60);
  if (hours < 24) return `${hours} hrs. ago`;

  const days = Math.floor(hours / 24);
  if (days === 1) return `yesterday`;
  if (days <= 7) return `${days} days ago`;

  const weeks = Math.floor(days / 7);
  if (weeks === 1) return `last week`;
  if (weeks < 4) return `${weeks} weeks ago`;

  const months = Math.floor(days / 30); // Approximation
  if (months === 1) return `last month`;
  return `${months} months ago`;
};

const Notification = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [allNotificationData, setALLNotificationData] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const modalRef = useRef(null);

  const handleNotification = () => {
    setFilterOpen(!filterOpen);
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setFilterOpen(false);
    }
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const getAllNotifications = async () => {
    try {
      setLoading(true);
      const url = `/web/getUserNotificationViewAll?pageSize=${limit}&pageNo=${currentPage}${
        selectedDate ? `&searchData=${selectedDate}` : ""
      }`;
      const response = await axiosClient.get(url);
      if (!response.data.error) {
        setALLNotificationData(response.data.data);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching notifications:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllNotifications();
  }, [currentPage, limit, selectedDate]);

  useEffect(() => {
    if (filterOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    } 
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filterOpen]);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  return (
    <>
      <div className="all_suport_bg manages_top">
        <section className="ser_v_araeea profiles">
          <div className="container">
            <div className="bradcams mb_view_hide">
              <span className="bothss">
                <Link to="">My Account</Link>{" "}
                <img src="/images/brd_rowwo.png" alt="" /> Notification
              </span>
            </div>
            <div className="row">
              <div className="col-lg-3 mb_view_hide">
                <ProfileSidebar />
              </div>

              <div className="col-lg-9">
                <div className="form_boxx profilss">
                  <div className="form_arar mt-0 notif_cations">
                    <h4>
                      Notification
                      <span
                        id="fltr_notifi"
                        onClick={handleNotification}
                        ref={modalRef}
                      >
                        <img src="/images/mage_filter.png" alt="" /> Filters
                      </span>
                      {filterOpen && (
                        <div
                          className="filtterr_boxxss"
                          ref={modalRef}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div className="lst_araes">
                            <label>Choose Date</label>
                            <input
                              type="date"
                              name="startDate"
                              className="tx_input_area"
                              placeholder="Select Date"
                              onChange={handleDateChange}
                            />
                          </div>
                          {/* <button
                            className="apply_filter_btn"
                            onClick={() => setFilterOpen(false)}
                          >
                            Apply Filter
                          </button> */}
                        </div>
                      )}
                    </h4>

                    <div className="nitif_tion_lists pgsss">
                      {allNotificationData?.rows?.length > 0
                        ? allNotificationData?.rows?.map((item, index) => {
                            return (
                              <div key={index} className="usr_list_main">
                                <div className="bells">
                                  <img
                                    src="/images/bellicrss.png"
                                    alt="New Notification"
                                  />
                                </div>
                                <div className="usr_txt">
                                  <h4>{item.title}</h4>
                                  <p dangerouslySetInnerHTML={{ __html: item.description.replace(/(Remark:)/, '<br />$1') }} />
                                </div>
                                <div className="d_times">
                                  {" "}
                                  {timeAgo(new Date(item.created_at))}
                                </div>
                              </div>
                            );
                          })
                        : null }
                    </div>
                  </div>
                  <CommanPagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    limit={limit}
                    setLimit={setLimit}
                    totalCounts={allNotificationData?.totalElement}
                    totalPage={allNotificationData?.totalPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Notification;

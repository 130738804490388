import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import axiosClient from "../Services/Axios/axios";
import ProfileSidebar from "./common/ProfileSidebar";

import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import Share from "yet-another-react-lightbox/plugins/share";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";

const ViewReview = () => {
  const { id } = useParams(); 
  const [review, setReview] = useState(null); 
  const [loading, setLoading] = useState(true); 
  const navigate = useNavigate();
  // const [lightboxIndex, setLightboxIndex] = useState(0);
      const captionsRef = useRef(null);
      const zoomRef = useRef(null);
      const [open, setOpen] = useState(false); 
  const [lightboxImage, setLightboxImage] = useState("");
     

  
  useEffect(() => {
    const fetchReviewData = async () => {
      try {
        const response = await axiosClient.get(`/events/getReview/${id}`);
        console.log(response); 
        if (!response.data.error) {
          setReview(response.data.data);
        } else {
          Swal.fire({
            icon: "error",
            title: "Failed to fetch review data.",
            text: response.data.message,
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error fetching review data.",
          text: error.response?.data?.message || "Something went wrong.",
        });
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchReviewData();
  }, [id]);

  // Render loading state or review data
  if (loading) {
    return <div>Loading...</div>; // You can replace this with a spinner or loading component
  }

  return (
    <div className="all_suport_bg manages_top">
      <section className="ser_v_araeea profiles">
        <div className="container">
          <div className="bradcams mb_view_hide">
            <span className="bothss">
              <Link to="/impact">My Account</Link>
              <img src="/images/brd_rowwo.png" alt="" /> <Link to="/my-events">Event</Link>
              <img src="/images/brd_rowwo.png" alt="" /> View Review
            </span>
          </div>
          <div className="row">
            <div className="col-lg-3 mb_view_hide">
              <ProfileSidebar />
            </div>
            <div className="col-lg-9">
            
              <h4 className="br_space ml-4">Review Details</h4>
              <div className="form_arar mt-0">
              {review ? (
                <div className="review-details mt-4 ">
                 <div className="reviewImage lightbox-trigger cursor-pointer" onClick={() => {
                      setLightboxImage(review.image); // Set the image for the lightbox
                      setOpen(true); // Open the lightbox
                    }}>
                      <img src={review.image} alt="Review" />
                    </div>
                  
                  <Lightbox
                    open={open}
                    close={() => setOpen(false)}
                    plugins={[Zoom, Captions, Share]}
                    slides={[{ src: lightboxImage }]} // Use the single image for the lightbox
                  />
                  <div className="reviewContent">
                    <p>
                      <strong>Review:</strong> {review.review}
                    </p>{" "}
                  </div>
                  {/* Display the review content */}
                </div>
              ) : (
                <p>No review found.</p>
              )}
              <button type="submit" className="sub_mitessR mt-2" onClick={() => navigate("/my-events")}>
                Back
              </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ViewReview;

import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { ClipLoader } from "react-spinners";
import ProfileSidebar from "./common/ProfileSidebar";
import axiosClient from "../Services/Axios/axios";

const Careers = () => {
  const { id } = useParams();
  const [userData, setUserData] = useState();
  const [buttonloader, setButtonloader] = useState(false);
  const [errors, setErrors] = useState({});
  const fileInputRef = useRef();

  const [reviewData, setReviewData] = useState({
    event_id: "",
    user_id: "",
    review: "",
    image: "",
  });

  const handleImageChange = async (e) => {
    try {
      const file = e.target.files[0];
      if (!file) {
        setErrors({ ...errors, image: "Image is required" });
        return;
      }
      setErrors({ ...errors, image: "" }); // Clear error if file is valid

      const formData = new FormData();
      formData.append("image", file);
      const responce = await axiosClient.post("/users/uploadimg", formData);
      if (!responce.data.error) {
        setReviewData({ ...reviewData, image: responce.data.file });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Image upload failed. Please try again.",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation
    let validationErrors = {};
    if (!reviewData.review.trim()) {
      validationErrors.review = "Review is required";
    }
    if (!reviewData.image) {
      validationErrors.image = "Image is required";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setButtonloader(true);
    try {
      const data = await axiosClient.post(`/events/addReview`, reviewData);
      if (!data.data.error) {
        Swal.fire({
          icon: "success",
          title: "Thanks for your Review",
          showConfirmButton: false,
          timer: 1500,
        });
        // Reset form fields
        setReviewData({ ...reviewData, review: "", image: "" });
        if (fileInputRef.current) {
          fileInputRef.current.value = ""; // Clear file input
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.response?.data?.message || "Something went wrong",
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setButtonloader(false);
    }
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userData"));
    setUserData(user);
    setReviewData({
      ...reviewData,
      event_id: id,
      user_id: user.id,
    });
  }, [id]);

  return (
    <div className="all_suport_bg manages_top">
      <section className="ser_v_araeea profiles">
        <div className="container">
          <div className="bradcams mb_view_hide">
            <span className="bothss">
              <Link to="">My Account</Link>
              <img src="/images/brd_rowwo.png" alt="" /> Events
              <img src="/images/brd_rowwo.png" alt="" /> Add Review
            </span>
          </div>
          <div className="row">
            <div className="col-lg-3 mb_view_hide">
              <ProfileSidebar />
            </div>
            <div className="col-lg-9">
              <div className="form_boxx profff_eventss profilss">
                <h4 className="br_space">Add Review</h4>
                <div className="form_arar mt-0">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>Enter Review</label>
                          <textarea
                            className="form-control"
                            name="review"
                            onChange={(e) => {
                              setErrors({ ...errors, review: "" });
                              setReviewData({
                                ...reviewData,
                                review: e.target.value,
                              });
                            }}
                            value={reviewData.review}
                            rows="7"
                            cols="7"
                          ></textarea>
                          {errors.review && (
                            <span className="text-danger">{errors.review}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>Attach Image</label>
                          <input
                            type="file"
                            className="form-control bor1"
                            onChange={handleImageChange}
                            ref={fileInputRef}
                          />
                          {errors.image && (
                            <span className="text-danger">{errors.image}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12 mt-4">
                        <div className="form-group">
                          <button
                            type="submit"
                            className="sub_mitess"
                            disabled={buttonloader}
                          >
                            {buttonloader ? (
                              <ClipLoader color="#fff" size={25} />
                            ) : (
                              "Save"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Careers;

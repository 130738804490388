import { Link } from "react-router-dom";
import axiosClient from "../Services/Axios/axios";
import { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";

const Contact = () => {
  const [contactUsDetails, setContactUsDetails] = useState({});
  const [contactUsExtraDetails, setContactExtraUsDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const getContactUsDetais = async () => {
    try {
      setLoading(true);
      const response = await axiosClient.get(
        `web/getContactUsPage?language=en`
      );
      if (!response.data.error) {
        setContactUsDetails(response.data.data);
        setContactExtraUsDetails(response.data.extra);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getContactUsDetais();
  }, []);

  if (loading) {
    return (
      <div className="loader">
        <BeatLoader color="#097C81" />
      </div>
    );
  }

  return (
    <>
      <section className="ser_v_araeea manages_top" id="contactus">
        {contactUsDetails.id !== undefined && (
          <div className="container">
            <div className="contentss">
              <h2>{contactUsDetails.name} </h2>
              <p>{contactUsDetails.sub_title}</p>
              <div className="maps_arar">
                <img src={contactUsDetails.image} alt="" />
              </div>
            </div>
          </div>
        )}

        {contactUsDetails.description && (
          <div className="container reach_arar">
            <div className="contect_space">
              <div className="contentss">
                <h2>Reach out to us</h2>

                <div
                  dangerouslySetInnerHTML={{
                    __html: contactUsDetails.description,
                  }}
                ></div>
              </div>
            </div>
          </div>
        )}
        <section class="ser_v_araeea pt-2">
          <div class="container">
            <div class="faq_bgrr">
              {contactUsExtraDetails.image &&  
                 <img src={contactUsExtraDetails.image}  class="bg_faqq"alt="" />
              }

              
             
                  {contactUsExtraDetails.description &&  
                   <div class="over_cntss">
                       <div
                          dangerouslySetInnerHTML={{
                            __html: contactUsExtraDetails.description,
                          }}
                        ></div>
                    </div>
                  }
              
            </div>
          </div>
        </section>
        {/* <div className="container">
          <div className="contentss_forms">
            <div className="form_bx">
              <h2>Let’s Get in Touch</h2>
              <form action="">
                <div className="row">
                  <div className="col-lg-6">
                    <div class="form-group">
                      <label>First Name</label>
                      <input
                        type="text"
                        class="form-control"
                        name=""
                        placeholder="Enter your first name"
                      />
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div class="form-group">
                      <label>Last Name</label>
                      <input
                        type="text"
                        class="form-control"
                        name=""
                        placeholder="Enter your last name"
                      />
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div class="form-group">
                      <label>
                        E-mail ID <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        name=""
                        placeholder="Enter your last name"
                      />
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div class="form-group">
                      <label>
                        Phone Number <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        name=""
                        placeholder="Enter your last name"
                      />
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div class="form-group">
                      <label>Message</label>
                      <textarea
                        class="form-control"
                        rows="7"
                        cols="7"
                      ></textarea>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div class="form-group mt-4">
                      <button
                        type="submit"
                        value="Send Message"
                        class="sub_mitess"
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {contactUsDetails.image2 && (
              <div className="usr_mg_arss">
                <img src={contactUsDetails.image2} alt="" />
              </div>
            )}
          </div>
        </div> */}
      </section>
    </>
  );
};

export default Contact;

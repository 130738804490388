import { Link, useNavigate } from "react-router-dom";
import { React, useEffect, useState } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "animate.css";
import ProfileSidebar from "./common/ProfileSidebar";
import axiosClient from "../Services/Axios/axios";
import Swal from "sweetalert2";
import ViewIcon from "../assets/Images/eye-open.png";
import HideIcon from "../assets/Images/eye-crossed.png";
const Careers = () => {
  const [show, setShow] = useState(false);
  const [userData, setUserData] = useState({});
  const navigation = useNavigate();
  const [errorsValidation, setErrorsValidation] = useState({
    existing_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [passwordData, setpasswordData] = useState({
    existing_password: "",
    new_password: "",
    confirm_password: "",
  });

  const [showExistencePassword, setShowExistencePassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);

  const handleDeactivate = async () => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to deactivate your account",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    });

    if (result.isConfirmed) {
      try {
        const deleteAccount = await axiosClient.put(
          "users/update-status",
          { status: "3" } // Assuming "3" is the status for deletion
        );

        // Check the response data
        if (!deleteAccount.data.error) {
          Swal.fire({
            title: "Deleted!",
            text:
              deleteAccount.data.message || "Your account has been deleted.",
            icon: "success",
            timer: 2000,
            showConfirmButton: false,
          });
          localStorage.clear(); // Clear local storage
          navigation("/"); // Redirect to home or login page
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: deleteAccount.data.message || "Failed to delete account.",
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response?.data?.message || "An error occurred.",
        });
      }
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    let formIsValid = true;
    let errors = {};

    if (!passwordData.new_password) {
      formIsValid = false;
      errors.new_password = "New password is required";
    } else if (
      !/(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>])/.test(passwordData.new_password)
    ) {
      formIsValid = false;
      errors.new_password =
        "Password must contain at least one uppercase letter,one lowercase letter, one number,one special character and at least 8 character long";
    }

    if (!passwordData.confirm_password) {
      formIsValid = false;
      errors.confirm_password = "Confirm password is required";
    } else if (passwordData.confirm_password !== passwordData.new_password) {
      formIsValid = false;
      errors.confirm_password = "Passwords do not match.";
    }

    if (!passwordData.existing_password) {
      formIsValid = false;
      errors.existing_password = "Existing password is required";
    }

    setErrorsValidation(errors);

    if (formIsValid) {
      try {
        const upadatedData = await axiosClient.post("users/change-password", {
          existing_password: passwordData.existing_password,
          new_password: passwordData.new_password,
        });
        if (!upadatedData.data.error) {
          Swal.fire({
            icon: "success",
            title: "Password update successfully",
          });
        } else {
          console.log("upadatedData.data.message");
          Swal.fire({
            icon: "error",
            title: upadatedData.data.message,
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: error.response.data.message,
        });
      }
    }
  };

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("userData")));
  }, []);
  console.log(+userData.signup_type !== 1 || +userData.signup_type !== 2, "sss");
  return (
    <>
      <div className="all_suport_bg manages_top">
        <section className="ser_v_araeea profiles">
          <div className="container">
            <div className="bradcams mb_view_hide">
              <span className="bothss">
                <Link to="">My Account</Link>
                <img src="/images/brd_rowwo.png" alt="" /> Settings
              </span>
            </div>
            <div className="row">
              <div className="col-lg-3 mb_view_hide">
                <ProfileSidebar />
              </div>

              <div className="col-lg-9">
                <div className="form_boxx profilss">
                  <h4 class="br_space">Change Password</h4>
                  <div className="form_arar mt-0">
                    <div className="mang_und_listst mt-0">
                      <form onSubmit={handleUpdate}>
                        <div className="row">
                          {+userData.signup_type === 1 ||
                          +userData.signup_type === 2 ? null : (
                            <div className="col-lg-6 col-sm-6">
                              <div class="form-group mng_eyess">
                                <label>Existing Password</label>
                                <input
                                  type={showExistencePassword ? "text" : "password"}
                                  class="form-control"
                                  name="existing_password"
                                  placeholder="Enter your existing password"
                                  onChange={(e) => {
                                    setpasswordData({
                                      ...passwordData,
                                      existing_password: e.target.value,
                                    });
                                  }}
                                  value={passwordData.existing_password}
                                />
                                <button
                                  type="button"
                                  className="btn btn-outline-secondary LoginViewButtonSetting"
                                  onClick={() => setShowExistencePassword(!showExistencePassword)}
                                >
                                  {showExistencePassword ? (
                                    <img src={ViewIcon} alt="Hide" />
                                  ) : (
                                    <img src={HideIcon} alt="View" />
                                  )}
                                </button>
                                {errorsValidation.existing_password && (
                                  <div className="error">
                                    {errorsValidation.existing_password}
                                  </div>
                                )}
                              </div>
                            </div>
                          )}

                          <div className="col-lg-6 col-sm-6">
                            <div class="form-group mng_eyess">
                              <label>
                                New Password (At least 8 Characters)
                              </label>
                              <input
                                type={showPassword ? "text" : "password"}
                                class="form-control"
                                name="new_password"
                                placeholder="Enter your new password"
                                onChange={(e) => {
                                  setpasswordData({
                                    ...passwordData,
                                    new_password: e.target.value,
                                  });
                                }}
                                value={passwordData.new_password}
                              />
                              <button
                                type="button"
                                className="btn btn-outline-secondary LoginViewButtonSetting"
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {showPassword ? (
                                  <img src={ViewIcon} alt="Hide" />
                                ) : (
                                  <img src={HideIcon} alt="View" />
                                )}
                              </button>
                              {errorsValidation.new_password && (
                                <div className="error">
                                  {errorsValidation.new_password}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="col-lg-6 col-sm-6">
                            <div class="form-group mng_eyess">
                              <label>Confirm Password</label>
                              <input
                               type={showCPassword ? "text" : "password"}
                                class="form-control"
                                name="confirm_password"
                                placeholder="Confirm password"
                                onChange={(e) => {
                                  setpasswordData({
                                    ...passwordData,
                                    confirm_password: e.target.value,
                                  });
                                }}
                                value={passwordData.confirm_password}
                              />
                              <button
                              type="button"
                              className="btn btn-outline-secondary LoginViewButtonSetting"
                              onClick={() => setShowCPassword(!showCPassword)}
                            >
                              {showCPassword ? (
                                <img src={ViewIcon} alt="Hide" />
                              ) : (
                                <img src={HideIcon} alt="View" />
                              )}
                            </button>

                              {errorsValidation.confirm_password && (
                                <div className="error">
                                  {errorsValidation.confirm_password}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          {/* <div className="col-lg-6">
                            <div class="form-group">
                              <label>Security Pin</label>
                              <p className="viewss">
                                View security Password : 19**{" "}
                                <Link to="">
                                  <img src="/images/sm_eye.png" alt="" />
                                </Link>
                              </p>
                            </div>
                          </div> */}

                          <div className="col-lg-12 mt-5">
                            <div class="form-group">
                              <button
                                type="submit"
                                value="Update Profile"
                                class="sub_mitess"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>

                      <div className="settings_boths_btnss">
                        <span
                          className="size">
                          Do you want to delete your account ?
                        </span>
                        <button
                          className="sub_mitess"
                          onClick={handleDeactivate}
                        >
                          Delete Account
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Careers;

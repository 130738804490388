import { Link, useParams, useNavigate } from "react-router-dom";
import { React, useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import CountUp from "react-countup";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import axiosClient from "../Services/Axios/axios";
import { BeatLoader } from "react-spinners";
import dayjs from "dayjs";
import ShareModal from "./common/ShareModal";
import NewsItem from "./common/NewsItem.js";


const blogsslider = {
  margin: 0,
  responsiveClass: true,
  nav: true,
  dots: false,
  autoplay: false,
  navText: ["<", ">"],
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 1,
    },
    700: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};

const Blogs = () => {
  const { page_url } = useParams();
  const [loading, setLoading] = useState(false);
  const [newsData, setNewsData] = useState({});
  const [exploreData, setExploreData] = useState([]);
  const navigate = useNavigate(); // Define navigate
  const [shareModal, setShareModal] = useState(false);
  const [shareUrl, setShareUrl] = useState("");

  const getSingleNewsData = async () => {
    try {
      setLoading(true);
      const data = await axiosClient.get(
        `/news/Newsdetail/${page_url}?language=en`
      );
      if (!data.data.error) {
        setNewsData(data.data.data);
        setExploreData(data.data.data.explorMore);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  // const handleShareModal = async () => {
  //   setShareModal(!shareModal);
  // };

  const handleShareModal = async (url) => {
    const fullUrl = `${window.location.origin}/news-details/${url}`; // Construct the full URL
    setShareUrl(fullUrl);
    setShareModal(!shareModal);
  };
  useEffect(() => {
    getSingleNewsData();
  }, [page_url]);

  if (loading) {
    return (
      <div className="loader">
        <BeatLoader color="#097C81" />
      </div>
    );
  }
  console.log(newsData, "newsData");
  return (
    <>
      <div className="all_suport_bg manages_top">
        <section
          className="ser_v_araeea evnt_slisder allss news_dtlss"
          id="blogss"
        >
          <div className="container">
            <div className="dtl_titlesss">
              <h2>{newsData?.name}</h2>
              <span className="dt_shrae">
                {dayjs(newsData?.created_date).format("DD MMM, YYYY")}{" "}
                <img
                  src="/images/shareandroi_ic.png"
                  alt=""
                  onClick={() => handleShareModal(newsData?.slug)}
                />
              </span>
            </div>
          </div>
          <div className="container">
            <OwlCarousel
              className="slider-items owl-carousel news_listst"
              id="home_sliserss"
              {...blogsslider}
            >
              <div className="item">
                <div className="sld_partsss">
                  <img src={newsData?.image} className="mang_heitts" alt="" />
                </div>
              </div>

              {/* <div className="item">
                <div className="sld_partsss">
                  <img src="/images/blog_sld.png" alt="" />
                  <div className="over_cntsst">
                    <h2>
                      Lorem ipsum dolor sit amet. Sed consequatur necessitatibus
                    </h2>
                    <p>
                      <img src="/images/ic_sharp-location-on.png" alt="" />{" "}
                      Phnom Penh , Combodia
                    </p>
                  </div>
                </div>
              </div> */}
            </OwlCarousel>

            <div className="discriptionss">
              <div className="un_cntxtx">
                {/* <p>{newsData?.description}</p> */}
                <p  dangerouslySetInnerHTML={{
                          __html: newsData?.description,
                        }}
                      ></p>



              </div>
              {/* <div className="un_cntxtx">
                <p>
                  Cum sociis natoque penatibus et magnis dis parturient montes,
                  nascetur ridiculus mus. Donec quam felis,Cum sociis natoque
                  penatibus et magnis dis parturient montes, nascetur ridiculus
                  mus. Donec quam felis,Cum sociis natoque penatibus et magnis
                  dis parturient montes, nascetur ridiculus mus. Donec quam
                  felis,Cum sociis natoque penatibus et magnis dis parturient
                  montes, nascetur ridiculus mus. Cum sociis natoque penatibus
                  et magnis dis parturient montes, nascetur ridiculus mus. Donec
                  quam felis,Cum sociis natoque penatibus et magnis dis
                  parturient montes, nascetur ridiculus mus. Donec quam
                  felis,Cum sociis natoque penatibus et magnis dis parturient
                  montes, nascetur ridiculus mus. Donec quam felis,Cum sociis
                  natoque penatibus et magnis dis parturient montes, nascetur
                  ridiculus mus. Cum sociis natoque penatibus et magnis dis
                  parturient montes, nascetur ridiculus mus. Donec quam
                  felis,Cum sociis natoque penatibus et magnis dis parturient
                  montes, nascetur ridiculus mus. Donec quam felis,Cum sociis
                  natoque penatibus et magnis dis parturient montes, nascetur
                  ridiculus mus. Donec quam felis,Cum sociis natoque penatibus
                  et magnis dis parturient montes, nascetur ridiculus mus. Cum
                  sociis natoque penatibus et magnis dis parturient montes,
                  nascetur ridiculus mus. Donec quam felis,Cum sociis natoque
                  penatibus et magnis dis parturient montes, nascetur ridiculus
                  mus. Donec quam felis,Cum sociis natoque penatibus et magnis
                  dis parturient montes, nascetur ridiculus mus. Donec quam
                  felis,Cum sociis natoque penatibus et magnis dis parturient
                  montes, nascetur ridiculus mus.{" "}
                </p>
              </div>

              <div className="un_cntxtx">
                <h4>Lorem ipsum dolor sit amet, cons</h4>
                <p>
                  Cum sociis natoque penatibus et magnis dis parturient montes,
                  nascetur ridiculus mus. Donec quam felis,Cum sociis natoque
                  penatibus et magnis dis parturient montes, nascetur ridiculus
                  mus. Donec quam felis,Cum sociis natoque penatibus et magnis
                  dis parturient montes, nascetur ridiculus mus. Donec quam
                  felis,Cum sociis natoque penatibus et magnis dis parturient
                  montes, nascetur ridiculus mus. Cum sociis natoque penatibus
                  et magnis dis parturient montes, nascetur ridiculus mus. Donec
                  quam felis,Cum sociis natoque penatibus et magnis dis
                  parturient montes, nascetur ridiculus mus. Donec quam
                  felis,Cum sociis natoque pena
                </p>
              </div>

              <div className="un_cntxtx">
                <h4>Lorem ipsum dolor sit amet, cons</h4>
                <p>
                  Cum sociis natoque penatibus et magnis dis parturient montes,
                  nascetur ridiculus mus. Donec quam felis,Cum sociis natoque
                  penatibus et magnis dis parturient montes, nascetur ridiculus
                  mus. Donec quam felis,Cum sociis natoque penatibus et magnis
                  dis parturient montes, nascetur ridiculus mus. Donec quam
                  felis,Cum sociis natoque penatibus et magnis dis parturient
                  montes, nascetur ridiculus mus. Cum sociis natoque penatibus
                  et magnis dis parturient montes, nascetur ridiculus mus. Donec
                  quam felis,Cum sociis natoque penatibus et magnis dis
                  parturient montes, nascetur ridiculus mus. Donec quam
                  felis,Cum sociis natoque penaCum sociis natoque penatibus et
                  magnis dis parturient montes, nascetur ridiculus mus. Donec
                  quam felis,Cum sociis natoque penatibus et magnis dis
                  parturient montes, nascetur ridiculus mus. Donec quam
                  felis,Cum sociis natoque penatibus et magnis dis parturient
                  montes, nascetur ridi
                </p>
              </div> */}
            </div>
          </div>
        </section>

        <section className="ser_v_araeea pt-0" id="news_featured_hm">
          <div className="container">
            <div class="al_sec_ctxt mb-0">
              <h2>
                Explore More{" "}
                <span className="ex_mores">
                  <Link
                    to={`/news-details-category-wise/${exploreData[0]?.blog_category?.name}`}
                  >
                    See more
                  </Link>
                </span>
              </h2>
            </div>

            <div className="row">
              {exploreData?.map((res, i) => (
                <div
                  className="col-lg-4 col-sm-6 mt-4 mb_top_space nws_details" id="set_cntest"
                  onClick={() => navigate(`/news-details/${res.slug}`)}
                >
                  <div className="news_box_arara">
                    <div className="nes_mg_arae">
                      <img src={res.image} alt="" />
                    </div>
                    <span className="daytss">
                      {dayjs(res?.created_date).format("DD-MM-YYYY")}
                      {res.blog_category && (
                        <span className="ctgrees">
                          {res.blog_category.name}
                        </span>
                      )}
                    </span>
                    <h3>{res.name}</h3>
                    <NewsItem res={res} /> 
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <ShareModal
          isModalOpen={shareModal}
          handleClose1={handleShareModal}
          ShareText={newsData?.name}
          shareUrl={shareUrl}
        />
      </div>
    </>
  );
};

export default Blogs;

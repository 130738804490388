import { Link, useParams, useNavigate } from "react-router-dom";
import { React, useEffect, useRef, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Modal, Button } from "react-bootstrap";
import axiosClient from "../../Services/Axios/axios";
import PhoneInput from "react-country-phone-input";
import { isValidPhoneNumber } from "libphonenumber-js";
import "react-phone-input-2/lib/style.css";
// import PhoneInput from "react-country-phone-input";
import Swal from "sweetalert2";
import { BeatLoader, ClipLoader } from "react-spinners";
import dayjs, { Dayjs } from "dayjs";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import Share from "yet-another-react-lightbox/plugins/share";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import Lightbox from "yet-another-react-lightbox";
import img from "../../assets/Images/background-thumb.jpg";
import play from "../../assets/Images/PlayIcon.png";
import moment from "moment-timezone";
import TruncateLocation from "../common/TruncateLocation";

const Donate = () => {
  const { page_url } = useParams();
  const [eventData, setEventData] = useState(null);
  const [photaData, setPhotaData] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const [buttonloader, setButtonloader] = useState(false);
  const [similarData, setSimilarData] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState();
  const navigate = useNavigate();
  const [limit, setLimit] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [exploreCount, setExploreCount] = useState(0);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [startDateTime, setStartDateTime] = useState(null);
  const [endDateTime, setEndDateTime] = useState(null);
  const [activeTab, setActiveTab] = useState(0);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    country_code: "+91",
    user_type: "1",
    communication_type: "1",
    event_id: "",
    user_id: "",
  });
  const [errors, setErrors] = useState({
    fname: "",
    email: "",
    phone: "",
  });

  const [selected, setSelected] = useState({ 1: true, 2: false, 3: false });
  const handleClose = () => setShow(false);
  const videoshow = () => setShow(true);

  const [lightboxIndex, setLightboxIndex] = useState(0);
  const captionsRef = useRef(null);
  const zoomRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handlePhoneChange = (value, country) => {
    setFormData({
      ...formData,
      fullPhone: value,
      mobile: value.slice(country.dialCode.length), // Extract mobile number without country code
      country_code: `+${country.dialCode}`,
      country: country.iso2, // Update the country code
    });
  };

  const handleThumbnailClick = (videoUrl) => {
    if (currentVideo === null) {
      setCurrentVideo(videoUrl); // Set the current video to the clicked video URL
    } else {
      setCurrentVideo(null); // Reset current video to null to hide the video
      setCurrentVideo(videoUrl);
    }
  };

  const handleTabChange = (index) => {
    setActiveTab(index); // Update the active tab index
    if (index === 0) {
      // If switching to the video tab
      setCurrentVideo(null); // Reset current video to show thumbnail
    }
  };

  const validate = () => {
    const errors = {};

    // Name validation
    if (!formData.name.trim()) {
      errors.fname = "Name is required.";
      setButtonloader(false);
    } else if (formData.name.length > 40) {
      errors.fname = "Name must be at least 40 characters.";
      setButtonloader(false);
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim()) {
      errors.email = "Email ID is required.";
      setButtonloader(false);
    } else if (!emailRegex.test(formData.email)) {
      errors.email = "Invalid email format.";
      setButtonloader(false);
    } else if (formData.email.length > 40) {
      errors.email = "Email must be at least 40 characters.";
      setButtonloader(false);
    }

    // Phone number validation
    if (!formData.mobile.trim() || !formData.country_code) {
      errors.phone = "Phone number and country code are required.";
      setButtonloader(false);
    }
    //  else if (
    //   !isValidPhoneNumber(
    //     `${formData.country_code}${formData.mobile}`,
    //     formData.country_code.replace("+", "")
    //   )
    // ) {
    //   errors.phone = "Invalid phone number for the selected country.";
    //   setButtonloader(false);
    // }

    setErrors(errors);
    return Object.keys(errors).length === 0; // Returns true if no errors
  };

  const handleProfile = async () => {
    try {
      const response = await axiosClient.get("/users/getByID");
      const userData = response?.data?.data;

      if (response?.data?.error || !userData) return;

      localStorage.setItem("userData", JSON.stringify(userData));
    } catch (error) {
      console.error("Error fetching profile:", error);
      localStorage.clear();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (userData) {
      if (
        ([8, 9].includes(userData.role_type) &&
          userData.profile_complete === 1) ||
        [5, 7].includes(userData.role_type)
      ) {
        setButtonloader(true);
        if (validate()) {
          try {
            const userTimeZone = moment.tz.guess();
            const { data } = await axiosClient.post(`/events/booking`, {
              ...formData,
              user_type: Number(formData.user_type),
              communication_type: Number(formData.communication_type),
              timezone: userTimeZone,
            });

            if (!data.error) {
              Swal.fire({
                icon: "success",
                title: "Thanks for your booking",
                showConfirmButton: false,
                timer: 1500,
              });
              handleClose();

              setFormData({
                name: "",
                email: "",
                mobile: "",
                country_code: "+91",
                user_type: "1",
                fullPhone: "",
                communication_type: "1",
                event_id: formData.event_id, // Keep event ID intact
                user_id: userData?.id || "",
              });
              handleProfile();
            }
            setButtonloader(false);

            if (userData) {
              navigate("/my-events");
            }
          } catch (error) {
            setButtonloader(false);
            Swal.fire({
              icon: "error",
              title: error.response?.data?.message || "An error occurred",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }
      } else {
        Swal.fire({
          title:
            "KYB verification is required to register for this event. Please complete your KYB to proceed",
          icon: "warning",
          showCancelButton: false,
          showConfirmButton: true,
          // timer: 1500,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
      }
    } else {
      if (!userData) {
        setButtonloader(true);
        if (validate()) {
          try {
            const userTimeZone = moment.tz.guess();
            const { data } = await axiosClient.post(`/events/booking`, {
              ...formData,
              user_type: Number(formData.user_type),
              communication_type: Number(formData.communication_type),
              timezone: userTimeZone,
            });

            if (!data.error) {
              Swal.fire({
                icon: "success",
                title: "Thanks for your booking",
                showConfirmButton: false,
                timer: 1500,
              });
              handleClose();

              setFormData({
                name: "",
                email: "",
                mobile: "",
                country_code: "+91",
                user_type: "1",
                fullPhone: "",
                communication_type: "1",
                event_id: formData.event_id, // Keep event ID intact
                user_id: userData?.id || "",
              });
            }
            setButtonloader(false);
          } catch (error) {
            setButtonloader(false);
            Swal.fire({
              icon: "error",
              title: error.response?.data?.message || "An error occurred",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }
      } else {
        Swal.fire({
          title:
            "KYC verification is required to register for this event. Please complete your KYC to proceed",
          icon: "warning",
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1500,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? (checked ? value : "") : value, // Update logic for checkboxes
    }));
  };

  const getSingleData = async () => {
    try {
      setLoading(true);
      const { data } = await axiosClient.get(
        `/events/getEvents/${page_url}?language=en&pageSize=${limit}&pageNo=1`
      );

      if (!data.error) {
        setEventData(data.data);
        setSimilarData(data.data.explorMore.rows);
        setExploreCount(data.data.explorMore.totalElement);

        const userTimeZone = moment.tz.guess();
        // Convert UTC time to user's local time in "10:00 AM" format
        const eventStartTime = moment
          .tz(
            `${data.data.event_date} ${data.data.event_time}`,
            "YYYY-MM-DD hh:mm A",
            "UTC"
          )
          .tz(userTimeZone)
          .format("hh:mm A");

        const eventEndTime = moment
          .tz(
            `${data.data.event_date} ${data.data.event_time_end}`,
            "YYYY-MM-DD hh:mm A",
            "UTC"
          )
          .tz(userTimeZone)
          .format("hh:mm A");

        setStartDateTime(eventStartTime);
        setEndDateTime(eventEndTime);

        setFormData((prev) => ({
          ...prev,
          event_id: data.data.id,
        }));

        // setPhotaData(
        // data.data.event_gallaries.filter((item) => item.gallary_type === 1)
        // );
        setPhotaData(
          data.data.event_gallaries
            .filter((item) => item.gallary_type === 1)
            .map((item) => ({ src: item.image }))
        );

        setVideoData(
          data.data.event_gallaries.filter((item) => item.gallary_type === 2)
        );

        const staticThumbnail = img;
        const videos = data.data.event_gallaries.filter(
          (item) => item.gallary_type === 2
        );
        const videosWithThumbnails = videos.map((video) => ({
          ...video,
          thumbnail: staticThumbnail,
        }));

        setVideoData(videosWithThumbnails);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    getSingleData();
  }, [page_url, limit]);

  useEffect(() => {
    const storedUserData = localStorage.getItem("userData");
    if (storedUserData) {
      const parsedUserData = JSON.parse(storedUserData);
      setUserData(parsedUserData);
      setFormData((prev) => ({
        ...prev,
        user_id: parsedUserData.id || "",
        name: parsedUserData?.first_name
          ? `${parsedUserData?.first_name} ${
              parsedUserData?.last_name ? parsedUserData?.last_name : ""
            } `
          : "",

        email: parsedUserData.email || "",
        mobile: parsedUserData.mobile || "",
      }));
    }
  }, []);
  if (loading) {
    return (
      <div className="loader">
        <BeatLoader color="#097C81" />
      </div>
    );
  }
  return (
    <>
      <Modal
        className="all_supportss sm_sizess"
        id="registeress"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="fromss_boxx">
            <div className="titalss">
              <h3>Register Now</h3>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="unfildss" id="rediiobnt">
                <p>Register now for this event as :</p>
                <div className="rdo_boxx">
                  <p>
                    <input
                      type="radio"
                      id="test1"
                      name="user_type"
                      value="1" // Set specific value
                      onChange={(e) => handleChange(e)}
                      checked={formData.user_type === "1"} // Bind checked state
                    />
                    <label htmlFor="test1">Participant</label>
                  </p>
                  <p>
                    <input
                      type="radio"
                      id="test2"
                      name="user_type"
                      value="2" // Set specific value
                      onChange={(e) => handleChange(e)}
                      checked={formData.user_type === "2"} // Bind checked state
                    />
                    <label htmlFor="test2">Volunteer</label>
                  </p>
                </div>
              </div>
              <div class="form-group">
                <label>
                  Name<span className="red">*</span>
                </label>
                <input
                  type="text"
                  class={`form-control ${errors.fname ? "input-error" : ""}`}
                  name="name"
                  placeholder="Enter Name"
                  value={formData.name}
                  onChange={(e) => {
                    setErrors({ ...errors, fname: "" });
                    handleChange(e);
                  }}
                />
                {errors.fname && <div className="error">{errors.fname}</div>}
              </div>

              <div class="form-group">
                <label>
                  Email ID<span className="red">*</span>
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.email ? "input-error" : ""
                  }`}
                  name="email"
                  placeholder="Enter Email ID"
                  value={formData.email}
                  onChange={(e) => {
                    setErrors({ ...errors, email: "" });
                    handleChange(e);
                  }}
                  readOnly={userData?.email ? true : false}
                />
                {errors.email && <div className="error">{errors.email}</div>}
              </div>

              <div class="form-group">
                <label>
                  Phone Number<span className="red">*</span>
                </label>
                <PhoneInput
                  className={` react-tel-input ${
                    errors.phone ? "input-error" : ""
                  }`}
                  country={formData.country || "in"}
                  value={formData.fullPhone || ""}
                  onChange={(value, country) =>
                    handlePhoneChange(value, country)
                  }
                  placeholder="Enter Phone Number"
                  inputProps={{
                    name: "phoneNumber",
                    required: true,
                    autoFocus: true,
                  }}
                />

                {/* <PhoneInput
                  country={"in"} // Default country
                  value={`${formData.country_code}${formData.mobile}`} // Combine country code and mobile
                  className={` react-tel-input ${
                    errors.phone ? "input-error" : ""
                  }`}
                  onChange={(value, country) =>
                    handlePhoneChange(value, country)
                  }
                  inputProps={{
                    name: "phoneNumber",
                    required: true,
                    autoFocus: true,
                  }}
                /> */}
                {errors.phone && <div className="error">{errors.phone}</div>}
              </div>

              {/* <div class="form-group check_txt" id="checkox">
                <input id="check1" name="" type="checkbox" value="yes" />
                <label for="check1">I confirm my phone number</label>
              </div> */}

              <div class="form-group">
                <div className="ck_titalss">
                  <h3>Choose your best way for communication</h3>
                </div>
                <div className="row">
                  {["1", "2", "3"].map((value, index) => (
                    <div className="col-lg-4 col-4" key={index}>
                      <div className="check_txtx" id="checkox">
                        <input
                          id={`check${value}`}
                          name="communication_type"
                          type="checkbox"
                          value={value}
                          checked={formData.communication_type === value} // Single selection logic
                          onChange={(e) =>
                            setFormData((prevData) => ({
                              ...prevData,
                              communication_type: e.target.checked ? value : "", // Update state
                            }))
                          }
                        />
                        <label htmlFor={`check${value}`}>
                          {value === "1"
                            ? "Telegram"
                            : value === "2"
                            ? "Phone Call"
                            : "Email"}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div class="form-group mt-4">
                <button
                  type="submit"
                  value="Register"
                  class="sub_mitess"
                  disabled={buttonloader}
                >
                  {buttonloader ? (
                    <ClipLoader color="#fff" size={"25"} />
                  ) : (
                    "Register"
                  )}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      {eventData?.id && (
        <div className="all_suport_bg manages_top">
          <section className="ser_v_araeea" id="do_telss_page">
            <div className="container">
              <div className="al_sec_ctxt do_detailss">
                <h2>
                  {eventData?.title}
                  {/* Lorem ipsum dolor : sit amet Sed consequatur{" "}
                  <span className="blocks">necessitatibus</span> */}
                </h2>
              </div>
              <div className="row">
                <div
                  className={
                    dayjs(eventData.event_date).isBefore(dayjs(), "day")
                      ? "col-lg-12"
                      : "col-lg-8"
                  }
                >
                  <div className="us_bg_imgas">
                    <img src={eventData?.image} alt="" />
                  </div>

                  <div className="tm_context">
                    <div className="set_tm_dat">
                      <div className="st_adrss">
                        <img
                          src="/images/g_ic_sharp-location-on.png"
                          className="map_ic"
                          alt=""
                        />{" "}
                        {/* {eventData?.event_location} */}
                        {eventData.event_location.length > 60 ? (
                          <TruncateLocation
                            res={{ description: eventData.event_location }}
                          />
                        ) : (
                          eventData.event_location // Render the full location if it's 60 characters or less
                        )}
                      </div>
                      <div className="st_adrss">
                        <div className="part_right">
                          <span className="datess">
                            <img
                              src="/images/g_uil_calender.png"
                              className="datss"
                              alt=""
                            />{" "}
                            {dayjs(eventData?.event_date).format(
                              "DD, MMMM YYYY"
                            )}
                          </span>

                          <span className="datess">
                            <img
                              src="/images/tabler_clock.png"
                              className="timess"
                              alt=""
                            />{" "}
                            {startDateTime} - {endDateTime}
                          </span>
                        </div>
                      </div>
                    </div>
                    <p>
                      {" "}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: eventData?.description,
                        }}
                      ></div>
                    </p>
                  </div>
                </div>

                {dayjs(eventData.event_date).isBefore(dayjs(), "day") ? null : (
                  <div className="col-lg-4">
                    <div
                      className="rt_donat_lists mb_view_hide"
                      id={
                        dayjs(eventData.event_date).isBefore(dayjs(), "day")
                          ? "event_dtlss"
                          : undefined
                      }
                    >
                      <div id="form_arar_alss">
                        <h4>Register Now</h4>
                        <form onSubmit={handleSubmit}>
                          <div className="unfildss" id="rediiobnt">
                            <p>Register now for this event as :</p>
                            <div className="rdo_boxx">
                              <p>
                                <input
                                  type="radio"
                                  id="test1"
                                  name="user_type"
                                  value="1" // Set specific value
                                  onChange={(e) => handleChange(e)}
                                  checked={formData.user_type === "1"} // Bind checked state
                                />
                                <label htmlFor="test1">Participant</label>
                              </p>
                              <p>
                                <input
                                  type="radio"
                                  id="test2"
                                  name="user_type"
                                  value="2" // Set specific value
                                  onChange={(e) => handleChange(e)}
                                  checked={formData.user_type === "2"} // Bind checked state
                                />
                                <label htmlFor="test2">Volunteer</label>
                              </p>
                            </div>
                          </div>

                          <div class="form-group">
                            <label>
                              Name<span className="red">*</span>
                            </label>
                            <input
                              type="text"
                              class={`form-control ${
                                errors.fname ? "input-error" : ""
                              }`}
                              name="name"
                              placeholder="Enter Name"
                              value={formData.name}
                              onChange={(e) => {
                                setErrors({ ...errors, fname: "" });
                                handleChange(e);
                              }}
                            />
                            {errors.fname && (
                              <div className="error">{errors.fname}</div>
                            )}
                          </div>

                          <div class="form-group">
                            <label>
                              Email ID<span className="red">*</span>
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                errors.email ? "input-error" : ""
                              }`}
                              name="email"
                              placeholder="Enter Email ID"
                              value={formData.email}
                              onChange={(e) => {
                                setErrors({ ...errors, email: "" });
                                handleChange(e);
                              }}
                              readOnly={userData?.email ? true : false}
                            />
                            {errors.email && (
                              <div className="error">{errors.email}</div>
                            )}
                          </div>

                          <div class="form-group" id="managss_boxx">
                            <label>
                              Phone Number<span className="red">*</span>
                            </label>
                            <div className="drp_listss"></div>
                            <PhoneInput
                              className={` react-tel-input ${
                                errors.phone ? "input-error" : ""
                              }`}
                              country={formData.country || "in"}
                              value={formData.fullPhone || ""}
                              onChange={(value, country) =>
                                handlePhoneChange(value, country)
                              }
                              placeholder="Enter Phone Number"
                              inputProps={{
                                name: "phoneNumber",
                                required: true,
                                autoFocus: true,
                              }}
                            />
                            {errors.phone && (
                              <div className="error">{errors.phone}</div>
                            )}
                          </div>

                          <div class="form-group">
                            <div className="ck_titalss">
                              <h6>Choose your best way for communication</h6>
                            </div>
                            <div className="row">
                              {["1", "2", "3"].map((value, index) => (
                                <div className="col-lg-4 col-4" key={index}>
                                  <div className="check_txtx" id="checkox">
                                    <input
                                      id={`check${value}`}
                                      name="communication_type"
                                      type="checkbox"
                                      value={value}
                                      checked={
                                        formData.communication_type === value
                                      } // Single selection logic
                                      onChange={(e) =>
                                        setFormData((prevData) => ({
                                          ...prevData,
                                          communication_type: e.target.checked
                                            ? value
                                            : "", // Update state
                                        }))
                                      }
                                    />
                                    <label htmlFor={`check${value}`}>
                                      {value === "1"
                                        ? "Telegram"
                                        : value === "2"
                                        ? "Phone Call"
                                        : "Email"}
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>

                          <div class="form-group mt-4">
                            <button
                              type="submit"
                              value="Donate Now"
                              class="sub_mitess"
                              disabled={
                                dayjs(eventData.event_date).isBefore(
                                  dayjs(),
                                  "day"
                                ) || buttonloader
                              }
                            >
                              {buttonloader ? (
                                <ClipLoader color="#fff" size={"25"} />
                              ) : (
                                "Register"
                              )}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>

                    <div
                      className="btn_manages mt-4 mb_view_show"
                      id="desk_top_hide"
                    >
                      <Link to="" className="nrmllss regitterss text-center">
                        <Button variant="primary" onClick={videoshow}>
                          Register{" "}
                        </Button>
                      </Link>
                    </div>

                    {/* <div className="btn_manages mb_view_hide mt-3">
                    <Link to="" className="nrmllss">
                      Share Event{" "}
                      <img src="/images/w-share-android.png" alt="" />{" "}
                    </Link>
                  </div> */}
                  </div>
                )}
              </div>
            </div>
          </section>

          <section className="ser_v_araeea pt-0 ev_tabdss evnetsts">
            <div className="container">
              <div className="entnts_tabss">
                <Tabs selectedIndex={activeTab} onSelect={handleTabChange}>
                  <div className="tabs_list">
                    <TabList>
                      <Tab>Photos</Tab>
                      <Tab>Videos</Tab>
                    </TabList>
                  </div>

                  <div className="content-wrapper">
                    {/* <TabPanel>
                      <div className="main_tabs_data">
                        <div className="row">
                          {photaData && photaData.length > 0 ? (
                            photaData.map((item, index) => (
                              <div key={index} className="col-lg-3 col-6">
                                <div className="ph_gellry">
                                  <img
                                    src={item?.image}
                                    alt={`photo-${index}`}
                                  />
                                </div>
                              </div>
                            ))
                          ) : (
                            <p>Image Not Found</p>
                          )}
                        </div>
                      </div>
                    </TabPanel> */}

                    <TabPanel>
                      <div className="main_tabs_data">
                        <div className="row">
                          {photaData && photaData.length > 0 ? (
                            photaData.map((item, index) => (
                              <div
                                className="col-lg-3 col-6 lightbox-trigge"
                                key={index}
                                onClick={() => {
                                  setLightboxIndex(index); // Set the index for the lightbox
                                  setOpen(true); // Open the lightbox
                                }}
                              >
                                <div
                                  className="ph_gellry lightbox-trigger"
                                  data-lightbox="gallery"
                                >
                                  <img
                                    src={item.src}
                                    alt={`Photo ${index + 1}`}
                                  />
                                </div>
                              </div>
                            ))
                          ) : (
                            <p className="centered-red-text">Image Not Found</p>
                          )}
                        </div>
                      </div>
                    </TabPanel>
                    <Lightbox
                      open={open}
                      close={() => setOpen(false)}
                      plugins={[Zoom, Captions, Share]}
                      zoom={{ ref: zoomRef }}
                      captions={{ ref: captionsRef }}
                      slides={photaData.map((item) => ({ src: item.src }))}
                      index={lightboxIndex}
                      render={{
                        image: (props) => (
                          <img {...props} className="custom-lightbox-image" />
                        ),
                      }}
                    />

                    <TabPanel>
                      <div className="main_tabs_data">
                        <div className="row">
                          {videoData && videoData.length > 0 ? (
                            videoData.map((item, index) => {
                              return (
                                <div className="col-lg-6 col-6" key={index}>
                                  <div className="vedioo">
                                    {currentVideo === null && ( // Show thumbnail if no video is currently playing
                                      <img
                                        width="100%"
                                        height="400px"
                                        src={item.thumbnail}
                                        alt="Video Thumbnail"
                                        onClick={() =>
                                          handleThumbnailClick(item.image)
                                        }
                                      />
                                    )}
                                    {currentVideo === item.image && ( // Show video if it's the current video
                                      <iframe
                                        width="100%"
                                        height="400"
                                        src={currentVideo}
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerPolicy="strict-origin-when-cross-origin"
                                        allowFullScreen
                                      ></iframe>
                                    )}
                                    {currentVideo === null && ( // Show play button overlay on thumbnail
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "50%",
                                          left: "50%",
                                          transform: "translate(-50%, -50%)",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <button
                                          style={{
                                            width: "70px",
                                            height: "70px",
                                            borderRadius: "50%",
                                            backgroundColor: "black",
                                          }}
                                          onClick={() =>
                                            handleThumbnailClick(item.image)
                                          }
                                        >
                                          <img src={play} alt="Play Icon" />
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <p className="centered-red-text">No Videos Found</p>
                          )}
                        </div>
                      </div>
                    </TabPanel>
                  </div>
                </Tabs>
              </div>
            </div>
          </section>

          <section className="ser_v_araeea evnetsts pt-0">
            <div className="container">
              <div className="al_sec_ctxt">
                <h2>Similar Events</h2>
              </div>
              <div className="entnts_tabss">
                <div className="content-wrapper mt-0">
                  <div className="main_tabs_data">
                    {similarData?.map((item, i) => {
                      return (
                        <div className="un_data_list">
                          <Link to={`/events-details/${item.page_url}`}>
                            <div className="usr_mgss">
                              <img src={item.image} alt="" />
                            </div>
                          </Link>
                          <div className="cntent_tx">
                            <div className="dat_time">
                              <span className="datess">
                                <img src="/images/g_uil_calender.png" alt="" />{" "}
                                {dayjs(item.event_date).format("DD, MMMM YYYY")}
                              </span>
                              <span className="spacess">&nbsp;</span>
                              <span className="datess">
                                <img src="/images/tabler_clock.png" alt="" />{" "}
                                {item.event_time} - {item.event_time_end}
                              </span>
                            </div>
                            <Link to={`/events-details/${item.page_url}`}>
                              <h2>{item.title}</h2>
                            </Link>
                            <p>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.description.substring(0, 500),
                                }}
                              />
                            </p>
                            <div className="adress">
                              <div className="adrss">
                                <img
                                  src="/images/g_ic_sharp-location-on.png"
                                  alt=""
                                />{" "}
                                {/* {item.event_location} */}
                                {item.event_location.length > 60 ? (
                                  <TruncateLocation
                                    res={{ description: item.event_location }}
                                  />
                                ) : (
                                  item.event_location // Render the full location if it's 60 characters or less
                                )}
                              </div>
                              <div className="red_mr">
                                {userData &&
                                (([8, 9].includes(userData.role_type) &&
                                  userData.profile_complete === 1) ||
                                  [5, 7].includes(userData.role_type)) ? (
                                  <Link to="" className="red_mr">
                                    <Button
                                      disabled={dayjs(item.event_date).isBefore(
                                        dayjs(),
                                        "day"
                                      )}
                                      variant="primary"
                                      onClick={videoshow}
                                    >
                                      Register Now
                                      <img
                                        src="/images/ar_right_icon.png"
                                        alt=""
                                      />
                                    </Button>
                                  </Link>
                                ) : (
                                  <Link to="" className="red_mr">
                                    <Button
                                      disabled={dayjs(item.event_date).isBefore(
                                        dayjs(),
                                        "day"
                                      )}
                                      variant="primary"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (!userData) {
                                          videoshow();
                                        } else {
                                          Swal.fire({
                                            title:
                                              "KYB verification is required to register for this event. Please complete your KYB to proceed",
                                            icon: "warning",
                                            showCancelButton: false,
                                            showConfirmButton: true,
                                            // timer: 1500,
                                            confirmButtonColor: "#3085d6",
                                            cancelButtonColor: "#d33",
                                            confirmButtonText: "Ok",
                                          });
                                        }
                                      }}
                                    >
                                      Register Now
                                      <img
                                        src="/images/ar_right_icon.png"
                                        alt=""
                                      />
                                    </Button>
                                  </Link>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {exploreCount && exploreCount > limit && (
                      <div className="col-lg-12 mt-5">
                        <div className="vei_als">
                          <Link
                            to=""
                            onClick={(e) => {
                              e.preventDefault();
                              setLimit(limit + 5);
                            }}
                          >
                            <span className="managess_bnt">View More </span>
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
      <div id="Footer_arae">
        <div className="ftr_manages">
          <div className="th_parts_bxx">
            <div className="container">
              <div class="al_sec_ctxt text-center wit_tx">
                <h2>Your reliable, efficient, and impactful way to give</h2>
              </div>
              <div className="row justy_centr">
                <div className="col-lg-4 col-6">
                  <div className="cont_ftr_box">
                    <img src="/images/wallpaper.png" className="wlper" alt="" />
                    <h5>Efficient</h5>
                    <p>
                      Donate swiftly and <span>effortlessly</span>
                    </p>
                  </div>
                </div>

                <div className="col-lg-4 col-6">
                  <div className="cont_ftr_box">
                    <img src="/images/wallpaper.png" className="wlper" alt="" />
                    <h5>Impactful</h5>
                    <p>
                      Direct your support to the{" "}
                      <span>people and causes that matter</span> most to you
                    </p>
                  </div>
                </div>

                <div className="col-lg-4 col-6">
                  <div className="cont_ftr_box">
                    <img src="/images/wallpaper.png" className="wlper" alt="" />
                    <h5>Reliable</h5>
                    <p>
                      Your donation is safeguarded by{" "}
                      <span>the Worldtrust community</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Donate;

import { Link } from "react-router-dom";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import { useEffect, useState } from "react";
import axiosClient from "../../Services/Axios/axios";
import Swal from "sweetalert2";
import validator from "validator";
import dayjs from "dayjs";
import { ClipLoader } from "react-spinners";
const Footer = () => {
  const [emailAddress, setEmailAddress] = useState("");
  const [emailError, setEmailError] = useState("");
  const [socialLinkData, setSocialLinkData] = useState();
  const [userData,setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  
  const getSocialLinkData = async () => {
    const response = await axiosClient.get("/web/getSocialMediaDetails");
    if (!response.data.error) {
      setSocialLinkData(response.data.data);
    }
  };
  // const hundleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (validator.isEmail(emailAddress)) {
  //     if (emailAddress) {
  //       const response = await axiosClient.post("/web/newsletter", {
  //         email: emailAddress,
  //       });
  //       if (!response.data.error) {
  //         Swal.fire({
  //           icon: "success",
  //           title: "Success",
  //           text: response.data.message,
  //         });
  //         setEmailAddress("");
  //       }
  //     }
  //   } else {
  //     setEmailError("Invalid email address");
  //   }
  // };

  const hundleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setEmailError("");
  
    if (!validator.isEmail(emailAddress)) {
      setEmailError("Invalid email address");
      setLoading(false);
      return; // Early return for invalid email
    }
  
    try {
      const response = await axiosClient.post("/web/newsletter", {
        email: emailAddress,
      });
  
      // Handle response
      if (!response.data.error) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
        });
        setEmailAddress(""); // Clear the input field
      } else {
        // Handle error response
        setEmailError(response.data.message);
        Swal.fire({
          icon: "error",
          // title: "Submission Failed",
          text: response.data.message,
        });
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || "An unexpected error occurred.";
      Swal.fire({
        icon: "error",
        // title: "Submission Failed",
        text: errorMessage,
      });
    } finally {
      setLoading(false); // Stop loading in all cases
    }
  };

  useEffect(() => {
    getSocialLinkData();
    const userDataFromLocalStorage = localStorage.getItem("userData"); 
    setUserData(userDataFromLocalStorage ? JSON.parse(userDataFromLocalStorage) : null);
  }, []);
  return (
    <>
      <footer id="Footer_arae">
        <div className="ftr_manages">
          <div className="container">
            <div className="row brd_managess">
              <div className="col-lg-3 mb-4 mb_view_show">
                <div className="ftr_partss adss_flss logoss">
                  <div className="ftr_logoss">
                    <img src="/images/ftr_logo.png" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6">
                <div className="subss_cr">
                  <h4>Sign up for our newsletter</h4>
                  <div className="stay_up_w">
                    <form action="">
                      <input
                        type="text"
                        name="email"
                        className="form-control"
                        id="subs_email"
                        value={emailAddress}
                        onChange={(e) => {
                          setEmailError("");
                          setEmailAddress(e.target.value);
                        }}
                        placeholder="Your email address"
                        required
                      />
                      <button
                        type="submit"
                        value="Submit"
                        className="sub_mitess"
                        onClick={hundleSubmit}
                        disabled={loading} 
                        >
                          {loading ? (
                            <ClipLoader color="#fff" size={25} className="loading_newsletter" /> 
                          ) : (
                            <img src="/images/ar_w-right.png" alt="" />
                          )} 
                      </button>
                      
                    </form>
                    {emailError && (
                      <p style={{ color: "red", marginTop: "5px" }}>
                        {emailError}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 mb_view_hide">
                <div className="social_link_als">
                  <div className="un_socials_lnks">
                    <ul>
                      {socialLinkData &&
                        Object.keys(socialLinkData).some(
                          (key) => key === "instagram"
                        ) && (
                          <li>
                            <Link
                              to={socialLinkData.instagram}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img src="/images/social_ic1.png" alt="" />
                            </Link>
                          </li>
                        )}

                      {socialLinkData &&
                        Object.keys(socialLinkData).some(
                          (key) => key === "facebook"
                        ) && (
                          <li>
                            <Link
                              to={socialLinkData.facebook}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img src="/images/social_ic2.png" alt="" />
                            </Link>
                          </li>
                        )}
                      {socialLinkData &&
                        Object.keys(socialLinkData).some(
                          (key) => key === "linkedin"
                        ) && (
                          <li>
                            <Link
                              to={socialLinkData.linkedin}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img src="/images/social_ic3.png" alt="" />
                            </Link>
                          </li>
                        )}

                      {socialLinkData &&
                        Object.keys(socialLinkData).some(
                          (key) => key === "telegram"
                        ) && (
                          <li>
                            <Link
                              to={socialLinkData.telegram}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img src="/images/social_ic4.png" alt="" />
                            </Link>
                          </li>
                        )}
                      {socialLinkData &&
                        Object.keys(socialLinkData).some(
                          (key) => key === "youtube"
                        ) && (
                          <li>
                            <Link
                              to={socialLinkData.youtube}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img src="/images/social_ic5.png" alt="YouTube" />
                            </Link>
                          </li>
                        )}


                        {socialLinkData &&
                        Object.keys(socialLinkData).some(
                          (key) => key === "twitter"
                        ) && (
                          <li>
                            <Link
                              to={socialLinkData.twitter}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img src="/images/social_ic6.png" alt="twitter" />
                            </Link>
                          </li>
                        )}       

                      {/* <li>
                        <Link to="/">
                          <img src="/images/social_ic6.png" alt="" />
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
			<div className="dk_view_hide">
              <div className="col-lg-3 mb_view_hide mb-4">
                <div className="ftr_partss adss_flss logoss">
                  <div className="ftr_logoss">
                    <img src="/images/ftr_logo.png" alt="" />
                  </div>
                </div>
              </div>
              </div>

              <div className="col-lg-3 col-sm-4 mb_view_hide">
                <div className="ftr_partss">
                  <h4>Worldtrust</h4>
                  <ul>
                    <li>
                      <Link to="/donate">Worldtrust Donation</Link>
                    </li>
                    <li>
                      <Link to="/about-us">Team Worldtrust</Link>
                    </li>
                    <li>
                      <Link to="/news">Worldtrust News </Link>
                    </li>
                    {/* <li>
                      <Link to="/register" state={{ role: "8" }}>
                        Sign Up As a Charity
                      </Link>
                    </li> */}
                    {!userData && (
                      <li>
                        <Link to="/register" state={{ role: "8" }}>
                          Sign Up as a charity
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 col-sm-4 mb_view_hide">
                <div className="ftr_partss">
                  <h4>About</h4>
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about-us">About Us</Link>
                    </li>
                    <li>
                      <Link to="/donate">Donate</Link>
                    </li>
                    <li>
                      <Link to="/events">Event</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 col-sm-4 mb_view_hide">
                <div className="ftr_partss">
                  <h4>Useful Links</h4>
                  <ul>
                    <li>
                      <Link to="/faqs">FAQ</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/terms-and-conditions">Terms & Condition</Link>
                    </li>
                    <li>
                      <Link to="/careers">Careers</Link>
                    </li>
                  </ul>
                </div>
              </div>

<div className="dk_view_hide">
              <div className="col-lg-12">
                <div className="accordion_container mb_view_show">
                  <div className="ftr_partss">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about-us">About Us</Link>
                      </li>
                      <li>
                        <Link to="/donate">Donate</Link>
                      </li>
                      <li>
                        <Link to="/events">Event</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact Us</Link>
                      </li>
                    </ul>
                  </div>

                  <Accordion>
                    <AccordionItem header="Useful Links">
                      <div className="ftr_partss">
                        <ul>
                          <li>
                            <Link to="/faqs">FAQ</Link>
                          </li>
                          <li>
                            <Link to="/privacy-policy">Privacy Policy</Link>
                          </li>
                          <li>
                            <Link to="/terms-and-conditions">
                              Terms & Condition
                            </Link>
                          </li>
                          <li>
                            <Link to="/careers">Careers</Link>
                          </li>
                        </ul>
                      </div>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
              </div>
              <div className="dk_view_hide">
              <div className="col-lg-6 mb_view_show">
                <div className="social_link_als">
                  <div className="un_socials_lnks">
                    <ul>
                      {/* <li>
                        <Link to="/">
                          <img src="/images/social_ic1.png" alt="" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <img src="/images/social_ic2.png" alt="" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <img src="/images/social_ic3.png" alt="" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <img src="/images/social_ic4.png" alt="" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <img src="/images/social_ic5.png" alt="" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <img src="/images/social_ic6.png" alt="" />
                        </Link>
                      </li> */}

{socialLinkData &&
                        Object.keys(socialLinkData).some(
                          (key) => key === "instagram"
                        ) && (
                          <li>
                            <Link
                              to={socialLinkData.instagram}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img src="/images/social_ic1.png" alt="" />
                            </Link>
                          </li>
                        )}

                      {socialLinkData &&
                        Object.keys(socialLinkData).some(
                          (key) => key === "facebook"
                        ) && (
                          <li>
                            <Link
                              to={socialLinkData.facebook}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img src="/images/social_ic2.png" alt="" />
                            </Link>
                          </li>
                        )}
                      {socialLinkData &&
                        Object.keys(socialLinkData).some(
                          (key) => key === "linkedin"
                        ) && (
                          <li>
                            <Link
                              to={socialLinkData.linkedin}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img src="/images/social_ic3.png" alt="" />
                            </Link>
                          </li>
                        )}

                      {socialLinkData &&
                        Object.keys(socialLinkData).some(
                          (key) => key === "telegram"
                        ) && (
                          <li>
                            <Link
                              to={socialLinkData.telegram}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img src="/images/social_ic4.png" alt="" />
                            </Link>
                          </li>
                        )}
                      {socialLinkData &&
                        Object.keys(socialLinkData).some(
                          (key) => key === "youtube"
                        ) && (
                          <li>
                            <Link
                              to={socialLinkData.youtube}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img src="/images/social_ic5.png" alt="YouTube" />
                            </Link>
                          </li>
                        )}


                        {socialLinkData &&
                        Object.keys(socialLinkData).some(
                          (key) => key === "twitter"
                        ) && (
                          <li>
                            <Link
                              to={socialLinkData.twitter}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img src="/images/social_ic6.png" alt="twitter" />
                            </Link>
                          </li>
                        )}     
                    </ul>
                  </div>
                </div>
              </div>
              </div>
            </div>

            <div className="eml_socilal">
              <div className="em_ico">
                Copyright © {dayjs().format("YYYY")} worldtrust.io All Rights
                Reserved.
              </div>
              <div className="social">
                <div className="socialss">
                  Designed & Developed by{" "}
                  <Link to="https://www.pearsystem.com/" target="_blank">
                    PearSystem
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

import React, { useEffect, useState } from "react";
import axiosClient from "../../Services/Axios/axios";
import { Link } from "react-router-dom";
import CommanPagination from "../common/CommanPagination/CommanPagination";
import dayjs from "dayjs";
import BeatLoader from "react-spinners/BeatLoader"; // Ensure you have installed 'react-spinners'
import Swal from "sweetalert2";
import { gapi } from "gapi-script";
import moment from "moment-timezone";
import TruncateLocation from "../common/TruncateLocation";
 
 

const EnrolledEvents = ({ tab, searchText }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [enrolledEventData, setEnrolledEventData] = useState(null);
  const [loading, setLoading] = useState(false);
  const userId = JSON.parse(localStorage.getItem("userData")).id;
  const [loading1, setLoading1] = useState(false);
  // Fetch Enrolled Events
  const getAllEnrolledEvent = async () => {
    try {
      setLoading(true);
      const response = await axiosClient.get(
        `/events/getEnrolledEvents?language=en&pageSize=${limit}&pageNo=${currentPage}&searchText=${
          tab === 1 ? searchText : ""
        }`
      );
      setLoading(false);
      setEnrolledEventData(response.data?.error ? [] : response.data);
    } catch (error) {
      console.error("Error fetching enrolled events:", error);
      setLoading(false);
      setEnrolledEventData([]);
    }
  };

  const handleCheckIn = async (id) => {
    try {
      const response = await axiosClient.post(`/events/checkin`, {
        event_id: id,
      });
      if (response.data && !response.data.error) {
        getAllEnrolledEvent();
        Swal.fire({ icon: "success", title: "Event Checked In Successfully" });
      }
    } catch (error) {
      console.error("Check-in failed:", error);
    }
  };

  const handleWithdraw = async (id) => {
    try {
      // Await the Swal.fire promise to get the user's confirmation response
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You want to withdraw this event?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, withdraw it!",
      });

      // Log the confirmation response
      console.log(result.isConfirmed, "User confirmation result");

      // Proceed only if the user confirmed the action
      if (result.isConfirmed) {
        const response = await axiosClient.post(`/events/withdraw`, {
          event_id: id,
        });

        // Check if the response indicates success
        if (response.data && !response.data.error) {
          getAllEnrolledEvent(); // Refresh the enrolled events
          Swal.fire({
            icon: "success",
            title: "Event Withdrawn Successfully",
          });
        }
      }
    } catch (error) {
      console.error("Withdraw failed:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to withdraw the event. Please try again.",
      });
    }
  };

  const handleCheckOut = async (id) => {
    try {
      const response = await axiosClient.post(`/events/checkout`, {
        event_id: id,
      });
      if (response.data && !response.data.error) {
        getAllEnrolledEvent();
        Swal.fire({ icon: "success", title: "Event Checked Out Successfully" });
      }
    } catch (error) {
      console.error("Check-in failed:", error);
    }
  };
  const handleAddToCalendarClick = (item) => {
    setLoading1(true);
    const userTimeZone = moment.tz.guess();

    // Convert event start time to user's local time in ISO format
    const eventStartTime = moment
      .tz(
        `${item.event.event_date} ${item.event.event_time}`,
        "YYYY-MM-DD hh:mm A",
        "UTC"
      )
      .tz(userTimeZone)
      .toISOString();

    const eventEndTime = moment
      .tz(
        `${item.event.event_date} ${item.event.event_time_end}`,
        "YYYY-MM-DD hh:mm A",
        "UTC"
      )
      .tz(userTimeZone)
      .toISOString();

    // Force account selection for login
    gapi.auth2
      .getAuthInstance()
      .signIn({ prompt: "select_account" }) // Prompt to select account
      .then((user) => {
        // Successful login
        const profile = user.getBasicProfile();

        // Event details
        const event = {
          summary: item?.event.title,
          location: item?.event.event_location,
          description: item?.event.description,
          start: {
            dateTime: eventStartTime, // Now correctly formatted
            timeZone: userTimeZone, // Correct timezone
          },
          end: {
            dateTime: eventEndTime, // Now correctly formatted
            timeZone: userTimeZone, // Correct timezone
          },
        };

        // Call Calendar API to create event
        return gapi.client.calendar.events
          .insert({
            calendarId: "primary",
            resource: event,
          })
          .then((response) => {
            setLoading1(false);
            console.log("Event created: ", response);
            Swal.fire({ icon: "success", title: "Event added successfully" });
          });
      })
      // .catch((error) => {
      //   console.error("Error during login or creating event: ", error);
      //   Swal.fire({ icon: "error", title: "Something went wrong" });
      // });

      .catch((error) => { 
        // setError("Something Went Wrong");
        console.error("Error during login or creating event: ", error);
        setLoading1(false);
        // Swal.fire({ icon: "error", title: "Something went wrong" });
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getAllEnrolledEvent();
  }, [currentPage, limit, searchText]);

  const hasReviewed = (eventId, reviews) => {
    // Check if reviews is defined and is an array
    if (!Array.isArray(reviews)) {
      return false; // No reviews available
    }
    return reviews.some(
      (review) => review.event_id === eventId && review.user_id === userId
    );
  };

  return (
    <div className="main_tabs_data">
      {loading ? (
        <div className="member-loader">
          <BeatLoader color="#097C81" />
        </div>
      ) : (
        <>
        {loading1 && (
            <div className="full-screen-loader">
              <BeatLoader color="#097C81" />
              <style jsx>{`
                .full-screen-loader {
                  position: fixed;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  background: rgba(255, 255, 255, 0.5);
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  z-index: 9999; // Ensure it appears above other content
                }
              `}</style>
            </div>
          )}
          {enrolledEventData?.data?.rows.map((item, i) => {
            const userTimeZone = moment.tz.guess();
            const eventStartTime = moment
              .tz(
                `${item.event?.event_date} ${item.event?.event_time}`,
                "YYYY-MM-DD hh:mm A",
                "UTC"
              )
              .tz(userTimeZone)
              .format("hh:mm A");

            const eventEndTime = moment
              .tz(
                `${item.event?.event_date} ${item.event?.event_time_end}`,
                "YYYY-MM-DD hh:mm A",
                "UTC"
              )
              .tz(userTimeZone)
              .format("hh:mm A");
            return (
              <div className="un_data_list" key={i}>
                <Link to={`/events-details/${item.event?.page_url}`}>
                  <div className="usr_mgss">
                    <img
                      src={item.event?.image}
                      alt={item.event?.title || "Event"}
                    />
                  </div>
                </Link>
                <div className="cntent_tx">
                  <div className="dts_shrerss">
                    <div className="dat_time">
                      <span className="datess">
                        <img src="/images/g_uil_calender.png" alt="Calendar" />
                        {dayjs(item.event?.event_date).format("DD, MMM YYYY")}
                      </span>
                      <span className="spacess">&nbsp;</span>
                      <span className="datess">
                        <img src="/images/tabler_clock.png" alt="Clock" />
                        {eventStartTime} - {eventEndTime}
                      </span>
                    </div>
                    <div className="sh_linkss">
                      <div className="ul_links">
                        <ul>
                          <li>
                            <Link
                              to=""
                              onClick={() => handleAddToCalendarClick(item)}
                            >
                              <img
                                src="/images/g-calendar.png"
                                alt="Calendar"
                              />
                              &nbsp; Add to calendar
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <h2>
                    {item.event?.title}{" "}
                    <span className="peplss">
                      {item.event?.registered_seat > 0 ||
                      item.event?.registered_seat_participant > 0
                        ? `${
                            item.event?.registered_seat +
                            item.event?.registered_seat_participant
                          } people have registered`
                        : ""}
                    </span>
                  </h2>
                  <p>
                    {" "}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.event?.description.substring(0, 500),
                      }}
                    ></div>
                    {/* {item.event?.description} */}
                  </p>
                  <div className="adress">
                    <div className="adrss">
                      <img
                        src="/images/g_ic_sharp-location-on.png"
                        alt="Location"
                      />
                      {/* {item.event?.event_location} */}
                      {item.event.event_location.length > 60 ? (
                              <TruncateLocation res={{ description: item.event.event_location }} />
                            ) : (
                              item.event.event_location // Render the full location if it's 60 characters or less
                      )}
                    </div>
                    <div className="red_mr">
                      {item.booking_status === 0 && (
                        <>
                          <Link
                            onClick={() => handleWithdraw(item.event?.id)}
                            className="red_mr fl_rights brd_5"
                          >
                            Withdraw
                          </Link>
                          <Link
                            onClick={() => handleCheckIn(item.event?.id)}
                            className="red_mr brd_5"
                          >
                            Check In
                          </Link>
                        </>
                      )}

                      {item.booking_status === 1 && (
                        <>
                          <span className="red_checkin">
                            Check In :{" "}
                            {dayjs(item.checkin_time).format("hh : mm A")}
                          </span>
                          <Link
                            onClick={() => handleCheckOut(item.event?.id)}
                            className="red_mr brd_5"
                          >
                            Check Out
                          </Link>
                        </>
                      )}

                      {/* {item.booking_status === 2 && (
                        <>
                          <Link
                            to={`/add-review/${item.event?.id}`}
                            className="red_mr brd_5"
                          >
                            Add Review
                          </Link>
                        </>
                      )} */}

                      {item.booking_status === 2 && (
                        <>
                          {item.reviews &&
                          item.reviews.length > 0 &&
                          hasReviewed(item.event.id, item.reviews) ? (
                            <div className="review-buttons">
                              <Link
                                to={`/view-review/${item.reviews[0].id}`}
                                // className="btn btn-info"
                                className="red_mr brd_5"
                              >
                                View Review
                              </Link>
                              <Link
                                // to={`/add-review/${item.reviews[0].id}`} // Access the first review's ID
                                to={`/edit-review/${item.reviews[0].id}`}
                                className="red_mr"
                                // onClick={() => navigate(`/add-review/${item.reviews[0].id}`)}
                              >
                                Edit Review
                              </Link>
                            </div>
                          ) : (
                            <Link
                              to={`/add-review/${item.event?.id}`}
                              className="red_mr brd_5"
                            >
                              Add Review
                            </Link>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          <CommanPagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            limit={limit}
            setLimit={setLimit}
            totalCounts={enrolledEventData?.data?.totalElement || 0}
            totalPage={enrolledEventData?.data?.totalPage || 0}
          />
        </>
      )}
    </div>
  );
};

export default EnrolledEvents;

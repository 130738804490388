import { Link } from "react-router-dom";
import { React, useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import CountUp from "react-countup";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import { Modal, Button } from "react-bootstrap";
import ProgressBar from "@ramonak/react-progress-bar";
import "animate.css";
import ProfileSidebar from "./common/ProfileSidebar";

import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TelegramIcon,
  TelegramShareButton
} from "react-share";
const REFERAL_CODE_LINK = process.env.REACT_APP_REFERAL_CODE_LINK;

const Login = () => {
  const [userNewData, setUserNewData] = useState({});
  const [profileData, setProfileData] = useState({});

  const [buttonText, setButtonText] = useState("Copy Link");

  const handleCopy = () => {
    navigator.clipboard
      .writeText(`${REFERAL_CODE_LINK}${profileData?.referral_code}`)
      .then(() => {
        setButtonText("Copied!"); // Change button text to "Copied!"
        setTimeout(() => {
          setButtonText("Copy Link"); // Revert back to "Copy Link" after 2 seconds
        }, 1000);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  useEffect(() => {
    setUserNewData(JSON.parse(localStorage.getItem("userData")));
  }, []);
  console.log(userNewData, "userNewData");
  useEffect(() => {
    setProfileData({
      first_name: userNewData?.first_name,
      last_name: userNewData?.last_name,
      email: userNewData?.email,
      phone: userNewData?.phone,
      phoneCode: userNewData?.phoneCode,
      gender: userNewData?.gender,
      dob: userNewData?.dob,
      referral_code: userNewData?.referral_code,
      profile_image: userNewData?.profile_image,
      parent_referral_code: userNewData?.parent_referral_code,
    });
  }, [userNewData]);
  return (
    <>
      <div className="all_suport_bg manages_top">
        <section className="ser_v_araeea evnetsts profiles">
          <div className="container">
            <div className="bradcams mb_view_hide">
              <span className="bothss">
                <Link to="">My Account</Link>{" "}
                <img src="/images/brd_rowwo.png" alt="" /> Referral
              </span>
            </div>
            <div className="row">
              <div className="col-lg-3 mb_view_hide">
                <ProfileSidebar />
              </div>

              <div className="col-lg-9">
                <div className="entnts_tabss profff_eventss profilss">
                  <h4 className="br_space">Referral</h4>
                  <form action="">
                    <div className="al_pg_suportss">
                      <div className="top_sectss">
                        <p>
                          Refer a friend and every time they spend , you get
                          rewarded too !
                        </p>
                        <ul>
                          <li>
                            <img src="/images/circle-cliks.png" alt="" /> Invite
                            your friends
                          </li>
                          <li>
                            <img src="/images/circle-cliks.png" alt="" /> They
                            create profile
                          </li>
                          <li>
                            <img src="/images/circle-cliks.png" alt="" /> You
                            earn money !
                          </li>
                        </ul>
                      </div>

                      <div className="link_supports">
                        <div className="ad_list">
                          <input
                            type="text"
                            value={`${REFERAL_CODE_LINK}${profileData.referral_code}`}
                            placeholder=""
                            className="ad_likns"
                            readOnly
                          />
                          <button
                            type="button"
                            className="btn_supot"
                            onClick={handleCopy}
                          >
                            {buttonText}
                          </button>
                        </div>

                        <p>More ways to invite your friends</p>
                        <ul>
                          <li>
                            {/* <Link to="" className="lnk_brdss">
                              <img src="/images/ic_facebc.png" alt="" />
                            </Link> */}
                            <FacebookShareButton
                              url={`${REFERAL_CODE_LINK}${profileData.referral_code}`}
                              quote={"Check out this awesome website"}
                            >
                              <FacebookIcon size={32} round />
                            </FacebookShareButton>
                          </li>
                          <li>
                            {/* <Link to="" className="lnk_brdss">
                              <img src="/images/ic_links.png" alt="" />
                            </Link> */}
                            <LinkedinShareButton
                              url={`${REFERAL_CODE_LINK}${profileData.referral_code}`}
                            >
                              <LinkedinIcon size={32} round />
                            </LinkedinShareButton>
                          </li>
                          <li>
                            {/* <Link to="" className="lnk_brdss">
                              <img src="/images/ic_whats.png" alt="" />
                            </Link> */}
                            <TelegramShareButton
                            url={`${REFERAL_CODE_LINK}${profileData.referral_code}`}
                            quote={"Check out this awesome website"}
                            >
                            <TelegramIcon size={32} round />
                            </TelegramShareButton>
                          </li>
                          <li>
                            {/* <Link to="" className="lnk_brdss">
                              <img src="/images/ic_twitter.png" alt="" />
                            </Link> */}

                            <TwitterShareButton
                              url={`${REFERAL_CODE_LINK}${profileData.referral_code}`}
                              quote={"Check out this awesome website"}
                            >
                              <TwitterIcon size={32} round />
                            </TwitterShareButton>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* {profileData.parent_referral_code && ( */}
                    <>
                      {" "}
                      <div className="al_pg_suportss">
                        <div className="top_sectss mb-3">
                          <p>Referred By</p>
                        </div>

                        <div className="link_supports">
                          <div className="ad_list singalss">
                            <input
                              type="text"
                              value={profileData.parent_referral_code}
                              placeholder=""
                              className="ad_likns"
                              readOnly={true}
                            />
                            <Link to="/referral-list">
                              <button
                                type=""
                                value="Your Total Referral (02)"
                                className="btn_supot"
                              >
                                Your Total Referral
                                {/* (02) */}
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </>
                    {/* )} */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Login;

import { Link, useParams, useNavigate } from "react-router-dom";
import { React, useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import CountUp from "react-countup";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import axiosClient from "../Services/Axios/axios";
import { BeatLoader } from "react-spinners";
import dayjs from "dayjs";
//import ShareModal from "./common/ShareModal";
import NewsItem from "./common/NewsItem.js";


const blogsslider = {
  margin: 0,
  responsiveClass: true,
  nav: true,
  dots: false,
  autoplay: false,
  navText: ["<", ">"],
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 1,
    },
    700: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};

const BlogsDetailsCategoryWise = () => {
  const { page_url } = useParams();
  // const page_url = "testtse";
  const [loading, setLoading] = useState(false);
  const [exploreData, setExploreData] = useState([]);
  const navigate = useNavigate(); // Define navigate
  //const [shareModal, setShareModal] = useState(false);
  const getSingleNewsData = async () => {
    try {
      setLoading(true);
      const data = await axiosClient.get(
        `news/NewsListingCategorywise/${page_url}?language=en`
      );
      if (!data.data.error) {
        setExploreData(data.data.data.rows);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  // const handleShareModal = async () => {
  //   setShareModal(!shareModal);
  // };
  useEffect(() => {
    getSingleNewsData();
  }, [page_url]);

  if (loading) {
    return (
      <div className="loader">
        <BeatLoader color="#097C81" />
      </div>
    );
  }
  console.log(exploreData, "exploreData");
  return (
    <>
      <div className="all_suport_bg manages_top">
        <section
          className="ser_v_araeea evnt_slisder allss news_dtlss"
          id="blogss"
        >
          <div className="container">
            <div className="dtl_titlesss">
              <h2>{exploreData[0]?.blog_category.name}</h2>
              {/* <span className="dt_shrae">
                {dayjs(newsData?.created_date).format("DD MMM, YYYY")}{" "}
                <img
                  src="/images/shareandroi_ic.png"
                  alt=""
                  onClick={handleShareModal}
                />
              </span> */}
            </div>
          </div>
        </section>

        <section className="ser_v_araeea pt-0" id="news_featured_hm">
          <div className="container">
            <div className="row">
              {exploreData?.map((res, i) => (
                <div
                  className="col-lg-4 col-sm-6 mt-4 mb_top_space nws_details"
                  onClick={() => navigate(`/news-details/${res.slug}`)} 
                >
                  <div className="news_box_arara">
                    <div className="nes_mg_arae">
                      <img src={res.image} alt="" />
                    </div>
                    <span className="daytss">
                      {dayjs(res.created_date).format("DD-MM-YYYY")}
                      {res.blog_category && (
                        <span className="ctgrees">
                          {res.blog_category.name}
                        </span>
                      )}
                    </span>
                    <h3>{res.name}</h3>
                    {/* <div
                      className="max-h-185"
                      dangerouslySetInnerHTML={{
                        __html: res.description,
                      }}
                    ></div> */}
                      <NewsItem res={res} /> 
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        {/* <ShareModal
          isModalOpen={shareModal}
          handleClose1={handleShareModal}
          ShareText={newsData?.name}
        /> */}
      </div>
    </>
  );
};

export default BlogsDetailsCategoryWise;

import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import Swal from "sweetalert2";
import axiosClient from "../../Services/Axios/axios";
import ProfileSidebar from "../common/ProfileSidebar";


const UpdateCampaign = () => {
  const location = useLocation();
  const { id } = useParams();
  const [image, setImage] = useState(null);
  const fileInputRef = useRef();
  const [buttonloader, setButtonloader] = useState(false);
  const navigate = useNavigate();

  const [formState, setFormState] = useState({
    donation_title: "",
    global_amount: "",
    donation_description: "",
    donation_category_id: "",
    donation_images: "",
    expiry_date: new Date(),
    other_images: [""],
    documents: [""],
    campaign_id: +id,
  });
  const [categoryData, setCategoryData] = useState([]);
  const datepickerRef = useRef(null);
  const [errors, setErrors] = useState({});

  const [documentUploadStatus, setDocumentUploadStatus] = useState(
    formState.documents.map(() => ({ uploading: false, successMessage: "" }))
  );
  const [galleryUploadStatus, setGalleryUploadStatus] = useState(
    formState.other_images.map(() => ({ uploading: false, successMessage: "" }))
  );

  const [coverImageUploadStatus, setCoverImageUploadStatus] = useState({
    uploading: false,
    successMessage: "",
  });

  const validate = () => {
    const newErrors = {};
    if (!formState.donation_title.trim())
      newErrors.donation_title = "Title is required.";
    if (!formState.global_amount.trim() || isNaN(formState.global_amount))
      newErrors.global_amount = "Valid amount is required.";
    if (!formState.donation_description.trim())
      newErrors.donation_description = "Description is required.";
    if (!formState.donation_category_id)
      newErrors.donation_category_id = "Category is required.";
    if (!formState.donation_images)
      newErrors.donation_images = "Cover image is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e, index, type) => {
    const { name, value, files } = e.target;
    

    setFormState({ ...formState, [name]: value });
  };

  const addMoreFields = (type) => {
    if (type === "gallery") {
      setFormState({
        ...formState,
        other_images: [...formState.other_images, ""],
      });
      setGalleryUploadStatus((prev) => [
        ...prev,
        { uploading: false, successMessage: "" },
      ]);
    } else if (type === "documents") {
      setFormState({ ...formState, documents: [...formState.documents, ""] });

      setDocumentUploadStatus((prev) => [/*by me*/
         ...prev,
        { uploading: false, successMessage: "" },
      ]);
    }
  };

  const removeField = (index, type) => {
    if (type === "gallery") {
      const updatedImages = formState.other_images.filter(
        (_, i) => i !== index
      );
      setFormState({ ...formState, other_images: updatedImages });
      setGalleryUploadStatus((prev) => prev.filter((_, i) => i !== index));
    } else if (type === "documents") {
      const updatedDocuments = formState.documents.filter(
        (_, i) => i !== index
      );
      setFormState({ ...formState, documents: updatedDocuments });

      setDocumentUploadStatus((prev) =>
        prev.filter((_, i) => i !== index)
      ); /*by me*/
    }
  };
  const handleChange1 = async (e, index, type) => {
    try {
      const file = e.target.files[0];
      
      setErrors((prevErrors) => ({ ...prevErrors, donation_images: "" }));

      if (!file) return;

      // setDocumentUploadStatus((prev /*by me*/) =>
      //   prev.map((status, i) =>
      //     i === index ? { ...status, successMessage: "" } : status
      //   )
      // );

      // Allowed file types
      if (type === "gallery") {
        setGalleryUploadStatus((prev) =>
          prev.map((status, i) =>
            i === index ? { uploading: true, successMessage: "" } : status
          )
        );
      } else if (type === "documents") {
        setDocumentUploadStatus((prev) =>
          prev.map((status, i) =>
            i === index ? { uploading: true, successMessage: "" } : status
          )
        );
      }

      const imageTypes = ["image/jpeg", "image/jpg", "image/png"];
      const documentTypes = ["application/pdf"];

      const maxSizeInBytes = parseInt(
        process.env.REACT_APP_MAX_FILE_SIZE_MB,
        10
      );

      console.log(maxSizeInBytes); // Outputs: 5242880

      let formData = new FormData();
      let uploadUrl = "";
      let appendKey = "";
      const validateImageDimensions = (file) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => {
            if (img.width === 790 && img.height === 475) {
              resolve(true);
            } else {
              reject("Error: Image dimensions must be 790x475 pixels.");
            }
          };
          img.onerror = () => reject("Invalid image file.");
          img.src = URL.createObjectURL(file);
        });
      };

      // Validation & API Endpoint Selection
      if (type === "gallery") {
        
        if (!imageTypes.includes(file.type)) {
          setGalleryUploadStatus((prev) =>
            prev.map((status, i) =>
              i === index
                ? {
                    uploading: true,
                    successMessage:"",
                  }
                : status
            )
          
          );
          console.log("xxxxx",setGalleryUploadStatus.successMessage);
          setTimeout(() => {
            setGalleryUploadStatus((prev) =>
              prev.map((status, i) =>
                i === index ? { ...status, uploading: false } : status
              )
            );
            Swal.fire({
              icon: "error",
              title: "Only images (JPG, JPEG, PNG) are allowed in the gallery",
            });
          }, 2000);
          e.target.value = null;
          return;
        }
        try {
          await validateImageDimensions(file);
        } catch (error) {
          setGalleryUploadStatus((prev) =>
            prev.map((status, i) =>
              i === index
                ? {
                    uploading: true,
                    successMessage: "",
                  }
                : status
            )
          );
          setTimeout(() => {
            setGalleryUploadStatus((prev) =>
              prev.map((status, i) =>
                i === index ? { ...status, uploading: false } : status
              )
            );
            Swal.fire({
              icon: "error",
              title: "Invalid Image Dimensions",
              text: error,
            });
          }, 2000);

          e.target.value = null;
          return;
        }
        appendKey = "image";
        uploadUrl = "/users/uploadimg";
      } else if (type === "documents") {
        if (!documentTypes.includes(file.type)) {
          setDocumentUploadStatus((prev) =>
            prev.map((status, i) =>
              i === index
                ? {
                    uploading: true,
                    successMessage: "",
                  }
                : status
            )
          );
          setTimeout(() => {
            setDocumentUploadStatus((prev) =>
              prev.map((status, i) =>
                i === index
                  ? {
                      uploading: false,
                      successMessage: "",
                    }
                  : status
              )
            );

            Swal.fire({
              icon: "error",
              title: "Invalid File Type",
              text: "Only PDF files are allowed.",
            });
          }, 2000);
          e.target.value = "";
          return;
        }
        if (file.size > maxSizeInBytes) {
          setDocumentUploadStatus((prev) =>
            prev.map((status, i) =>
              i === index
                ? {
                    uploading: true,
                    successMessage: "",
                  }
                : status
            )
          );

          setTimeout(() => {
            setDocumentUploadStatus((prev) =>
              prev.map((status, i) =>
                i === index
                  ? {
                      uploading: false,
                      successMessage: "",
                    }
                  : status
              )
            );
            // setUploading(false);
            Swal.fire({
              icon: "error",
              title: "File Size Exceeded",
              text: `File size must be less than 5 MB.`,
            });
          }, 2000);
          e.target.value = "";
          return;
        }

        appendKey = "pdf";
        uploadUrl = "/users/uploadpdf";
      } else if (type === "donation_images") {
        if (!imageTypes.includes(file.type)) {
          setCoverImageUploadStatus({ uploading: true, successMessage: "" });
          setTimeout(() => {
            setCoverImageUploadStatus({ uploading: false, successMessage: "" });
          Swal.fire({
            icon: "error",
            title: "Only images (JPG, JPEG, PNG) are allowed in the gallery",
          });
        }, 2000);
          
          e.target.value = "";
          return;
        }
        try {
          await validateImageDimensions(file);
        } catch (error) {
          setCoverImageUploadStatus({ uploading: true, successMessage: "" });
          setTimeout(() => {
            setCoverImageUploadStatus({ uploading: false, successMessage: "" });
            Swal.fire({
              icon: "error",
              title: "Invalid Image Dimensions",
              text: error,
            });
          }, 2000);

          e.target.value = null;
          return;
        }
        setCoverImageUploadStatus({ uploading: true, successMessage: "" });
        appendKey = "image";
        uploadUrl = "/users/uploadimg";
      }

      if (type === "documents") {
        setDocumentUploadStatus((prev) =>
          prev.map((status, i) =>
            i === index ? { ...status, uploading: true } : status
          )
        );
      }
      if (type === "gallery") {
        setGalleryUploadStatus((prev) =>
          prev.map((status, i) =>
            i === index ? { ...status, uploading: true } : status
          )
        );
      }

      // Upload logic
      formData.append(appendKey, file);
      const responce = await axiosClient.post(uploadUrl, formData);

      // if (type === "gallery") {
      //   setGalleryUploadStatus((prev) =>
      //     prev.map((status, i) => (i === index ? { uploading: false } : status))
      //   );
      // }

      // setDocumentUploadStatus((prev) =>
      //   prev.map((status, i) =>
      //     i === index ? { ...status, uploading: false } : status
      //   )
      // );
      

      if (!responce.data.error) {
        if (type === "gallery") {
          const updatedImages = [...formState.other_images];
          updatedImages[index] = responce.data.file;
          setFormState({ ...formState, other_images: updatedImages });
          setGalleryUploadStatus((prev) =>
            prev.map((status, i) =>
              i === index
                ? {
                  uploading: false,
                    successMessage: "Document uploaded successfully!",
                  }
                : status
            )
          );
          return;
        } else if (type === "documents") {
          const updatedDocuments = [...formState.documents];
          updatedDocuments[index] = responce.data.file;
          setFormState({ ...formState, documents: updatedDocuments });
      
          setDocumentUploadStatus((prev) =>
            prev.map((status, i) =>
              i === index
                ? {
                  uploading: false,
                    successMessage: "Document uploaded successfully!",
                  }
                : status
            )
          );
          return;
        } else if (type === "donation_images") {
          setFormState({ ...formState, donation_images: responce.data.file });
          setCoverImageUploadStatus({
            uploading: false,
            successMessage: "Cover image uploaded successfully!",
          });
          return;
        }
      }

      // Show preview for images only
      if (imageTypes.includes(file.type)) {
        const reader = new FileReader();
        reader.onload = () => {
          setImage(reader.result);
        };
        reader.readAsDataURL(file);
      }
    } catch (error) {
      setDocumentUploadStatus((prev) =>
        prev.map((status, i) =>
          i === index ? { ...status, uploading: false } : status
        )
      );
      Swal.fire({
        icon: "error",
        title: "File is too large",
      });
      // if (type === "gallery") {
      //   setGalleryUploadStatus((prev) =>
      //     prev.map((status, i) =>
      //       i === index ? { uploading: false, successMessage: "" } : status
      //     )
      //   );
      // } else if (type === "documents") {
      //   setDocumentUploadStatus((prev) =>
      //     prev.map((status, i) =>
      //       i === index ? { uploading: false, successMessage: "" } : status
      //     )
      //   );
      // }
    
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (validate()) {
        setButtonloader(true);
        const data = await axiosClient.put(`/campaigns/edit-campaign`, {
          ...formState,
          expiry_date: dayjs(formState.expiry_date).format("YYYY/MM/DD"),
        });
        if (!data.data.error) {
          Swal.fire({
            icon: "success",
            title: data.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        setFormState({
          ...formState,
          donation_title: "",
          global_amount: "",
          donation_description: "",
          donation_category_id: "",
          donation_images: "",
          expiry_date: new Date(),
          other_images: [""],
          documents: [""],
        });
        navigate("/campaign");
        if (fileInputRef.current) {
          fileInputRef.current.value = ""; // Clearing the file input
        }
        setButtonloader(false);
      }
    } catch (error) {
      console.log(error);
      setButtonloader(false);
      Swal.fire({
        icon: "error",
        title: error.response.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  const getSingleCampaign = async () => {
    const data = location.state;
    const response = await axiosClient.get(
      `/campaigns/getMyCampaignDetailsById/${id}?language=en`
    );
    console.log("zzzzzzz", response);
    if (response.data && !response.data.error) {
      console.log(response.data.data, "response.data.data");
      setFormState({
        ...formState,
        donation_title: response.data.data.donation_title,
        global_amount: response.data.data.global_amount,
        donation_description: response.data.data.donation_description,
        donation_category_id: response.data.data.donation_category_id,
        donation_images: response.data.data.donation_images,
        expiry_date: response.data.data.expiry_date,
        other_images: response.data.data.campaign_galleries
          .filter((item) => item.type === 1)
          .map((item) => item.image_url),
        documents: response.data.data.campaign_galleries
          .filter((item) => item.type === 2)
          .map((item) => item.image_url),
      });
      setGalleryUploadStatus(response.data.data.campaign_galleries
        .filter((item) => item.type === 1)
        .map(() => ({ uploading: false, successMessage: "" }))
      );

      setDocumentUploadStatus(response.data.data.campaign_galleries
        .filter((item) => item.type === 2)
        .map(() => ({ uploading: false, successMessage: "" }))
      );
      // set;
    }
    // setFormState(data);
  };

  useEffect(() => {
    getSingleCampaign();
  }, [id]);

  const getAllData = async () => {
    try {
      const category = await axiosClient.get(
        `/campaigns/getCampaignsCategory?language=en`
      );
      if (!category.data.error) {
        setCategoryData(
          category.data.data.map((item) => {
            return {
              label: item.category_name,
              value: item.id,
            };
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);
  return (
    <>
      <div className="all_suport_bg manages_top">
        <section className="ser_v_araeea evnetsts profiles">
          <div className="container">
            <div className="bradcams mb_view_hide">
              <span className="bothss">
                <Link to="/profile">My Account</Link>{" "}
                <img src="/images/brd_rowwo.png" alt="" />{" "}
                <Link to="/campaign">Campaigns</Link>
                <img src="/images/brd_rowwo.png" alt="" />{" "}
                <Link to="">Update Campaign</Link>
              </span>
            </div>
            <div className="row">
              <div className="col-lg-3 mb_view_hide">
                <ProfileSidebar />
              </div>
              <div className="col-lg-9">
                <div className="form_boxx profilss">
                  <h4 className="br_space">Update Campaign</h4>
                  <div className="form_arar mt-0">
                    <div className="mang_und_listst mt-0">
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="form-group">
                                  <label>Title</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="donation_title"
                                    placeholder="Enter title"
                                    value={formState.donation_title}
                                    onChange={handleChange}
                                  />
                                  {errors.donation_title && (
                                    <small className="text-danger">
                                      {errors.donation_title}
                                    </small>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-12">
                                <div className="form-group">
                                  <label>About Campaign</label>
                                  <textarea
                                    className="form-control"
                                    rows="4"
                                    name="donation_description"
                                    placeholder=""
                                    value={formState.donation_description}
                                    onChange={handleChange}
                                  ></textarea>
                                  {errors.donation_description && (
                                    <small className="text-danger">
                                      {errors.donation_description}
                                    </small>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label>Amount</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="global_amount"
                                    placeholder="Enter amount"
                                    value={formState.global_amount}
                                    onChange={handleChange}
                                  />
                                  {errors.global_amount && (
                                    <small className="text-danger">
                                      {errors.global_amount}
                                    </small>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label>Category</label>
                                  <select
                                    className="form-control"
                                    name="donation_category_id"
                                    value={formState.donation_category_id}
                                    onChange={handleChange}
                                  >
                                    {categoryData &&
                                      categoryData.map((item) => {
                                        return (
                                          <option value={item.value}>
                                            {item.label}
                                          </option>
                                        );
                                      })}
                                  </select>
                                  {errors.donation_category_id && (
                                    <small className="text-danger">
                                      {errors.donation_category_id}
                                    </small>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="form-group relatives">
                                  <label>Deadline</label>
                                  <DatePicker
                                    selected={formState.expiry_date}
                                    onChange={(date) =>
                                      setFormState({
                                        ...formState,
                                        expiry_date: date,
                                      })
                                    }
                                    minDate={new Date()}
                                    ref={datepickerRef}
                                  />
                                  <img
                                    src="/images/calenders.png"
                                    alt="Calendar"
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "10px",
                                    }}
                                    onClick={() =>
                                      datepickerRef.current.setOpen(true)
                                    }
                                  />
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="form-group" id="browsers">
                                  <label>Cover Image</label>
                                  <input
                                    type="file"
                                    className="form-control"
                                    name="donation_images"
                                    onChange={(e) =>
                                      handleChange1(e, 0, "donation_images")
                                    }
                                  />
                                  <img src={formState?.donation_images} />
                                  {coverImageUploadStatus.uploading && (
                                    <ClipLoader
                                      color="#000"
                                      size={20}
                                      className="ml-2"
                                    />
                                  )}
                                  {coverImageUploadStatus.successMessage && (
                                    <small className="text-success">
                                      {coverImageUploadStatus.successMessage}
                                    </small>
                                  )}
                                  {errors.donation_images && (
                                    <small className="text-danger">
                                      {errors.donation_images}
                                    </small>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-12">
                                <div
                                  className="form-group"
                                  id="add_more_filess"
                                >
                                  <div className="both_managess">
                                    <label>Gallery Image</label>
                                    <div className="ad_buttons">
                                      <Link
                                        to="#"
                                        onClick={() => addMoreFields("gallery")}
                                      >
                                        + Add More
                                      </Link>
                                    </div>
                                  </div>

                                  {formState?.other_images?.map(
                                    (image, index) => (
                                      <div
                                        className="row add_mor_lisstst"
                                        key={index}
                                      >
                                        <div className="col-lg-10 col-8">
                                          <div
                                            className="form-group mb-1"
                                            id="browsers"
                                          >
                                            <input
                                              type="file"
                                              className="form-control"
                                              ref={fileInputRef}
                                              onChange={(e) =>
                                                handleChange1(
                                                  e,
                                                  index,
                                                  "gallery"
                                                )
                                              }
                                            />
                                            {galleryUploadStatus[index]
                                              ?.uploading && (
                                              <ClipLoader
                                                color="#000"
                                                size={20}
                                                className="ml-2"
                                              />
                                            )}
                                            {galleryUploadStatus[index]
                                              ?.successMessage && (
                                              <small className="text-success">
                                                {
                                                  galleryUploadStatus[index]
                                                    .successMessage
                                                }
                                              </small>
                                            )}

                                            {image ? <img src={image} /> : null}
                                          </div>
                                        </div>
                                        <div className="col-lg-2 col-4">
                                          <div className="ad_buttons remove_bnt">
                                            <Link
                                              to="#"
                                              onClick={() =>
                                                removeField(index, "gallery")
                                              }
                                            >
                                              Remove
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-12">
                                <div
                                  className="form-group"
                                  id="add_more_filess"
                                >
                                  <div className="both_managess">
                                    <label>Documents</label>
                                    <div className="ad_buttons">
                                      <Link
                                        to="#"
                                        onClick={() =>
                                          addMoreFields("documents")
                                        }
                                      >
                                        + Add More
                                      </Link>
                                    </div>
                                  </div>

                                  {formState?.documents?.map((doc, index) => (
                                    <div
                                      className="row add_mor_lisstst"
                                      key={index}
                                    >
                                      <div className="col-lg-10 col-8">
                                        <div
                                          className="form-group mb-1"
                                          id="browsers"
                                        >
                                          <input
                                            type="file"
                                            className="form-control"
                                            ref={fileInputRef}
                                            onChange={(e) =>
                                              handleChange1(
                                                e,
                                                index,
                                                "documents"
                                              )
                                            }
                                          />

                                          {doc ? (
                                            doc.includes(".pdf") ? (
                                              <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                download
                                                href={doc}
                                              >
                                                Download PDF
                                              </a>
                                            ) : (
                                              <img
                                                src={doc}
                                                alt="Uploaded file preview"
                                              />
                                            )
                                          ) : null}
                                          <br />
                                          {/* {doc ? <img src={doc} /> : null} */}
                                          {documentUploadStatus[index]
                                            ?.uploading && (
                                            <ClipLoader
                                              color="#000"
                                              size={20}
                                              className="ml-2"
                                            />
                                          )}
                                          {documentUploadStatus[index]
                                            ?.successMessage && (
                                            <small className="text-success">
                                              {
                                                documentUploadStatus[index]
                                                  .successMessage
                                              }
                                            </small>
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-lg-2 col-4">
                                        <div className="ad_buttons remove_bnt">
                                          <Link
                                            to="#"
                                            onClick={() =>
                                              removeField(index, "documents")
                                            }
                                          >
                                            Remove
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12 mt-4">
                            <div className="form-group">
                              <button
                                type="submit"
                                value="Publish Event"
                                className="sub_mitess"
                                disabled={buttonloader}
                              >
                                {buttonloader ? (
                                  <ClipLoader color="#fff" size={"25"} />
                                ) : (
                                  "Update Campaign"
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default UpdateCampaign;

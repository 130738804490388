import { Check } from "lucide-react";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  TelegramIcon,
  TelegramShareButton,
} from "react-share";
import copyIcon from "../../assets/Images/nimbus_copy.png";


const ShareModal = ({ isModalOpen, handleClose1, ShareText, shareUrl }) => {
  // const [shareUrl, setShareUrl] = useState(window.location.href);
  
  const [shareText, setShareLine] = useState(ShareText);
  const [copied, setCopied] = useState(false); // State to track copied status
  // const staticUrl = "https://worldtrust.allproject.online/";

  const handleCopyStaticLink = () => {
    navigator.clipboard.writeText(shareUrl).then(() => {
      setCopied(true); // Change icon to check
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    }).catch(err => {
      console.error("Failed to copy: ", err);
    });
  };

  return (
    <Modal
      className="all_supportss sm_sizess"
      id="capture"
      show={isModalOpen}
      onHide={handleClose1}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="sher_mdl_area">
          <h1>Share this on Social Media</h1>
        
          <div className="ic_icon_area">
            <TelegramShareButton url={shareUrl} title={shareText}> 
              <TelegramIcon size={32} round />
              </TelegramShareButton>

            <FacebookShareButton url={shareUrl} quote={shareText}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>

            <TwitterShareButton url={shareUrl} title={shareText}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>

            <LinkedinShareButton url={shareUrl}>
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
            <button onClick={handleCopyStaticLink} className="copyIconBtn">
              {copied ? <Check size={32} color="green" /> : <img className="iconCopy" src={copyIcon} alt="Copy" width={40} height={40} />}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShareModal;
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ProfileSidebar from "./common/ProfileSidebar";
import axiosClient from "../Services/Axios/axios";
import Swal from "sweetalert2";
import ImageUploader from "./common/ImageUploader";
import { ClipLoader } from "react-spinners";
import PhoneInput from "react-country-phone-input";

const Careers = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [userNewData, setUserNewData] = useState({});
  const [profileImage, setProfileImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    phoneCode: "",
    fullPhone: "",
    gender: "",
    dob: startDate,
    referral_code: "",
    company_name: "",
    profile_image: "",
    role_type: "5",
  });

  const [errorsValidation, setErrorsValidation] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    gender: "",
    dob: "",
  });

  const handleDateChange = (date) => {
    setStartDate(date.target.value);
    setProfileData({
      ...profileData,
      dob: date.target.value,
    });
    if (errorsValidation.dob) {
      setErrorsValidation((prev) => ({ ...prev, dob: "" }));
    }
  };

  const handlePhoneChange = (value, country) => {
    setProfileData({
      ...profileData,
      fullPhone: value,
      phone: value.slice(country.dialCode.length),
      phoneCode: `+${country.dialCode}`,
    });
    if (errorsValidation.fullPhone) {
      setErrorsValidation((prev) => ({ ...prev, fullPhone: "" }));
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    let formIsValid = true;
    let errors = {};

    // Validation for First Name
    if (!profileData.first_name) {
      formIsValid = false;
      errors.first_name = "First name is required";
    } else if (profileData.first_name.length > 50) {
      formIsValid = false;
      errors.first_name = "First name cannot exceed 50 characters";
    } else if (!/^[A-Za-z\s]*$/.test(profileData.first_name)) {
      formIsValid = false;
      Swal.fire({
        icon: "error",
        title: "Invalid Input",
        text: "First name can only contain letters.",
      });
    }
    

    // Validation for Last Name
    if (!profileData.last_name) {
      formIsValid = false;
      errors.last_name = "Last name is required";
    } else if (profileData.last_name.length > 50) {
      formIsValid = false;
      errors.first_name = "last name cannot exceed 50 characters";
    } else if (!/^[A-Za-z\s]*$/.test(profileData.last_name)) {
      formIsValid = false;
      Swal.fire({
        icon: "error",
        title: "Invalid Input",
        text: "First name can only contain letters.",
      });
    }

    // Validation for Email
    if (!profileData.email) {
      formIsValid = false;
      errors.email = "Email address is required";
    } else if (!/\S+@\S+\.\S+/.test(profileData.email)) {
      formIsValid = false;
      errors.email = "Email address is invalid";
    }

    // Validation for Phone
    if (!profileData.phone) {
      formIsValid = false;
      errors.fullPhone = "Phone number is required";
    } 
    // else if (!/^\d{10}$/.test(profileData.phone)) {
    //   formIsValid = false;
    //   errors.fullPhone = "Phone number must be 10 digits";
    // }

    // Validation for Gender
    if (!profileData.gender) {
      formIsValid = false;
      errors.gender = "Gender is required";
    }

    // Validation for Date of Birth
    if (!profileData.dob) {
      formIsValid = false;
      errors.dob = "Date of birth is required";
    }
    if (userNewData?.role_type === 8 || userNewData?.role_type === 9) {
      if (!profileData.company_name) {
        formIsValid = false;
        errors.company_name = "please provide a Company name or NGO name";
      } else if (profileData.company_name.length > 50) {
        formIsValid = false;
        errors.company_name = "company name cannot exceed 50 characters";
      }
    }

    setErrorsValidation(errors);

    if (formIsValid) {
      try {
        setLoading(true);
        const upadatedData = await axiosClient.put("users/update-user", {
          ...profileData,
          profile_image: profileImage,
          user_role_type: +profileData.role_type,
        });
        if (!upadatedData.data.error) {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "User profile updated successfully.",
          });
          handleProfile();
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }

      // Add your form submission logic here
    }
  };

  const handleProfile = async () => {
    const response = await axiosClient.get("/users/getByID");
    if (!response.data.error) {
      localStorage.setItem("userData", JSON.stringify(response.data.data));
      // window.location.reload();
    }
  };

  function runAfterImageDelete(file) {
    console.log({ file });
  }
  useEffect(() => {
    setUserNewData(JSON.parse(localStorage.getItem("userData")));
  }, []);

  useEffect(() => {
    setProfileData({
      first_name: userNewData?.first_name,
      last_name: userNewData?.last_name,
      email: userNewData?.email,
      phone: userNewData?.phone,
      phoneCode: userNewData?.phoneCode,
      fullPhone: `${userNewData?.phoneCode} ${userNewData?.phone}`,
      fullPhone: userNewData?.phone ? `${userNewData.phoneCode} ${userNewData.phone}` : "",
      gender: userNewData?.gender,
      dob: userNewData?.dob,
      referral_code: userNewData?.referral_code,
      profile_image: userNewData?.profile_image,
      role_type: userNewData?.role_type,
      company_name: userNewData?.company_name,
    });
    setProfileImage(userNewData?.profile_image);
  }, [userNewData]);
  return (
    <>
      <div className="all_suport_bg manages_top">
        <section className="ser_v_araeea profiles">
          <div className="container">
            <div className="bradcams mb_view_hide">
              <span className="bothss">
                <Link to="">My Account</Link>{" "}
                <img src="/images/brd_rowwo.png" alt="" /> Profile
              </span>
            </div>
            <div className="row">
              <div className="col-lg-3 mb_view_hide">
                <ProfileSidebar />
              </div>
              <div className="col-lg-9">
                <div className="form_boxx profilss">
                  <div className="form_arar mt-0">
                    <h4>Profile</h4>
                    <div className="mang_und_listst mt-0">
                      <form action="" onSubmit={handleUpdate}>
                        <div className="row">
                          <div className="col-lg-9">
                            <div className="row">
                              <div className="col-lg-6 col-sm-6">
                                <div className="form-group">
                                  <label>First Name</label>
                                  <input
                                    max={10}
                                    type="text"
                                    className={`form-control ${
                                      errorsValidation.first_name
                                        ? "input-error"
                                        : ""
                                    }`}
                                    name="firstName"
                                    value={profileData.first_name}
                                    onChange={(e) => {
                                      setProfileData({
                                        ...profileData,
                                        first_name: e.target.value,
                                      });
                                      if (errorsValidation.first_name) {
                                        setErrorsValidation((prev) => ({ ...prev, first_name: "" }));
                                      }
                                    }}
                                    placeholder="Enter first name"
                                  />
                                  {errorsValidation.first_name && (
                                    <div className="error">
                                      {errorsValidation.first_name}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-6 col-sm-6">
                                <div className="form-group">
                                  <label>Last Name</label>
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errorsValidation.last_name
                                        ? "input-error"
                                        : ""
                                    }`}
                                    name="last_name"
                                    value={profileData.last_name}
                                    onChange={(e) => {
                                      setProfileData({
                                        ...profileData,
                                        last_name: e.target.value,
                                      });
                                      if (errorsValidation.last_name) {
                                        setErrorsValidation((prev) => ({ ...prev, last_name: "" }));
                                      }
                                    }}
                                    placeholder="Enter last name"
                                  />
                                  {errorsValidation.last_name && (
                                    <div className="error">
                                      {errorsValidation.last_name}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-6 col-sm-6">
                                <div className="form-group">
                                  <label>Email Address</label>
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errorsValidation.email
                                        ? "input-error"
                                        : ""
                                    }`}
                                    name="email"
                                    value={profileData.email}
                                    // onChange={(e) => {
                                    //   setProfileData({
                                    //     ...profileData,
                                    //     email: e.target.value,
                                    //   });
                                    // }}
                                    style={{ cursor: "not-allowed" }}
                                    placeholder="Enter email address"
                                    readOnly
                                  />
                                  {errorsValidation.email && (
                                    <div className="error">
                                      {errorsValidation.email}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-6 col-sm-6">
                                <div className="form-group">
                                  <label>Mobile No.</label>
                                  <PhoneInput
                                    className={`react-tel-input ${
                                      errorsValidation.fullPhone
                                        ? "input-error"
                                        : ""
                                    }`}
                                    placeholder="Enter Phone Number"
                                    country={"in"} // Default to 'in' or use stored country
                                    value={profileData.fullPhone || ""}
                                    onChange={(value, country) =>
                                      handlePhoneChange(value, country) 
                                    }
                                    inputProps={{
                                      name: "phoneNumber",
                                      required: true,
                                      // autoFocus: true,
                                    }}
                                  />
                                  {errorsValidation.fullPhone && (
                                    <div className="error">
                                      {errorsValidation.fullPhone}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-6 col-sm-6">
                                <div className="form-group">
                                  <label>Gender</label>
                                  <select
                                    className={`form-control ${
                                      errorsValidation.gender
                                        ? "input-error"
                                        : ""
                                    }`}
                                    name="gender"
                                    value={profileData.gender}
                                    onChange={(e) => {
                                      setProfileData({
                                        ...profileData,
                                        gender: e.target.value,
                                      });
                                      if (errorsValidation.gender) {
                                        setErrorsValidation((prev) => ({ ...prev, gender: "" }));
                                      }
                                    }}
                                  >
                                    <option value="">Choose your gender</option>
                                    <option value="1">Male</option>
                                    <option value="2">Female</option>
                                  </select>
                                  {errorsValidation.gender && (
                                    <div className="error">
                                      {errorsValidation.gender}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-6 col-sm-6">
                                <div className="form-group relatives">
                                  <label>Date of Birth</label>
                                  {/* <DatePicker
                                    name="dob"
                                    value={profileData.dob}
                                    selected={startDate}
                                    onChange={handleDateChange}
                                    dateFormat="yyyy-MM-dd"
                                  /> */}

                                  <input
                                    type="date"
                                    className="form-control"
                                    name="dob"
                                    value={profileData.dob}
                                    onChange={handleDateChange}
                                    placeholder="Enter date of birth"
                                    max={new Date().toISOString().split("T")[0]}
                                    onKeyDown={(e) => e.preventDefault()} // Prevent manual typing
                                  />
                                  {errorsValidation.dob && (
                                    <div className="error">
                                      {errorsValidation.dob}
                                    </div>
                                  )}
                                  {/* <img src="/images/calenders.png" alt="" />F */}
                                </div>
                              </div>

                              <div className="col-lg-6 col-sm-6">
                                <div className="form-group">
                                  <label>Referral Code</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="referCode"
                                    value={profileData.referral_code}
                                    readOnly
                                    style={{ cursor: "not-allowed" }}
                                    placeholder="Enter referral code"
                                  />
                                </div>
                              </div>
                              {(userNewData?.role_type === 8 ||
                                userNewData?.role_type === 9) && (
                                <>
                                  <div className="col-lg-6 col-sm-6">
                                    <div className="form-group">
                                      <label>Company / NGO</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="company_name"
                                        value={profileData.company_name}
                                        onChange={(e) => {
                                          setProfileData({
                                            ...profileData,
                                            company_name: e.target.value,
                                          });
                                        }}
                                        placeholder="Enter Company / NGO"
                                      />
                                      {errorsValidation.company_name && (
                                        <div className="error">
                                          {errorsValidation.company_name}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}

                              {(userNewData?.role_type === 5 ||
                                userNewData?.role_type === 7) && (
                                <>
                                  {" "}
                                  <div className="col-lg-12">
                                    <div className="form-group" id="rediiobnt">
                                      <label>Do you want to become?</label>
                                      <div class="rdo_boxx marg_bothss">
                                        <p>
                                          <input
                                            type="radio"
                                            id="individual"
                                            name="role_type"
                                            checked={
                                              profileData.role_type === 5 ||
                                              profileData.role_type === 7
                                            }
                                            value={"5"}
                                            onChange={({ target }) =>
                                              setProfileData({
                                                ...profileData,
                                                role_type: +target.value,
                                              })
                                            }
                                          />
                                          <label for="individual">
                                            Individual
                                          </label>
                                        </p>
                                        <p>
                                          <input
                                            name="role_type"
                                            checked={
                                              profileData.role_type === 8
                                            }
                                            value="8"
                                            type="radio"
                                            id="NGO"
                                            onChange={({ target }) =>
                                              setProfileData({
                                                ...profileData,
                                                role_type: +target.value,
                                              })
                                            }
                                          />
                                          <label for="NGO">NGO</label>
                                        </p>
                                        <p>
                                          <input
                                            type="radio"
                                            id="Company"
                                            name="role_type"
                                            checked={
                                              profileData.role_type === 9
                                            }
                                            value={"9"}
                                            onChange={(e) =>
                                              setProfileData({
                                                ...profileData,
                                                role_type: +e.target.value,
                                              })
                                            }
                                          />
                                          <label for="Company">Company</label>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div id="imgss_upload">
                              <ImageUploader
                                setState={setProfileImage}
                                defaultImage={profileImage}
                              />
                            </div>
                          </div>

                          <div className="col-lg-12 mt-4 prof_btnnns">
                            <div className="form-group">
                              <button
                                type="submit"
                                value="Update Profile"
                                className="sub_mitess"
                                disabled={loading}
                              >
                                {loading ? (
                                  <ClipLoader color="#fff" size={"15"} />
                                ) : (
                                  "Update Profile"
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Careers;

import React, { useEffect, useState } from "react";
import LogoIcon from "../../assets/Images/MiniLogo.png";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axiosClient from "../../Services/Axios/axios";
import Swal from "sweetalert2";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import ViewIcon from "../../assets/Images/eye-open.png";
import HideIcon from "../../assets/Images/eye-crossed.png";
import { BeatLoader } from "react-spinners";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";

const Register = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { role } = location.state || {};
  // Helper function to extract query parameters

  const [registerInfo, setRegisterInfo] = useState({
    first_name: "",
    email: "",
    password: "",
    role_type: role === "8" ? "8" : "5",
    company_name: "",
    parent_referral_code: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const getQueryParam = (key) => {
    const params = new URLSearchParams(location.search);
    console.log(params.get(key), "params");
    if (params.get(key) !== null) {
      setRegisterInfo({
        ...registerInfo,
        parent_referral_code: params.get(key),
      });
    }
    // if()
    // return params.get(key);
  }; 
  const firebaseConfig = {
    apiKey: "AIzaSyDvweZ-u_rORGyDoQ-lfvQ_BYnSkcNUYxk",
    authDomain: "world-ngo.firebaseapp.com",
    projectId: "world-ngo",
    storageBucket: "world-ngo.firebasestorage.app",
    messagingSenderId: "342829559045",
    appId: "1:342829559045:web:2ec8c6b1037e91485f9b6c",
    measurementId: "G-4KDR6QDD24"
  };


  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const auth = getAuth(app);
  const googleProvider = new GoogleAuthProvider();
  const facebookProvider = new FacebookAuthProvider();

  
  const [errorMessage, setErrorMessage] = useState("");
 const [loading, setLoading] = useState(false);

 

  const handleSubmit = async (evt) => {
    try {
      evt.preventDefault();

      let formIsValid = true;

      // Validation for First Name
      if (!registerInfo.first_name) {
        formIsValid = false;
        Swal.fire({
          icon: "error",
          title: "Invalid Input",
          text: "First name is required.",
        });
        return; // Stop the submission if invalid
      } else if (!/^[A-Za-z\s]*$/.test(registerInfo.first_name)) {
        formIsValid = false;
        Swal.fire({
          icon: "error",
          title: "Invalid Input",
          text: "First name can only contain letters.",
        });
        return; // Stop the submission if invalid
      }

      
      const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(
        registerInfo.password
      );
      const hasUpperCase = /[A-Z]/.test(registerInfo.password);
      const hasLowerCase = /[a-z]/.test(registerInfo.password);
      const hasNumber = /\d/.test(registerInfo.password);
      const isValidLength = registerInfo.password.length >= 8;

      if (
        !hasSpecialChar ||
        !hasUpperCase ||
        !hasLowerCase ||
        !hasNumber ||
        !isValidLength
      ) {
        Swal.fire({
          icon: "error",
          title: "Invalid Password",
          text: "Password must be at least 8 characters long, include at least one uppercase letter, one lowercase letter, one number, and one special character.",
        });
        return;
      }
      const data = await axiosClient.post(`/users/register`, {
        ...registerInfo,
        role_type: +registerInfo.role_type,
      });
      if (!data.data.error) {
        localStorage.setItem("email", registerInfo.email);
        navigate("/verification");
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Registration Failed",
        text:
          error.response?.data?.message ||
          "An error occurred during registration. Please try again.",
      });
    }
  };

  const handleGoogleLogin = async () => {
    setLoading(true);
    setErrorMessage("");
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      const userData = {
        email: user.email,
        first_name: user.displayName.split(" ")[0],
        last_name: user.displayName.split(" ").slice(1).join(" "),
        profile_image: user.photoURL,
        signup_type: "2",
        role_type: +registerInfo.role_type,
        parent_referral_code: registerInfo.parent_referral_code,
      };

      const response = await axiosClient.post(
        "/users/social-media-register",
        userData
      );
      if (response.data && !response.data.error) {
        localStorage.setItem("userData", JSON.stringify(response.data.data));
        Swal.fire({
          icon: "success",
          title: "Login Successful",
          text: "Welcome to WorldTrust!",
          showConfirmButton: false,
          timer: 1000,
        }).then(() => {
          navigate("/");
        });
      } else {
        throw new Error(response.data.message || "Failed to login with Google");
      }
    }
    //  catch (error) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Google Login Failed",
    //     text:
    //       error.message ||
    //       "An error occurred during Google login. Please try again.",
    //   });
    // }
    catch (error) { 
      console.log(error)
      // setErrorMessage(error.message || "An error occurred during Google login. Please try again.");
      // navigate("/login");
      if (error.code === 'auth/popup-closed-by-user') {
        // User closed the popup
        setErrorMessage("An error occurred during the process. Please try again.Feel free to reach out to our support team for assistance.");
        navigate("/register");
      } else {
        console.log("login Error", error);
        setErrorMessage("An error occurred during Google login. Please try again.");
        navigate("/register");
      }
    
      // Swal.fire({
      //   icon: "error",
      //   title: "Google Login Failed",
      //   text:
      //     error.message ||
      //     "An error occurred during Google login. Please try again.",
      // });
    }
    finally {
      setLoading(false); // Stop loading
    }
  };

  const handleFacebookLogin = async () => {
    setErrorMessage("");
    setLoading(true);
    try {
      const result = await signInWithPopup(auth, facebookProvider);
      const user = result.user;
      const userData = {
        email: user.email,
        first_name: user.displayName.split(" ")[0],
        last_name: user.displayName.split(" ").slice(1).join(" "),
        profile_image: user.photoURL,
        signup_type: "1",
        role_type: +registerInfo.role_type,
        parent_referral_code: registerInfo.parent_referral_code,
      };

      const response = await axiosClient.post(
        "/users/social-media-register",
        userData
      );
      if (response.data && !response.data.error) {
        localStorage.setItem("userData", JSON.stringify(response.data.data));
        Swal.fire({
          icon: "success",
          title: "Login Successful",
          text: "Welcome to WorldTrust!",
          showConfirmButton: false,
          timer: 1000,
        }).then(() => {
          navigate("/");
        });
      } else {
        throw new Error(
          response.data.message || "Failed to login with Facebook"
        );
      }
    }
    //  catch (error) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Facebook Login Failed",
    //     text:
    //       error.message ||
    //       "An error occurred during Facebook login. Please try again.",
    //   });
    // }
   //}; 
  catch (error) { 
    if (error.code === 'auth/popup-closed-by-user') {
      setErrorMessage("An error occurred during the process. Please try again.Feel free to reach out to our support team for assistance.");
      navigate("/register");
    } else {
      console.log("login Error", error);
      setErrorMessage("An error occurred during Google login. Please try again.");
      navigate("/register");
    }
  }
  finally {
    setLoading(false);
  }
};

  useEffect(() => {
    getQueryParam("referral");
  }, [location]);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [errorMessage]);

  return (
    <>
      <div className="container-fluid vh-100 d-flex justify-content-center login-container manages_top">
        {loading && (
        <>
          <div className="blur-background"></div> 
          <div className="loader" style={{ zIndex:1000,position:'fixed'}}>
            <BeatLoader color="#097C81" />
          </div>
        </>
        )}
        <div className="row w-100 h-100">
          <div className="col-md-6 d-none d-md-block" id="desk_top_hide">
            {/* <div className="h-100 d-flex flex-column justify-content-center align-items-center">
            <img src={LogoIcon} alt="WorldTrust" className="img-fluid" />
            <h5 className="mt-3">Helping with Donations</h5>
          </div> */}
          </div>
          <div className="col-md-6 d-flex flex-column justify-content-center align-items-center" id="cnt_managess_area">
            <div className="card p-4 w-100 d-flex flex-column justify-content-center align-items-center sc_lgo_allss">
              <div className="d-flex justify-content-center   ">
                <img src={LogoIcon} alt="WorldTrust" />
              </div>
              <h3 className="text-center">Welcome to WorldTrust</h3>
             
              <p className="text-center fw-bolder ">
                Continue using email address
              </p>

              <form onSubmit={handleSubmit} className="w-50" id="width_auto_al_screens">
                <div className="form-group mb-2">
                  <label className="mb-1">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Your Name"
                    value={registerInfo.first_name}
                    onChange={({ target }) =>
                      setRegisterInfo({
                        ...registerInfo,
                        first_name: target.value,
                      })
                    }
                    required
                  />
                </div>

                {registerInfo.role_type !== "5" && (
                  <>
                    <div className="form-group mb-2">
                      <label className="mb-1">Company Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Your Company Name"
                        value={registerInfo.company_name}
                        onChange={({ target }) =>
                          setRegisterInfo({
                            ...registerInfo,
                            company_name: target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </>
                )}

                <div className="form-group mb-2">
                  <label className="mb-1">Email Address</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter Email Address"
                    value={registerInfo.email}
                    onChange={({ target }) =>
                      setRegisterInfo({
                        ...registerInfo,
                        email: target.value,
                      })
                    }
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label className="mb-1">Password</label>
               <div className="input-group">
                 <input
                  type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Enter password"
                    value={registerInfo.password}
                    onChange={({ target }) =>
                      setRegisterInfo({
                        ...registerInfo,
                        password: target.value,
                      })
                    }
                    required
                  />
                   <button
                    type="button"
                    className="btn btn-outline-secondary LoginViewButton"
                    onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
                  >
                    {showPassword ? (
                      <img src={ViewIcon} alt="Hide" /> // Change to your hide icon
                    ) : (
                      <img src={HideIcon} alt="View" /> // Change to your view icon
                    )}
                  </button>
                </div>
                </div>
				
				<div className="dc_crip_tionss">
				 <p>Your password must have at least:</p>
				 <ul>
				  <li>Minimum 8 characters</li>
				  <li>1 Uppercase Letter</li>
				  <li>1 Lowercase Letter</li>
				  <li>1 Number</li>
				  <li>1 Symbol</li>
				 </ul>
				</div>

                <div className="form-group redo_sltt_area" id="rediiobnt">
                  <div className="rdo_boxx">
                    <p>
                      <input
                        type="radio"
                        id="individual"
                        name="role_type"
                        checked={registerInfo.role_type === "5"}
                        value="5"
                        onChange={({ target }) =>
                          setRegisterInfo({
                            ...registerInfo,
                            role_type: target.value,
                          })
                        }
                      />
                      <label for="individual">individual</label>
                    </p>
                    <p>
                      <input
                        name="role_type"
                        checked={registerInfo.role_type === "8"}
                        value="8"
                        type="radio"
                        id="NGO"
                        onChange={({ target }) =>
                          setRegisterInfo({
                            ...registerInfo,
                            role_type: target.value,
                          })
                        }
                      />
                      <label for="NGO">NGO</label>
                    </p>
                    <p>
                      <input
                        type="radio"
                        id="Company"
                        name="role_type"
                        checked={registerInfo.role_type === "9"}
                        value="9"
                        onChange={(e) =>
                          setRegisterInfo({
                            ...registerInfo,
                            role_type: e.target.value,
                          })
                        }
                      />
                      <label for="Company">Company</label>
                    </p>
                  </div>
                </div>
				
				

                <div className="d-grid mb-3">
                  <button type="submit" className="btn bg-natural btn-block">
                    Register
                  </button>
                </div>
              </form>

              {errorMessage && (
              <div className="alert alert-danger mt-3"   role="alert">
                {errorMessage}
              </div>
              )}

              <p className="text-center mt-2 mb-1">
                If you're already registered, <Link to="/login">Login</Link>
              </p>

              <div className="text-center">Or continue with</div>

              <div className="align-items-center gap-2 mt-3 social_bntt both_partss" id="width_auto_al_screens">
                <button
                  className="btn btn-danger social_icnn mb-3"
                  onClick={handleGoogleLogin}
                >
                  <img
                    src="/images/google_ic_n.png"
                    className="set_iconss"
                    alt=""
                  />{" "}
                  Use My Google Account
                </button>
                <button
                  className="btn btn-facebook social_icnn"
                  onClick={handleFacebookLogin}
                >
                  <img
                    src="/images/face_bk_ic_n.png"
                    className="set_iconss"
                    alt=""
                  />{" "}
                  Use My Facebook Account
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;

import React, { useEffect, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import axiosClient from "../../Services/Axios/axios";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const NotificationTabModal = ({
  modalRef,
  allNotificationData,
  unreadNotificationData,
  getAllNotifications,
}) => {
  const [activeTab, setActiveTab] = React.useState(0);
  const [loadingNotificationId, setLoadingNotificationId] = useState(null);
  const navigate = useNavigate();
  const timeAgo = (date) => {
    const now = new Date();
    const seconds = Math.floor((now - date) / 1000);

    if (seconds < 60) return `${seconds} sec. ago`;
    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) return `${minutes} min. ago`;
    const hours = Math.floor(minutes / 60);
    if (hours < 24) return `${hours} hrs. ago`;

    const days = Math.floor(hours / 24);
    if (days === 1) return `yesterday`;
    if (days <= 7) return `${days} days ago`;

    const weeks = Math.floor(days / 7);
    if (weeks === 1) return `last week`;
    if (weeks < 4) return `${weeks} weeks ago`;

    const months = Math.floor(days / 30); // Approximation
    if (months === 1) return `last month`;
    return `${months} months ago`;
  };
  const readNotification = async (id) => {
    setLoadingNotificationId(id); // Set the loading ID to disable the click
    try {
      const response = await axiosClient.post(`/web/readNotification`, {
        notification_id: `${id}`,
        created_by: "1",
      });
      if (!response.data.error) {
        getAllNotifications();
      }
    } catch (error) {
      console.error("Error reading notification:", error);
    } finally {
      setLoadingNotificationId(null); // Reset the loading ID
    }
  };
  return (
    <>
      <div
        className="modal-container"
        onClick={(e) => e.stopPropagation()} // Prevents event bubbling
        ref={modalRef}
      >
        <div
          className="cros_itemss_x"
          onClick={() => (modalRef.current.style.display = "none")}
        >
          <img src="/images/cross_icon_sm.png" alt="" />
        </div>

        <Tabs>
          <div className="noti_titlss">
            <h1>Notification</h1>
            <button
              className="vew_als"
              onClick={() => {
                navigate("/notification");
                modalRef.current.style.display = "none";
              }}
            >
              View All
            </button>
          </div>

          <div className="tabs_list prof_eventss">
            <div className="mb_view_show mb-3">
              <div className="fill_ters">
                <div className="inp_seach">
                  <img src="/images/search_g.png" alt="" />
                  <input
                    type="text"
                    className="inp_textx"
                    name=""
                    placeholder="Search"
                  />
                </div>
              </div>
            </div>

            <div className="tbdsa">
              <TabList>
                <Tab onClick={() => setActiveTab(0)}>All</Tab>
                <Tab onClick={() => setActiveTab(1)}>Unread</Tab>
              </TabList>
            </div>
            <div className="content-wrapper">
              <TabPanel>
                {/* <>
                  <div className="nitif_tion_lists">
                    <h6>New</h6>
                    <div className="usr_list_main">
                      <div className="bells">
                        <img src="/images/bell_grn.png" alt="" />
                      </div>
                      <div className="usr_txt">
                        <h4>Donation Confirmed</h4>
                        <p>
                          Your donation has been received . For details
                          please.......
                        </p>
                      </div>
                      <div className="d_times">12 min. ago</div>
                    </div>

                    <div className="usr_list_main">
                      <div className="bells">
                        <img src="/images/bell_grn.png" alt="" />
                      </div>
                      <div className="usr_txt">
                        <h4>Donation Confirmed</h4>
                        <p>
                          Your donation has been received . For details
                          please.......
                        </p>
                      </div>
                      <div className="d_times">12 min. ago</div>
                    </div>
                  </div>

                  <div className="nitif_tion_lists">
                    <h6>Yesterday</h6>
                    <div className="usr_list_main">
                      <div className="bells">
                        <img src="/images/cl_grn_ic.png" alt="" />
                      </div>
                      <div className="usr_txt">
                        <h4>Event Registration Successful</h4>
                        <p>
                          your event registration in successful , check your
                          mail.......
                        </p>
                      </div>
                      <div className="d_times">12 min. ago</div>
                    </div>

                    <div className="usr_list_main">
                      <div className="bells">
                        <img src="/images/cl_grn_ic.png" alt="" />
                      </div>
                      <div className="usr_txt">
                        <h4>Event Registration Successful</h4>
                        <p>
                          your event registration in successful , check your
                          mail.......
                        </p>
                      </div>
                      <div className="d_times">12 min. ago</div>
                    </div>

                    <div className="usr_list_main">
                      <div className="bells">
                        <img src="/images/tik_circle_ok.png" alt="" />
                      </div>
                      <div className="usr_txt">
                        <h4>Event Registration Successful</h4>
                        <p>
                          your event registration in successful , check your
                          mail.......
                        </p>
                      </div>
                      <div className="d_times">12 min. ago</div>
                    </div>
                  </div>

                  <div className="nitif_tion_lists">
                    <h6>Jan 21,24</h6>
                    <div className="usr_list_main">
                      <div className="bells">
                        <img src="/images/tik_circle_ok.png" alt="" />
                      </div>
                      <div className="usr_txt">
                        <h4>Donation Confirmed</h4>
                        <p>
                          Your donation has been received . For details
                          please.......
                        </p>
                      </div>
                      <div className="d_times">12 min. ago</div>
                    </div>

                    <div className="usr_list_main">
                      <div className="bells">
                        <img src="/images/tik_circle_ok.png" alt="" />
                      </div>
                      <div className="usr_txt">
                        <h4>Donation Confirmed</h4>
                        <p>
                          Your donation has been received . For details
                          please.......
                        </p>
                      </div>
                      <div className="d_times">12 min. ago</div>
                    </div>
                  </div>
                </> */}
                <>
                  {/* {loading ? (
                    <div className="member-loader">
                      <BeatLoader color="#097C81" />
                    </div>
                  ) : ( */}
                  <>
                    {/* <div className="nitif_tion_lists">
                        <h6>New</h6>
                        {allNotificationData
                          .filter((notif) => {
                            const today = new Date()
                              .toISOString()
                              .split("T")[0];
                            return (
                              notif.isread === 0 &&
                              notif.created_at.startsWith(today)
                            );
                          })
                          .map((notif) => (
                            <div className="usr_list_main" key={notif.id}>
                              <div className="bells">
                                <img
                                  src="/images/bell_grn.png"
                                  alt="New Notification"
                                />
                              </div>
                              <div className="usr_txt">
                                <h4>{notif.title}</h4>
                                <p>{notif.description.slice(0, 50)}...</p>
                              </div>
                              <div className="d_times">
                                {timeAgo(new Date(notif.created_at))}
                              </div>
                            </div>
                          ))}
                      </div>

                      <div className="nitif_tion_lists">
                        <h6>Yesterday</h6>
                        {allNotificationData
                          .filter((notif) => {
                            const yesterday = new Date();
                            yesterday.setDate(yesterday.getDate() - 1);
                            const yesterdayDate = yesterday
                              .toISOString()
                              .split("T")[0];
                            return (
                              notif.isread === 0 &&
                              notif.created_at.startsWith(yesterdayDate)
                            );
                          })
                          .map((notif) => (
                            <div className="usr_list_main" key={notif.id}>
                              <div className="bells">
                                <img
                                  src="/images/cl_grn_ic.png"
                                  alt="Yesterday Notification"
                                />
                              </div>
                              <div className="usr_txt">
                                <h4>{notif.title}</h4>
                                <p>{notif.description.slice(0, 50)}...</p>
                              </div>
                              <div className="d_times">
                                {timeAgo(new Date(notif.created_at))}
                              </div>
                            </div>
                          ))}
                      </div> */}

                    {allNotificationData.length > 0 ? (
                      allNotificationData?.map((notif, index) => {
                        return (
                          <div className="nitif_tion_lists" key={notif.id}>
                            <h6>
                              {" "}
                              {dayjs(notif.created_at).format("MMM DD, YYYY")}
                            </h6>

                            <div className="usr_list_main">
                              <div className="bells">
                                <img
                                  src="/images/bell_grn.png"
                                  alt="New Notification"
                                />
                              </div>
                              <div className="usr_txt">
                                <h4>{notif.title}</h4>
                                <p>{notif.description.slice(0, 50)}...</p>
                              </div>
                              <div className="d_times">
                                {timeAgo(new Date(notif.created_at))}
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="nitif_tion_lists">
                        <h6>No Notification Found</h6>
                      </div>
                    )}
                  </>
                </>
              </TabPanel>
              <TabPanel>
                <>
                  {unreadNotificationData?.rows.length > 0 ? (
                    unreadNotificationData?.rows.map((notif) => {
                      const isLoading = loadingNotificationId === notif.id;
                      return (
                        <div
                          className={`nitif_tion_lists ${
                            isLoading ? "disabled" : ""
                          }`}
                          key={notif.id}
                          onClick={() =>
                            !isLoading && readNotification(notif.id)
                          }
                          style={{ pointerEvents: isLoading ? "none" : "auto" }} // Disable click
                        >
                          <h6>
                            {dayjs(notif.created_at).format("MMM DD, YYYY")}
                          </h6>

                          <div className="usr_list_main">
                            <div className="bells">
                              <img
                                src="/images/bell_grn.png"
                                alt="New Notification"
                              />
                            </div>
                            <div className="usr_txt">
                              <h4>{notif.title}</h4>
                              <p>{notif.description.slice(0, 50)}...</p>
                            </div>
                            <div className="d_times">
                              {timeAgo(new Date(notif.created_at))}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="nitif_tion_lists">
                      <h6>No Notification Found</h6>
                    </div>
                  )}
                </>
              </TabPanel>
            </div>
          </div>
        </Tabs>
      </div>
    </>
  );
};

export default NotificationTabModal;

import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import Swal from "sweetalert2";
import axiosClient from "../Services/Axios/axios";
import ProfileSidebar from "./common/ProfileSidebar";

const EditReview = () => {
  const { id } = useParams(); // Get the review ID from the URL parameters
  const [userData, setUserData] = useState();
  const navigate = useNavigate();
  const [buttonLoader, setButtonLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const fileInputRef = useRef();

  const [reviewData, setReviewData] = useState({
    event_id: "",
    user_id: "",
    review: "",
    image: "", // Existing image URL
    newImage: null, // New image file
  });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userData"));
    setUserData(user);
    setReviewData((prevData) => ({
      ...prevData,
      event_id: id,
      user_id: user.id,
    }));

    // Fetch existing review data
    const fetchReviewData = async () => {
      try {
        const response = await axiosClient.get(`/events/getReview/${id}`);
        if (!response.data.error) {
          const reviewData = response.data.data; // Adjust based on your API response structure
          setReviewData({
            review: reviewData.review,
            image: reviewData.image, // Existing image URL
            event_id: reviewData.event_id,
            user_id: reviewData.user_id,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Failed to fetch review data.",
            text: response.data.message,
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error fetching review data.",
          text: error.response?.data?.message || "Something went wrong.",
        });
      }
    };

    fetchReviewData();
  }, [id]);

  // const handleImageChange = async (e) => {
  //   try {
  //     const file = e.target.files[0];
  //     if (!file) {
  //       setErrors({ ...errors, image: "Image is required" });
  //       return;
  //     }
  //     setErrors({ ...errors, image: "" }); // Clear error if file is valid

  //     const formData = new FormData();
  //     formData.append("image", file);
  //     const response = await axiosClient.post("/users/uploadimg", formData);
  //     if (!response.data.error) {
  //       setReviewData({ ...reviewData, newImage: response.data.file }); // Set the new image URL
  //     }
  //   } catch (error) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Image upload failed. Please try again.",
  //     });
  //   }
  // };

  const handleImageChange = async (e) => {
    try {
      const file = e.target.files[0];
      if (!file) {
        setErrors({ ...errors, image: "Image is required" });
        return;
      }

      // Validate file type
      const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (!validImageTypes.includes(file.type)) {
        setErrors({
          ...errors,
          image: "Only image files are allowed (JPEG, PNG, GIF)",
        });
        return;
      }

      // Validate file size (2MB limit)
      const maxSizeInBytes = 2 * 1024 * 1024; // 2MB
      if (file.size > maxSizeInBytes) {
        setErrors({ ...errors, image: "Image size must be less than 2MB" });
        return;
      }

      setErrors({ ...errors, image: "" }); // Clear error if file is valid

      const formData = new FormData();
      formData.append("image", file);
      const response = await axiosClient.post("/users/uploadimg", formData);
      if (!response.data.error) {
        setReviewData({ ...reviewData, newImage: response.data.file }); // Set the new image URL
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Image upload failed. Please try again.",
      });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation
    let validationErrors = {};
    if (!reviewData.review.trim()) {
      validationErrors.review = "Review is required";
    }
    if (!reviewData.newImage && !reviewData.image) {
      validationErrors.image = "Image is required";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setButtonLoader(true);
    try {
      const data = await axiosClient.put(`/events/edit-review`, {
        review: reviewData.review,
        review_id: id, // Assuming the review ID is the same as the URL parameter
        event_id: reviewData.event_id,
        user_id: reviewData.user_id,
        image: reviewData.newImage || reviewData.image, // Use new image if available, otherwise use existing
      });
      if (!data.data.error) {
        Swal.fire({
          icon: "success",
          title: "Review updated successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/my-events"); // Navigate to My Events page
        if (fileInputRef.current) {
          fileInputRef.current.value = ""; // Clear file input
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.response?.data?.message || "Something went wrong",
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setButtonLoader(false);
    }
  };

  return (
    <div className="all_suport_bg manages _top">
      
      <section className="ser_v_araeeaR evnetsts profiles">
        <div className="container">
        <div className="bradcams mb_view_hide">
            <span className="bothss">
              <Link to="/impact">My Account</Link>
              <img src="/images/brd_rowwo.png" alt="" />{" "}
              <Link to="/my-events">Event</Link>
              <img src="/images/brd_rowwo.png" alt="" /> Edit Review
            </span>
          </div>
          <div className="row">
            <div className="col-lg-3 mb_view_hide">
              <ProfileSidebar />
            </div>
            <div className="col-lg-9">
              <div className="form_boxx profff_eventss profilss">
                <h4 className="br_space">Edit Review</h4>
                <div className="form_arar mt-0">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>Enter Review</label>
                          <textarea
                            className="form-control"
                            name="review"
                            onChange={(e) => {
                              setErrors({ ...errors, review: "" });
                              setReviewData({
                                ...reviewData,
                                review: e.target.value,
                              });
                            }}
                            value={reviewData.review}
                            rows="7"
                            cols="7"
                          ></textarea>
                          {errors.review && (
                            <span className="text-danger">{errors.review}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          {reviewData.image && (
                            <div className="image-preview1">
                              <img
                                src={reviewData.image}
                                alt="Current Review"
                                className="img-thumbnail"
                              />
                            </div>
                          )}
                          <div className="mt-4">
                            <input
                              type="file"
                              className="form-control bor1"
                              onChange={handleImageChange}
                              ref={fileInputRef}
                            />
                            {errors.image && (
                              <span className="text-danger">
                                {errors.image}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 mt-4">
                        <div className="form-group d-flex" >
                        <button type="submit" className="sub_mitessR" onClick={() => navigate("/my-events")}>
                          Back
                        </button>
                          <button
                            type="submit"
                            className="sub_mitess"
                            disabled={buttonLoader}
                          >
                            {buttonLoader ? (
                              <ClipLoader color="#fff" size={25} />
                            ) : (
                              "Save"
                            )}
                          </button>
                          
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EditReview;

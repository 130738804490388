export const homeslider = {
  margin: 0,
  responsiveClass: true,
  nav: false,
  dots: true,
  loop: true,
  autoplay: false,
  navText: ["<", ">"],
  smartSpeed: 200,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 1,
    },
    700: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};

export const options = {
  margin: 30,
  responsiveClass: true,
  nav: true,
  dots: false,
  loop: true,
  autoplay: false,
  navText: ["<", ">"],
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 1,
    },
    700: {
      items: 1,
    },
    1000: {
      items: 3,
    },
  },
};

export const CharityCampaign = {
  margin: 0,
  responsiveClass: true,
  nav: false,
  dots: true,
  loop: true,
  autoplay: true,
  navText: ["<", ">"],
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 1,
    },
    700: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};

export const EventsMobiles = {
  margin: 0,
  responsiveClass: true,
  nav: false,
  dots: true,
  loop: true,
  autoplay: true,
  navText: ["<", ">"],
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 1,
    },
    700: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};

export const ParentMobiles = {
  margin: 15,
  responsiveClass: true,
  nav: false,
  dots: true,
  loop: true,
  autoplay: true,
  navText: ["<", ">"],
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 2,
    },
    400: {
      items: 2,
    },
    600: {
      items: 2,
    },
    700: {
      items: 2,
    },
    1000: {
      items: 2,
    },
  },
};

export const NewsFeaturedStories = {
  margin: 0,
  responsiveClass: true,
  nav: false,
  dots: true,
  loop: true,
  autoplay: true,
  navText: ["<", ">"],
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 1,
    },
    700: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};

import { Link, useParams } from "react-router-dom";
import { React, useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import ProgressBar from "@ramonak/react-progress-bar";
import axiosClient from "../../Services/Axios/axios";
import HtmlWithLimit from "../../Components/Common/HtmlWithLimit";
import { BeatLoader } from "react-spinners";

const MedicalCause = {
  margin: 0,
  responsiveClass: true,
  nav: false,
  dots: true,
  loop: true,
  autoplay: true,
  navText: ["<", ">"],
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 1,
    },
    700: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};

const EmergencyDonation = {
  margin: 0,
  responsiveClass: true,
  nav: false,
  dots: true,
  loop: true,
  autoplay: true,
  navText: ["<", ">"],
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 1,
    },
    700: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};

const DonationCategory = () => {
  const { page_url } = useParams();
  const [bannerData, setBannerData] = useState({});
  const [categoryViseData, setCategoryViseData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAllData = async () => {
    try {
      setLoading(true);
      const categoryData = await axiosClient.get(
        `/campaigns/getCampaignsByCategoryName?language=en&pageSize=10&pageNo=1&category_page_url=${page_url}`
      );
      if (!categoryData.data.error) {
        setCategoryViseData(categoryData.data.data.rows);
      }
      const banner = await axiosClient.get(
        `/campaigns/getCategoryDetailsByName?page_url=${page_url}&language=en`
      );
      if (!banner.data.error) {
        setBannerData(banner.data.data);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  if (loading) {
    return (
      <div className="loader">
        <BeatLoader color="#097C81" />
      </div>
    );
  }
  return (
    <>
      <section
        className="ser_v_araeea green_bg datates_pgs d-flex align-items-center min-h-400 manages_top"
        id="about_pgs"
      >
        <img src="/images/flo_partss.png" className="flo_partss" alt="" />
        <div className="container h-100 my-auto">
          <div className="row align-items-center h-100">
            <div className="col-lg-6">
              <div className="al_sprts_pages">
                {bannerData?.category_name && (
                  <h3>{bannerData.category_name}</h3>
                )}
              </div>
            </div>
            <div className="col-lg-6 mb_view_hide">
              <div className="cnt_datas">
                    {bannerData?.campaign_description}
              </div>

              {/* {bannerData.sub_title && (
                <div className="cnt_datas mb_view_hide">
                  {bannerData.sub_title}
                </div>
              )} */}
            </div>
          </div>
        </div>

        {/* <div className="box_ara_partss mb_view_hide">
          <div className="container">
            <div className="row">
              {categoryData.map((res) => (
                <div className="col-lg-2">
                  <div className="usr_lgo_parts">
                    <img
                      className="categoryImage"
                      src={res.category_image}
                      alt=""
                    />
                    <p>{res.category_name}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div> */}
      </section>


      {/*
      <section className="ser_v_araeea pb-0">
        <div className="container">
          <div className="filtter_area_byss">
            <div className="row">
              <div className="col-lg-6">
                <div className="tittls_name">
                  <h4>Categories</h4>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="slt_are_dropss">
                  <div className="slt_opt_partss">
                    <h6>Sort By</h6>
                    <select>
                      <option value="">Newest</option>
                    </select>
                  </div>

                  <div className="slt_opt_partss">
                    <h6>Categories</h6>
                    <select>
                      <option value="">Select Categories</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
	  */}

      <section className="ser_v_araeea" id="charity_campaign">
        <div className="container">
          <div className="row">
            {categoryViseData.map((val) => (
              <div className="col-lg-4 mb_view_hide desk_top_hide">
                <Link to={`/donate-details/${val.page_url}`}>
                  <div className="cm_box_listst">
                    <div className="cm_mg_box">
                      <img src={val.donation_images} className="h-100" alt="" />
                    </div>
                    <div className="cnt_al_boxx">
                      <span className="sm_txt">
                         {val.user.name}
                        <img src="/images/gr_tik_sm.png" alt="" />
                      </span>
                      <h3>{val.donation_title}</h3>
                      <p>
                        <HtmlWithLimit
                          htmlString={val.donation_short_description}
                          limit={500}
                        />
                      </p>
                      <div className="skills">
                        <ProgressBar
                          isLabelVisible={false}
                          completed={(
                            (val.till_donation_amount / val.global_amount) *
                            100
                          ).toFixed(2)}
                          bgColor={"#097C81"}
                          height={"12px"}
                          labelSize={"12px"}
                          transitionDuration={"0.3s"}
                        />
                      </div>
                      <h6>
                        Raised {val.till_donation_amount} USD{" "}
                        <span className="sm_t">of {val.global_amount} USD</span>
                      </h6>
                    </div>
                  </div>
                </Link>
              </div>
            ))}

            {/* ---------- Mobile View ---------- */}
            <div className="col-lg-12 CharityCampaign mb_view_show dsk_tp_spacess">
              <OwlCarousel
                className="slider-items owl-carousel dots_allss"
                id="three_points"
                {...MedicalCause}
              >
                {categoryViseData.map((val) => (
                  <div className="item">
                    <Link to={`/donate-details/${val.page_url}`}>
                      <div className="cm_box_listst">
                        <div className="cm_mg_box">
                          <img src={val.donation_images} alt="" />
                        </div>
                        <div className="cnt_al_boxx">
                          <span className="sm_txt">
                            {val.user.name}{" "}
                            <img src="/images/gr_tik_sm.png" alt="" />
                          </span>
                          <h3>{val.donation_title}</h3>
                          <p>{val.donation_short_description}</p>
                          <div className="skills">
                            <ProgressBar
                              isLabelVisible={false}
                              completed={(
                                (val.till_donation_amount / val.global_amount) *
                                100
                              ).toFixed(2)}
                              bgColor={"#097C81"}
                              height={"12px"}
                              labelSize={"12px"}
                              transitionDuration={"0.3s"}
                            />
                          </div>
                          <h6>
                            Raised {val.till_donation_amount} USD{" "}
                            <span className="sm_t">
                              of {val.global_amount} USD
                            </span>
                          </h6>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </OwlCarousel>
            </div>
            {/* ---------- End Mobile View ---------- */}
          </div>
        </div>
      </section>

      {/* <section className="ser_v_araeea pt-0">
        <div className="container">
          <div className="vei_als">
            <Link to="/donate">
              <span className="managess_bnt">
                View All Categories{" "}
                <img src="/images/chevron-right.png" alt="" />
              </span>
            </Link>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default DonationCategory;

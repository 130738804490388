import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axiosClient from "../../Services/Axios/axios";
import Swal from "sweetalert2";
import { Html5QrcodeScanner } from "html5-qrcode";
import moment from "moment-timezone";

const ScannerModal = ({ open, onClose }) => {
  const [tempData, setTempData] = useState("");
  const [websiteData, setWebsiteData] = useState({});
  const [camScan, setCamScan] = useState(true);
  const [dataModalOpen, setDataModalOpen] = useState(false);
  const [userDetail, setUserDetail] = useState({});
  const scannerRef = useRef(null);
  const [startDateTime, setStartDateTime] = useState(null);
  const [endDateTime, setEndDateTime] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (camScan && open) {
      setTimeout(() => {
        if (!scannerRef.current) return; // Ensure scanner div exists

        const scanner = new Html5QrcodeScanner(
          scannerRef.current.id, // 👈 Use ref instead of hardcoded id
          { fps: 10, qrbox: { width: 400, height: 300 } },
          false
        );

        scanner.render(
          (decodedText) => {
            // setTempData(decodedText);
            getWebsiteData(+decodedText);
            scanner.clear(); // Stop scanner
          },
          (errorMessage) => {
            // console.log("Scan error:", errorMessage);
          }
        );

        return () => scanner.clear();
      }, 500); // 👈 Small delay ensures div is rendered
    }
  }, [camScan, open]);

  // useEffect(() => {
  //   if (tempData) {
  //     getWebsiteData();
  //   }
  // }, [tempData]);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("userData"));
    console.log(data, "user data");
    setUserDetail(data);
  }, []);

  const handleClose = () => {
    setTempData("");
    setCamScan(true);
    onClose();
  };

  const getWebsiteData = async (id) => {
    try {
      const response = await axiosClient.get(`/events/getEventsInfoByID/${id}`);
      console.log(response.data.data, "response.data.data");
      if (response.data && !response.data.error) {
        setWebsiteData(response.data.data);

        const userTimeZone = moment.tz.guess();
        // Convert UTC time to user's local time in "10:00 AM" format
        const eventStartTime = moment
          .tz(
            `${response.data.data.event_date} ${response.data.data.event_time}`,
            "YYYY-MM-DD hh:mm A",
            "UTC"
          )
          .tz(userTimeZone)
          .format("hh:mm A");

        const eventEndTime = moment
          .tz(
            `${response.data.data.event_date} ${response.data.data.event_time_end}`,
            "YYYY-MM-DD hh:mm A",
            "UTC"
          )
          .tz(userTimeZone)
          .format("hh:mm A");

        setStartDateTime(eventStartTime);
        setEndDateTime(eventEndTime);
      }
     
        const newUserData = JSON.parse(localStorage.getItem("userData")); 
        const dataNew = newUserData?.EventBooking?.find(
            (item) => item?.event_id === id
          );
 
      if (dataNew) {
        if (dataNew?.booking_status === 0) {
          setCamScan(false); // Hide scanner after scan
          setDataModalOpen(true);
          setWebsiteData(response.data.data);
        }
        if (dataNew?.booking_status === 1) {
          setCamScan(false); // Hide scanner after scan
          onClose();
          Swal.fire({
            title: "Already Checked In",
            text: "You are already checked in, Do you want to Check Out?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
          }).then((result) => {
            if (result.isConfirmed) {
              handleCheckOut(dataNew?.event_id);
            }
          });
        }

        if (dataNew?.booking_status === 2) {
          setCamScan(true); // Hide scanner after scan
          onClose();
          Swal.fire({
            title: "Already Checked Out",
            icon: "warning",
            showCancelButton: false,
            confirmButtonText: "Yes",
          });
        }
      } else {
        onClose();
        setCamScan(false); // Hide scanner after scan
        Swal.fire({
          title: "Please register first",
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "Okay",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(`/events-details/${response.data.data?.page_url}`);
          } else {
            onClose();
          }
        });
      }
    } catch (error) {
      console.error("Error fetching website data:", error);
    }
  };
  const handleCheckIn = async () => {
    try {
      const response = await axiosClient.post(`/events/checkin`, {
        event_id: websiteData?.id,
        user_id: userDetail?.id,
      });
      if (response.data && !response.data.error) {
        handleProfile();
        Swal.fire({ icon: "success", title: "Event Checked In Successfully" });
        navigate(`/my-events`);
        onClose();
        window.location.reload(); 
      } else {
        Swal.fire({ icon: "error", title: response.data.message });
      }
    } catch (error) {
      Swal.fire({ icon: "error", title: "Something went wrong" });
      console.error("Check-in failed:", error);
    }
  };
  const handleCheckOut = async (id) => {
    try {
      const response = await axiosClient.post(`/events/checkout`, {
        event_id: id,
      });
      if (response.data && !response.data.error) {
        handleProfile();
        Swal.fire({
          icon: "success",
          title: "Event Checked Out Successfully , please review this event",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(`/my-events`);
            window.location.reload(); 
          }
          
        });
      } else {
        Swal.fire({ icon: "error", title: response.data.message });
      }
    } catch (error) {
      Swal.fire({ icon: "error", title: "Something went wrong" });
      console.error("Check-out failed:", error);
    }
  };
  const handleProfile = async () => {
    try {
      const response = await axiosClient.get("/users/getByID");
      const userData = response?.data?.data;

      if (response?.data?.error || !userData) return;

      localStorage.setItem("userData", JSON.stringify(userData));
      setUserDetail(userData);
    } catch (error) {
      console.error("Error fetching profile:", error);
    }
  };
  return (
    <Modal className="all_supportss sm_sizess" show={open} onHide={handleClose}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {camScan && (
          <div className="fromss_boxx">
            <div className="us_data_sc">
              <h5>Scan or Upload QR Code</h5>
            </div>
            <div className="cm_box_area scan_fildss sc_blak_filess">
              {/* {/ 👇 Attach ref instead of relying on "id=reader" /} */}
              <div
                ref={scannerRef}
                id="reader"
                style={{ width: "400px", height: "300px" }}
              ></div>
            </div>
          </div>
        )}
        {!camScan && dataModalOpen === true && (
          <div className="fromss_boxx" id="sc_wel_evntss">
            <div className="cm_box_area scan_fildss sc_blak_filess">
              <div className="hedings">Welcome</div>
              <div className="bothss">
                <div className="us_data_sc">
                  <h5>{websiteData?.title}</h5>
                  <div className="us_dat_timess">
                    <span className="datess">
                      <img src="/images/g_uil_calender.png" alt="" />
                      {dayjs(websiteData?.event_date).format("DD, MMM YYYY")}
                    </span>
                    <span className="datess">
                      <img src="/images/tabler_clock.png" alt="" />{" "}
                      {startDateTime} - {endDateTime}
                    </span>
                  </div>
                  <p>{websiteData?.short_title}</p>
                </div>
                <div className="us_mg_partss">
                  <img src={websiteData?.image} alt="" />
                </div>
              </div>
              <div className="manga_data">
                Thank you for participating in the event. You can check in from
                below, and your timing can be tracked through it.
              </div>
              <div className="sc_btn_wel">
                <Link onClick={handleCheckIn} to="">
                  Continue
                </Link>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ScannerModal;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ImageUploader from "react-image-upload";
import { Modal, Button } from "react-bootstrap";
import CommanPagination from "./common/CommanPagination/CommanPagination";
import ProfileSidebar from "./common/ProfileSidebar";
import axiosClient from "../Services/Axios/axios";
import { BeatLoader } from "react-spinners";

const Careers = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [referralData, setReferralData] = useState();

  const getAllReferralData = async () => {
    try {
      setLoading(true);
      const response1 = await axiosClient.get(
        `/web/getUserReferralViewAll?pageSize=${limit}&pageNo=${currentPage}`
      );
      if (!response1.data.error) {
        setLoading(false);
        setReferralData(response1.data);
      } else {
        setReferralData({});
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setReferralData({});
    }
  };
  useEffect(() => {
    getAllReferralData();
  }, [currentPage, limit]);
  return (
    <>
      <div className="all_suport_bg manages_top">
        <section className="ser_v_araeea profiles">
          <div className="container">
            <div className="bradcams mb_view_hide">
              <span className="bothss">
                <Link to="">My Account</Link>{" "}
                {/* <Link to="/referral"></Link> */}
                <img src="/images/brd_rowwo.png" alt="" /> Referral
                <img src="/images/brd_rowwo.png" alt="" /> Referral List
              </span>
            </div>
            <div className="row">
              <ProfileSidebar />
              <div className="col-lg-9">
                <div className="form_boxx Impact Gratitude  profilss">
                  <h4 class="br_space">
                    Referral({referralData?.data?.totalElement})
                  </h4>
                  {loading ? (
                    <div className="member-loader">
                      <BeatLoader color="#097C81" />
                    </div>
                  ) : (
                    <>
                      {" "}
                      <div className="row mt-2">
                        <div className="col-lg-12">
                          <div className="Imepctgrp tabless">
                            <div className="top_cnt_impct two_partss">
                              <div className="titals_im">Referral List</div>
                            </div> 
                            <div class="table-responsive mt-2">
                              <table class="table table-striped al_suport_items mb-0">
                                <thead>
                                  <tr>
                                    <th>Sr. No.</th>
                                    <th>Member ID</th>
                                    <th>Name</th>
                                    <th>Referral Code</th>
                                    <th>Email ID</th>
                                    {/* <th>Introducer</th> */}
                                    {/* <th className="last">Action</th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  {referralData &&
                                    referralData?.data?.rows?.map((item, i) => {
                                      console.log(item, "item");
                                      return (
                                        <tr>
                                          <td>
                                            {(currentPage - 1) * limit + i + 1}
                                          </td>
                                          <td>{item.member_id}</td>
                                          <td>{item.name}</td>
                                          <td>{item.referral_code}</td>
                                          <td>{item.email}</td>
                                          {/* <td>WTI88759IJ</td> */}
                                          {/* <td className="green_tx_w">
                                            <img
                                              src="/images/eye_ico.png"
                                              alt=""
                                            />
                                          </td> */}
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </div>
                         

                          <div className="pages_liststs" >
                          <CommanPagination
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            limit={limit}
                            setLimit={setLimit}
                            totalCounts={referralData?.data?.totalElement}
                            totalPage={referralData?.data?.totalPage}
                          />
                        </div>
                        </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Careers;

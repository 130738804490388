import { Link } from "react-router-dom";
import { React, useState, useEffect, useRef } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PhoneInput from "react-country-phone-input";
import { isValidPhoneNumber } from "libphonenumber-js";
import "react-phone-input-2/lib/style.css";
import { Modal, Button } from "react-bootstrap";
import { ClipLoader } from "react-spinners";
import "animate.css";
import ProfileSidebar from "./common/ProfileSidebar";
import axiosClient from "../Services/Axios/axios";
import EnrolledEvents from "./Events/EnrolledEvents";
import UpcomingEvents from "./Events/UpcomingEvents";
import Swal from "sweetalert2";
import moment from "moment-timezone";

const Login = () => {
  const [show, setShow] = useState(false);
  const [showscan, setShowscan] = useState(false);
  const [userData, setUserData] = useState({});
  const [buttonloader, setButtonloader] = useState(false);
  const handleClose = () => setShow(false);
  const videoshow = (id) => {
    setFormData({
      ...formData,
      event_id: id,
    });
    setShow(true);
  };
  const handleClose1 = () => setShowscan(false);
  const [searchText, setSearchText] = useState("");
  const [tab, setTab] = useState(1);
  const [formData, setFormData] = useState({
    name: userData?.first_name ? userData?.first_name : "",
    email: userData?.email ? userData?.email : "",
    mobile: "",
    country_code: "+91",
    user_type: "1",
    communication_type: "1",
    event_id: "",
    user_id: userData ? userData?.id : "",
  });

  const [errors, setErrors] = useState({
    fname: "",
    email: "",
    phone: "",
  });
  const cameracl = () => setShowscan(true);
  const handlePhoneChange = (value, country) => {
    setFormData({
      ...formData,
      fullPhone: value,
      mobile: value.slice(country.dialCode.length), // Extract mobile number without country code
      country_code: `+${country.dialCode}`,
      country: country.iso2, // Update the country code
    });
  };
  const validate = () => {
    const errors = {};
    // Name validation
    if (!formData.name.trim()) {
      errors.fname = "Name is required.";
      setButtonloader(false);
    } else if (formData.name.length > 50) {
      errors.fname = "Name must be at least 50 characters.";
      setButtonloader(false);
    }
    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim()) {
      errors.email = "Email ID is required.";
      setButtonloader(false);
    } else if (!emailRegex.test(formData.email)) {
      errors.email = "Invalid email format.";
      setButtonloader(false);
    } else if (formData.email.length > 50) {
      errors.email = "Email must be at least 50 characters.";
      setButtonloader(false);
    }

    // Phone number validation
    if (!formData.mobile.trim() || !formData.country_code) {
      errors.phone = "Phone number and country code are required.";
      setButtonloader(false);
    }
    //  else if (
    //   !isValidPhoneNumber(
    //     `${formData.country_code}${formData.mobile}`,
    //     formData.country_code.replace("+", "")
    //   )
    // ) {
    //   errors.phone = "Invalid phone number for the selected country.";
    //   setButtonloader(false);
    // }

    setErrors(errors);

    return Object.keys(errors).length === 0; // Returns true if no errors
  };

  const handleProfile = async () => {
    try {
      const response = await axiosClient.get("/users/getByID");
      const userData = response?.data?.data;

      if (response?.data?.error || !userData) return;

      localStorage.setItem("userData", JSON.stringify(userData));
    } catch (error) {
      console.error("Error fetching profile:", error);
      localStorage.clear();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonloader(true);
    if (validate()) {
      try {
        const userTimeZone = moment.tz.guess();
        const data = await axiosClient.post(`/events/booking`, {
          ...formData,
          user_type: Number(formData.user_type),
          communication_type: Number(formData.communication_type),
          timezone: userTimeZone,
        });
        if (!data.data.error) {
          Swal.fire({
            icon: "success",
            title: "Thanks for your booking",
            showConfirmButton: false,
            timer: 1500,
          });
          handleClose();
          setFormData({
            ...formData,
            name: "",
            email: "",
            mobile: "",
            fullPhone: "",
            country_code: "+91",
            user_type: "1",
            communication_type: "1",
          });

          handleProfile();
        }
        setButtonloader(false);
      } catch (error) {
        console.log(error);
        setButtonloader(false);
        Swal.fire({
          icon: "error",
          title: error.response.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? (checked ? value : "") : value, // Update logic for checkboxes
    }));
  };
  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (userData !== null) {
      setUserData(JSON.parse(userData));
    }
  }, []);
  useEffect(() => {
    setFormData({
      ...formData,
      user_id: userData ? userData?.id : "",
      name: userData?.first_name
        ? `${userData?.first_name} ${
            userData?.last_name ? userData?.last_name : ""
          } `
        : "",
      email: userData?.email ? userData?.email : "",
      mobile: userData?.mobile ? userData?.mobile : "",
      fullPhone: userData?.mobile ? userData?.mobile : "",
    });
  }, [userData]);
  return (
    <>
      <Modal
        className="all_supportss sm_sizess"
        id="registeress"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="fromss_boxx">
            <div className="titalss">
              <h3>Register Now</h3>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="unfildss" id="rediiobnt">
                <p>Register now for this event as :</p>
                <div className="rdo_boxx">
                  <p>
                    <input
                      type="radio"
                      id="test1"
                      name="user_type"
                      value="1" // Set specific value
                      onChange={(e) => handleChange(e)}
                      checked={formData.user_type === "1"} // Bind checked state
                    />
                    <label htmlFor="test1">Participant</label>
                  </p>
                  <p>
                    <input
                      type="radio"
                      id="test2"
                      name="user_type"
                      value="2" // Set specific value
                      onChange={(e) => handleChange(e)}
                      checked={formData.user_type === "2"} // Bind checked state
                    />
                    <label htmlFor="test2">Volunteer</label>
                  </p>
                </div>
              </div>
              <div class="form-group">
                <label>
                  Name<span className="red">*</span>
                </label>
                <input
                  type="text"
                  class={`form-control ${errors.fname ? "input-error" : ""}`}
                  name="name"
                  placeholder="Enter Name"
                  value={formData.name}
                  onChange={(e) => {
                    setErrors({ ...errors, fname: "" });
                    handleChange(e);
                  }}
                />
                {errors.fname && <div className="error">{errors.fname}</div>}
              </div>

              <div class="form-group">
                <label>
                  Email ID<span className="red">*</span>
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.email ? "input-error" : ""
                  }`}
                  name="email"
                  placeholder="Enter Email ID"
                  value={formData.email}
                  onChange={(e) => {
                    setErrors({ ...errors, email: "" });
                    handleChange(e);
                  }}
                  readOnly={userData?.email ? true : false}
                />
                {errors.email && <div className="error">{errors.email}</div>}
              </div>

              <div class="form-group">
                <label>
                  Phone Number<span className="red">*</span>
                </label>
                <PhoneInput
                  className={` react-tel-input ${
                    errors.phone ? "input-error" : ""
                  }`}
                  country={formData.country || "in"}
                  value={formData.fullPhone || ""}
                  onChange={(value, country) =>
                    handlePhoneChange(value, country)
                  }
                  placeholder="Enter Phone Number"
                  inputProps={{
                    name: "phoneNumber",
                    required: true,
                    autoFocus: true,
                  }}
                />
                {errors.phone && <div className="error">{errors.phone}</div>}
              </div>
              <div class="form-group">
                <div className="ck_titalss">
                  <h3>Choose your best way for communication</h3>
                </div>
                <div className="row">
                  {["1", "2", "3"].map((value, index) => (
                    <div className="col-lg-4 col-4" key={index}>
                      <div className="check_txtx" id="checkox">
                        <input
                          id={`check${value}`}
                          name="communication_type"
                          type="checkbox"
                          value={value}
                          checked={formData.communication_type === value} // Single selection logic
                          onChange={(e) =>
                            setFormData((prevData) => ({
                              ...prevData,
                              communication_type: e.target.checked ? value : "", // Update state
                            }))
                          }
                        />
                        <label htmlFor={`check${value}`}>
                          {value === "1"
                            ? "Whatsapp"
                            : value === "2"
                            ? "Phone Call"
                            : "Email"}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div class="form-group mt-4">
                <button
                  type="submit"
                  value="Register"
                  class="sub_mitess"
                  disabled={buttonloader}
                >
                  {buttonloader ? (
                    <ClipLoader color="#fff" size={"25"} />
                  ) : (
                    "Register"
                  )}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      <div className="all_suport_bg manages_top">
        <section className="ser_v_araeea evnetsts profiles">
          <div className="container">
            <div className="bradcams mb_view_hide">
              <span className="bothss">
                <Link to="">My Account</Link>{" "}
                <img src="/images/brd_rowwo.png" alt="" /> Events
              </span>
            </div>
            <div className="row">
              <div className="col-lg-3 mb_view_hide">
                <ProfileSidebar />
              </div>
              <div className="col-lg-9">
                <div className="entnts_tabss profff_eventss">
                  <h4>Events</h4>
                  <Tabs>
                    <div className="tabs_list prof_eventss">
                      <div className="mb_view_show mb-3" id="desk_top_hide">
                        <div className="fill_ters">
                          <div className="inp_seach">
                            <img src="/images/search_g.png" alt="" />
                            <input
                              type="text"
                              class="inp_textx"
                              name=""
                              placeholder="Search"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="tbdsa">
                        <TabList>
                          <Tab
                            onClick={() => {
                              setTab(1);
                              setSearchText("");
                            }}
                          >
                            Enrolled Events
                          </Tab>
                          <Tab
                            onClick={() => {
                              setTab(2);
                              setSearchText("");
                            }}
                          >
                            Upcoming Events
                          </Tab>
                        </TabList>
                      </div>
                      <div className="fill_ters mb_view_hide">
                        <div className="inp_seach">
                          <img src="/images/search_g.png" alt="" />
                          <input
                            type="text"
                            class="inp_textx"
                            name="searchText"
                            onChange={(e) => setSearchText(e.target.value)}
                            value={searchText}
                            placeholder="Search"
                          />
                        </div>
                        {/* <div className="filtters_sttt"> */}
                        {/* <div>
                          <span className="ad_linksss">
                            <Link to="/add-my-events">Create New Event</Link>
                          </span>
                        </div> */}
                        {/* </div> */}
                      </div>
                    </div>

                    <div className="content-wrapper">
                      <TabPanel>
                        <EnrolledEvents tab={tab} searchText={searchText} />
                      </TabPanel>
                      <TabPanel>
                        <UpcomingEvents
                          videoshow={videoshow}
                          tab={tab}
                          searchText={searchText}
                        />
                      </TabPanel>
                    </div>
                  </Tabs>
                  {/* <BarcodeScannerComponent
                    width={500}
                    height={500}
                    onUpdate={(err, result) => {
                      if (result) setImageData(result.text);
                      else setImageData("Not Found");
                    }}
                  />
                  <p>{imageData}</p> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Login;

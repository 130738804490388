import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import Swal from "sweetalert2";
import axiosClient from "../Services/Axios/axios";
import CommanImagePDf from "./common/CommanImagePDf";
import ProfileSidebar from "./common/ProfileSidebar";

const Careers = () => {
  const [userNewData, setUserNewData] = useState({});
  const [nidDocImage, setNidDocImage] = useState({
    nidDocImage: "",
  });
  const [companyRegistration, setCompanyRegistration] = useState({
    companyRegistration: "",
  });
  const [NGOLicense, setNGOLicense] = useState({
    ngo_license: "",
  });
  const [errors, setErrors] = useState({}); 

  const [loadingNgoCert, setLoadingNgoCert] = useState(false);
  const [successNgoCert, setSuccessNgoCert] = useState("");

  const [loadingTaxId, setLoadingTaxId] = useState(false);
  const [successTaxId, setSuccessTaxId] = useState("");

  const clearError = (fieldName) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "",
    }));
  };
  const [loading, setLoading] = useState(false);
  const handleUpdate = async (e) => {
    e.preventDefault();

    // Validation: Check if required fields have values
    if (
      (userNewData?.role_type === 5 || userNewData?.role_type === 7) &&
      !nidDocImage.nidDocImage
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please upload your NID image.",
      });
      return; // Stop the function if validation fails
    }

    if (
      (userNewData?.role_type === 8 || userNewData?.role_type === 9) &&
      (!companyRegistration.companyRegistration || !NGOLicense.ngo_license)
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please upload Company Registration and NGO License images.",
      });
      return; // Stop the function if validation fails
    }

    try {
      setLoading(true);
      let kycUpdate;
      if (userNewData?.role_type === 8 || userNewData?.role_type === 9) {
        kycUpdate = {
          company_registration: companyRegistration.companyRegistration,
          ngo_license: NGOLicense.ngo_license,
        };
      } else {
        kycUpdate = {
          nid_doc_image: nidDocImage.nidDocImage,
        };
      }

      const updatedData = await axiosClient.put(
        `users/update-user-${
          userNewData?.role_type === 5 || userNewData?.role_type === 7
            ? "kyc"
            : "kyb"
        }`,
        kycUpdate
      );

      if (!updatedData.data.error) {
        const response = await axiosClient.get("/users/getByID");
        localStorage.setItem("userData", JSON.stringify(response.data.data));
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: updatedData.data.message,
        });
        handleProfile();
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleProfile = async () => {
    const response = await axiosClient.get("/users/getByID");
    if (!response.data.error) {
      localStorage.setItem("userData", JSON.stringify(response.data.data));
    }
  };

  useEffect(() => {
    setUserNewData(JSON.parse(localStorage.getItem("userData")));
  }, []);

  useEffect(() => {
    setNidDocImage({ nidDocImage: userNewData?.nid_doc_image });
    setCompanyRegistration({
      companyRegistration: userNewData?.company_registration,
    });
    setNGOLicense({ ngo_license: userNewData?.ngo_license });
  }, [userNewData]);
  return (
    <>
      <div className="all_suport_bg manages_top">
        <section className="ser_v_araeea profiles">
          <div className="container">
            <div className="bradcams mb_view_hide">
              <span className="bothss">
                <Link to="">My Account</Link>{" "}
                <img src="/images/brd_rowwo.png" alt="" />{" "}
                {userNewData?.role_type === 5 || userNewData?.role_type === 7
                  ? "KYC"
                  : "KYB"}
              </span>
            </div>
            <div className="row">
              <div className="col-lg-3 mb_view_hide">
                <ProfileSidebar />
              </div>

              <div className="col-lg-9">
                <div className="form_boxx profilss">
                  <div className="form_arar mt-0">
                    <h4>
                      {userNewData?.role_type === 5 ||
                      userNewData?.role_type === 7
                        ? "KYC"
                        : "KYB"}
                    </h4>
                    <div className="mang_und_listst mt-0" id="two_arts_prt">
                      <form action="" onSubmit={handleUpdate}>
                        <div className="row">
                          {userNewData &&
                            (userNewData?.role_type === 5 ||
                              userNewData?.role_type === 7) && (
                              <>
                                {" "}
                                <div className="col-lg-6 col-sm-6">
                                  {/* <div className="mgs_titales">
                                  <h6 className="titaless">NID Image</h6>
                                  <ImageUploader
                                    setState={setNidDocImage}
                                    defaultImage={nidDocImage}
                                  />
                                  <p className="t_sizes">
                                    Recommended image size 150x150
                                  </p>
                                </div> */}
                                  <div
                                    class="form-group quest_mngs"
                                    id="browsers"
                                  >
                                    <label>
                                      <span class="area_txt">
                                        NID Image / Documents
                                      </span>
                                    </label>
                                    <CommanImagePDf
                                      name="nidDocImage"
                                      setState={setNidDocImage}
                                      state={nidDocImage}
                                      module="kyc"
                                      clearError={clearError}
                                      loading={loadingTaxId}
                                      setLoading={setLoadingTaxId}
                                      successMessage={successTaxId}
                                      setSuccessMessage={setSuccessTaxId}
                                    />
                                  </div>
                                </div>
                              </>
                            )}

                          {userNewData &&
                            (userNewData?.role_type === 8 ||
                              userNewData?.role_type === 9) && (
                              <>
                                {" "}
                                <div className="col-lg-6 col-sm-6">
                                  {/* <div className="mgs_titales">
                                  <h6 className="titaless">
                                    Company Registration{" "}
                                  </h6>
                                  <ImageUploader
                                    setState={setCompanyRegistration}
                                    defaultImage={companyRegistration}
                                  />
                                  <p className="t_sizes">
                                    Recommended image size 150x150
                                  </p>
                                </div> */}
                                  <div
                                    class="form-group quest_mngs"
                                    id="browsers"
                                  >
                                    <label>
                                      <span class="area_txt">
                                        {" "}
                                        Company Registration
                                      </span>
                                    </label>
                                    <CommanImagePDf
                                      name="companyRegistration"
                                      setState={setCompanyRegistration}
                                      state={companyRegistration}
                                      module="kyc"
                                      clearError={clearError}
                                      loading={loadingNgoCert}
                                      setLoading={setLoadingNgoCert}
                                      successMessage={successNgoCert}
                                      setSuccessMessage={setSuccessNgoCert}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                  {/* <div className="mgs_titales">
                                  <h6 className="titaless">NGO Licences </h6>
                                  <ImageUploader
                                    setState={setNGOLicense}
                                    defaultImage={}
                                  />
                                  <p className="t_sizes">
                                    Recommended image size 150x150
                                  </p>
                                </div> */}
                                  <div
                                    class="form-group quest_mngs"
                                    id="browsers"
                                  >
                                    <label>
                                      <span class="area_txt">
                                        {" "}
                                        NGO Licences
                                      </span>
                                    </label>
                                    <CommanImagePDf
                                      name="ngo_license"
                                      setState={setNGOLicense}
                                      state={NGOLicense}
                                      module="kyc"
                                      clearError={clearError}
                                      loading={loadingTaxId}
                                      setLoading={setLoadingTaxId}
                                      successMessage={successTaxId}
                                      setSuccessMessage={setSuccessTaxId}
                                    />
                                  </div>
                                </div>{" "}
                              </>
                            )}

                          <div className="col-lg-12 spacetop mt-5">
                            <div class="form-group">
                              <button
                                type="submit"
                                value="Update Profile"
                                class="sub_mitess"
                                disabled={loading}
                              >
                                {loading ? (
                                  <ClipLoader color="#fff" size={"15"} />
                                ) : (
                                  `Update ${
                                    userNewData?.role_type === 5 ||
                                    userNewData?.role_type === 7
                                      ? "KYC"
                                      : "KYB"
                                  }`
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Careers;

import { Link, useNavigate, useParams } from "react-router-dom";
import { React, useEffect, useState } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import axiosClient from "../Services/Axios/axios";
import Swal from "sweetalert2";
import { BeatLoader, ClipLoader } from "react-spinners";
import validator from "validator";

const Donate = () => {
  const userdata = JSON.parse(localStorage.getItem("userData"));

  const { page_url } = useParams();
  const stripe = useStripe();
  const navigate = useNavigate();
  const elements = useElements();
  const [stripeData, setStripeData] = useState({
    campaign_id: "",
    amount: "",
    currency: "USD",
    paymentMethodType: "qr",
    tips: "",
    reciept: null,
    email: userdata?.email ? userdata?.email : "",
    name: userdata?.first_name
      ? `${userdata?.first_name} ${
          userdata?.last_name ? userdata?.last_name : ""
        } `
      : "",
    hide_name: false,
    user_id: "",
    cardtype: "",
    description: "Payment for campaign 3 - Tip included", // Add a description here
  });
  const [clientSecret, setClientSecret] = useState("");
  const [buttonloader, setButtonloader] = useState(false);
  const [donationData, setDonationData] = useState({});
  const [bankInfoData, setBankInfoData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ file: "" });
  const [errorMessage, setErrorMessage] = useState({});

  // Function to handle payment
  const getDonationData = async () => {
    try {
      setLoading(true);
      const data = await axiosClient.get(
        `/campaigns/getCampaignDetailsById/${page_url}?language=en`
      );

      if (!data.data.error) {
        setDonationData(data.data.data);
      }

      const BankData = await axiosClient.get(`/web/getBankDetails?language=en`);
      if (!BankData.data.error) {
        setBankInfoData(BankData.data.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handlePayment = async (event) => {
    event.preventDefault(); // Prevent form submission

    const Headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkZWZhdWx0VXNlciI6MzA5OCwidXNlclR5cGUiOjUsImlhdCI6MTczMTUxMjUwNywiZXhwIjoxNzMxNTk4OTA3fQ.GyNcakgR5XPB9zS-Qs7clnk7OvlhS4d3b3PxtDretP4`,
      },
    };

    // if (validator.isEmail(stripeData.email)) {
    //   setErrorMessage({ ...errorMessage, email: "Please enter a valid email" });
    //   setButtonloader(false);
    //   return;
    // }

    try {
      // const response = await axiosClient.post(
      //   "/campaigns/create-payment-intent",
      //   stripeData,
      //   Headers
      // );
      // Request to backend to create payment intent
      setButtonloader(true);
      if (
        stripeData.amount &&
        stripeData.email &&
        stripeData.name &&
        stripeData.reciept
      ) {
        setErrorMessage({});
        if (stripeData.paymentMethodType === "card") {
          setErrorMessage({});
          const errors = {};
          const cardElement = elements.getElement(CardNumberElement);
          const cardExpiry = elements.getElement(CardExpiryElement);
          const cardCvc = elements.getElement(CardCvcElement);
          if (cardElement._empty) {
            errors.cardNumber = "Card number is required";
          }
          if (cardExpiry._empty) {
            errors.cardExpiry = "Expiry date is required";
          }
          if (cardCvc._empty) {
            errors.cardCvv = "CVV is required";
          }
          if (!stripeData.cardtype) {
            errors.cardtype = "Payment method type is required";
          }
          if (Object.keys(errors).length > 0) {
            setErrorMessage({
              ...errors,
            });
            setButtonloader(false);
            return;
          }
          const response = await axiosClient.post(
            "/campaigns/create-payment-intent",
            {
              ...stripeData,
              reciept: "",
              campaign_id: Number(donationData.id),
              tips: stripeData.tips !== "" ? Number(stripeData.tips) : 0,
            },
            Headers
          );

          const { client_secret } = response.data;
          setClientSecret(client_secret);

          const { error, paymentIntent } = await stripe.confirmCardPayment(
            client_secret,
            {
              payment_method: {
                card: cardElement,
                billing_details: {
                  name: stripeData.name,
                  email: stripeData.email,
                  // address: {
                  //   // line1: "123 Main Street",
                  //   city: "Mumbai",
                  //   // state: "Maharashtra",
                  //   postal_code: "400001",
                  //   country: "IN",
                  // },
                },
              },
            }
          );

          if (error) {
            Swal.fire({
              icon: "error",
              title: "Payment failed",
              text: error.message,
            });
          } else if (paymentIntent && paymentIntent.status === "succeeded") {
            const response = await axiosClient.post("/campaigns/temp_webhook", {
              campaign_id: Number(donationData.id),
              client_secret: client_secret,
            });
            if (!response.data.error) {
              // Swal.fire({
              //   icon: "success",
              //   title: "Payment successful",
              // });

              navigate(`/donation-successful`, {
                state: response.data.Data,
              });
            }
          }
          setButtonloader(false);
        } else {
          if (!stripeData.reciept) {
            setErrorMessage({
              ...errorMessage,
              reciept: "Please enter a Receipt",
            });
          }

          // if (!pdfResponse.data.error) {
          const response = await axiosClient.post("/campaigns/direct-payment", {
            ...stripeData,
            campaign_id: Number(donationData.id),
            tips: stripeData.tips !== "" ? Number(stripeData.tips) : 0,
          });

          if (!response.data.error) {
            // Swal.fire({
            //   icon: "success",
            //   title: "Payment successful",
            // });
            navigate(`/donation-successful`, {
              state: response.data.transactionId,
            });
          }
          //  }
          setButtonloader(false);
        }
      } else {
        const errors = {};
        if (stripeData.paymentMethodType !== "card") {
          if (!stripeData.reciept) {
            errors.reciept = "Please enter a Receipt";
          }
        }

        if (!stripeData.amount || stripeData.amount <= 0) {
          errors.amount = "Please enter a valid amount";
        }

        if (!stripeData.email) {
          errors.email = "Please enter a valid email";
        }

        if (!stripeData.name) {
          errors.name = "Please enter a valid name";
        }

        if (Object.keys(errors).length > 0) {
          setErrorMessage(errors);
          setButtonloader(false);
          return; // Exit early if validation fails
        }
      }
    } catch (err) {
      console.log("Error creating payment intent:", err);
      setButtonloader(false);
      // alert("Failed to create payment intent");
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const validFileTypes = ["image/jpeg", "image/png"];

    if (file && !validFileTypes.includes(file.type)) {
      setError({
        ...error,
        file: "Invalid file type. Please select a  JPG, or PNG file.",
      });
      event.target.value = null;
      return;
    }
    const formData = new FormData();
    formData.append("image", file);
    const pdfResponse = await axiosClient.post("/users/uploadimg", formData);
    setError({
      ...error,
      file: "",
    });
    if (!pdfResponse.data.error) {
      setStripeData({
        ...stripeData,
        reciept: pdfResponse.data.file,
      });
      setErrorMessage({
        ...errorMessage,
        reciept: "",
      });
    }
  };

  useEffect(() => {
    getDonationData();
  }, []);
  useEffect(() => {
    setStripeData({ ...stripeData, id: donationData.id });
  }, [donationData]);
  if (loading) {
    return (
      <div className="loader">
        <BeatLoader color="#097C81" />
      </div>
    );
  }
  return (
    <>
      <div className="all_suport_bg manages_top">
        <section className="ser_v_araeea" id="pay_alss">
          <div className="container">
            <div className="cntr_box_all">
              {donationData.id && (
                <div className="top_user justify-content-start">
                  <div className="usr_pickss">
                    <img src={donationData.donation_images} alt="" />
                  </div>
                  <div className="cnt_tx_cntss">
                    <span>You’re supporting</span> {donationData.donation_title}
                  </div>
                </div>
              )}

              <div className="form_arar">
                <form action="">
                  <div className="price_list">
                    <h6>Enter your Donation</h6>
                    <ul>
                      <li
                        onClick={() =>
                          setStripeData({ ...stripeData, amount: "50" })
                        }
                        className={stripeData.amount === "50" ? "active" : ""}
                      >
                        $50
                      </li>
                      <li
                        onClick={() =>
                          setStripeData({ ...stripeData, amount: "100" })
                        }
                        className={stripeData.amount === "100" ? "active" : ""}
                      >
                        $100
                      </li>
                      <li
                        onClick={() =>
                          setStripeData({ ...stripeData, amount: "200" })
                        }
                        className={stripeData.amount === "200" ? "active" : ""}
                      >
                        $200
                      </li>
                      <li
                        onClick={() =>
                          setStripeData({ ...stripeData, amount: "300" })
                        }
                        className={stripeData.amount === "300" ? "active" : ""}
                      >
                        $300
                      </li>
                      <li
                        onClick={() =>
                          setStripeData({ ...stripeData, amount: "500" })
                        }
                        className={stripeData.amount === "500" ? "active" : ""}
                      >
                        $500
                      </li>
                      <li
                        onClick={() =>
                          setStripeData({ ...stripeData, amount: "1000" })
                        }
                        className={stripeData.amount === "1000" ? "active" : ""}
                      >
                        $1,000
                      </li>
                    </ul>
                  </div>

                  <div class="form-group marg_cds">
                    <span className="setss">.00</span>
                    <input
                      type="text"
                      class="form-control"
                      name=""
                      placeholder="USD"
                      value={stripeData.amount}
                      onChange={({ target }) => {
                        let result = target.value.replace(/[^0-9 ]/g, "");
                        if (result.length > 10) {
                          result = result.slice(0, 10);
                        }
                        setStripeData({ ...stripeData, amount: result });
                      }}
                      required
                    />
                    {errorMessage && errorMessage.amount && (
                      <>
                        <p className="text-danger">{errorMessage.amount}</p>
                      </>
                    )}
                  </div>

                  <div class="form-group manags_cntxt">
                    Donation Amount{" "}
                    <span className="pricde">USD {stripeData.amount}.00</span>
                  </div>

                  <div className="brd_liness mb-4">&nbsp;</div>

                  <div class="form-group marg_cds">
                    <span className="setss">.00</span>
                    <input
                      type="text"
                      class="form-control"
                      name=""
                      placeholder="Tip"
                      value={stripeData.tips}
                      onChange={({ target }) => {
                        let result = target.value.replace(/[^0-9 ]/g, "");
                        if (result.length > 6) {
                          result = result.slice(0, 10);
                        }
                        setStripeData({ ...stripeData, tips: result });
                      }}
                      required
                    />

                    <div class="form-group manags_cntxt mt-2">
                      Donation Tip Amount{" "}
                      <span className="pricde">USD {stripeData.tips}.00</span>
                    </div>
                    <span className="sm_tittlss">
                      Worldtrust relies on your generosity to continue providing
                      0% platform fee for fundraisers.
                    </span>
                  </div>

                  <div class="form-group manags_cntxt clr_blakk mt-3 mb-4">
                    Payment
                  </div>

                  <div class="form-group manags_cntxt">
                    Total Donation Amount{" "}
                    <span className="pricde">
                      USD {+stripeData.tips + +stripeData.amount}
                    </span>
                  </div>

                  <div className="price_list link_btnss mt-3" id="pay_crd_bnks">
                    <h6>Select Payment Option</h6>
                    <Tabs>
                      <div className="tabs_list">
                        <TabList>
                          <Tab
                            onClick={() =>
                              setStripeData({
                                ...stripeData,
                                paymentMethodType: "qr",
                              })
                            }
                          >
                            QR
                          </Tab>
                          <Tab
                            onClick={() =>
                              setStripeData({
                                ...stripeData,
                                paymentMethodType: "bank",
                              })
                            }
                          >
                            Bank
                          </Tab>
                          <Tab
                            onClick={() =>
                              setStripeData({
                                ...stripeData,
                                paymentMethodType: "card",
                              })
                            }
                          >
                            Card
                          </Tab>
                        </TabList>
                      </div>
                      <div className="content-wrapper">
                        <TabPanel>
                          <div className="mang_und_listst">
                            <form>
                              <div className="qr_coddd">
                                <p className="titalss">
                                  Scan with your bank app or payment app
                                </p>
                                <div className="qr_boxxss">
                                  <img
                                    src={bankInfoData?.qr_code_image}
                                    alt=""
                                  />
                                </div>
                                <span>
                                  Payments collected via Stripe. Payee name may
                                  appear as "Stripe Payments Pte. Ltd.".
                                </span>
                              </div>

                              <div class="brd_liness mt-2 mb-3">&nbsp;</div>
                              <div class="form-group" id="browsers">
                                <label>Attach Receipt (Only Image)</label>
                                <input
                                  type="file"
                                  class="form-control"
                                  name=""
                                  placeholder="Attach Receipt"
                                  accept=".pdf, .jpg, .jpeg, .png"
                                  onChange={handleFileChange}
                                  required
                                />
                                {error.file !== "" && (
                                  <div className="text-danger mt-1">
                                    {error.file}
                                  </div>
                                )}
                                {errorMessage && errorMessage.reciept && (
                                  <>
                                    <p className="text-danger">
                                      {errorMessage.reciept}
                                    </p>
                                  </>
                                )}
                              </div>
                              <div class="brd_liness mt-4 mb-4">&nbsp;</div>

                              <div class="form-group">
                                <label>Your Full Name</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name=""
                                  placeholder="Enter your name"
                                  value={stripeData.name}
                                  onChange={({ target }) =>
                                    setStripeData({
                                      ...stripeData,
                                      name: target.value,
                                    })
                                  }
                                  required
                                />
                                {errorMessage.name && (
                                  <div className="text-danger mt-1">
                                    {errorMessage.name}
                                  </div>
                                )}
                              </div>

                              <div class="form-group">
                                <label>Your Email</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name=""
                                  placeholder="Enter your email"
                                  value={stripeData.email}
                                  onChange={({ target }) =>
                                    setStripeData({
                                      ...stripeData,
                                      email: target.value,
                                    })
                                  }
                                  readOnly={userdata?.email ? true : false}
                                  required
                                />
                                {errorMessage.email && (
                                  <div className="text-danger mt-1">
                                    {errorMessage.email}
                                  </div>
                                )}
                              </div>

                              {/* <div className="form-group">
                                <p className="by_textxt">
                                  You will receive an email receipt for your
                                  donation.{" "}
                                </p>
                              </div> */}

                              <div
                                class="form-group check_txt ck_align"
                                id="checkox"
                              >
                                <input
                                  id="check1"
                                  name=""
                                  type="checkbox"
                                  value={stripeData.hide_name}
                                  onChange={({ target }) => {
                                    if (target.checked === true) {
                                      setStripeData({
                                        ...stripeData,
                                        hide_name: 1,
                                      });
                                    } else {
                                      setStripeData({
                                        ...stripeData,
                                        hide_name: 0,
                                      });
                                    }
                                  }}
                                />
                                <label for="check1">
                                  Hide name from everyone but fundraiser
                                </label>
                              </div>

                              <div class="form-group mt-5 mb-4">
                                <button
                                  type="button"
                                  value="Donate Now"
                                  className="sub_mitess"
                                  onClick={handlePayment}
                                  disabled={buttonloader}
                                >
                                  {buttonloader ? (
                                    <ClipLoader color="#fff" size={"25"} />
                                  ) : (
                                    "Donate Now"
                                  )}
                                </button>
                              </div>

                              <div className="form-group">
                                <p className="by_textxt">
                                  By choosing the payment method above, you
                                  agree to the Worldtrust{" "}
                                  <Link to="/terms-and-conditions">
                                    Terms of Service
                                  </Link>{" "}
                                  and acknowledge the{" "}
                                  <Link to="/privacy-policy">
                                    Privacy Notice.
                                  </Link>
                                </p>
                              </div>
                              <div class="brd_liness mt-4">&nbsp;</div>

                              <div className="gpr_g_textss">
                                <img src="/images/trapy_gr.png" alt="" />
                                <div className="grp_txtx">
                                  <h6>Worldtrust protects your donation</h6>
                                  <p>
                                    We guarantee you a full refund for up to a
                                    year in the rare case that fraud occurs. See
                                    our Worldtrust Giving Guarantee.
                                  </p>
                                </div>
                              </div>
                            </form>
                          </div>
                        </TabPanel>

                        <TabPanel>
                          <div className="mang_und_listst">
                            <form>
                              <h6>Bank Details</h6>

                              <div class="pri_liststs mt-4">
                                <div class="form-group manags_cntxt">
                                  Bank Name{" "}
                                  <span class="pricde black_tx">
                                    {bankInfoData.bankName}
                                  </span>
                                </div>
                                <div class="form-group manags_cntxt">
                                  Account No.{" "}
                                  <span class="pricde black_tx">
                                    {bankInfoData.accountNumber}
                                  </span>
                                </div>
                                <div class="form-group manags_cntxt">
                                  IFSC Code{" "}
                                  <span class="pricde black_tx">
                                    {" "}
                                    {bankInfoData.ifsc_code}
                                  </span>
                                </div>
                                <div class="form-group manags_cntxt">
                                  Amount{" "}
                                  <span class="pricde black_tx">
                                    {stripeData.amount}.00 USD
                                  </span>
                                </div>
                                {stripeData.tips &&  
                                <div class="form-group manags_cntxt">
                                  Tip{" "}
                                  <span class="pricde black_tx">
                                    {stripeData.tips}.00 USD
                                  </span>
                                </div>
                                }     
                              </div>

                              <div class="brd_liness mt-2 mb-3">&nbsp;</div>
                              <div class="form-group" id="browsers">
                                <label>Attach Receipt (Only Image)</label>
                                <input
                                  type="file"
                                  class="form-control"
                                  name=""
                                  placeholder="Attach Receipt"
                                  accept=".pdf, .jpg, .jpeg, .png"
                                  onChange={handleFileChange}
                                  required
                                />
                                {error.file !== "" && (
                                  <div className="text-danger mt-1">
                                    {error.file}
                                  </div>
                                )}
                                {errorMessage && errorMessage.reciept && (
                                  <>
                                    <p className="text-danger">
                                      {errorMessage.reciept}
                                    </p>
                                  </>
                                )}
                              </div>
                              <div class="brd_liness mt-4 mb-4">&nbsp;</div>

                              <div class="form-group">
                                <label>Your Full Name</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name=""
                                  placeholder="Enter your name"
                                  value={stripeData.name}
                                  onChange={({ target }) =>
                                    setStripeData({
                                      ...stripeData,
                                      name: target.value,
                                    })
                                  }
                                  required
                                />
                                {errorMessage.name && (
                                  <div className="text-danger mt-1">
                                    {errorMessage.name}
                                  </div>
                                )}
                              </div>

                              <div class="form-group">
                                <label>Your Email</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name=""
                                  placeholder="Enter your email"
                                  value={stripeData.email}
                                  onChange={({ target }) =>
                                    setStripeData({
                                      ...stripeData,
                                      email: target.value,
                                    })
                                  }
                                  required
                                />
                                {errorMessage.email && (
                                  <div className="text-danger mt-1">
                                    {errorMessage.email}
                                  </div>
                                )}
                              </div>

                              {/* <div className="form-group">
                                <p className="by_textxt">
                                  You will receive an email receipt for your
                                  donation.{" "}
                                </p>
                              </div> */}

                              <div
                                class="form-group check_txt ck_align"
                                id="checkox"
                              >
                                <input
                                  id="check1"
                                  name=""
                                  type="checkbox"
                                  value={stripeData.hide_name}
                                  onChange={({ target }) => {
                                    if (target.checked === true) {
                                      setStripeData({
                                        ...stripeData,
                                        hide_name: 1,
                                      });
                                    } else {
                                      setStripeData({
                                        ...stripeData,
                                        hide_name: 0,
                                      });
                                    }
                                  }}
                                />
                                <label for="check1">
                                  Hide name from everyone but fundraiser
                                </label>
                              </div>

                              <div class="form-group mt-5 mb-4">
                                <button
                                  type="button"
                                  value="Donate Now"
                                  className="sub_mitess"
                                  onClick={handlePayment}
                                  disabled={buttonloader}
                                >
                                  {buttonloader ? (
                                    <ClipLoader color="#fff" size={"25"} />
                                  ) : (
                                    "Donate Now"
                                  )}
                                </button>
                              </div>

                              <div className="form-group">
                                <p className="by_textxt">
                                  By choosing the payment method above, you
                                  agree to the Worldtrust{" "}
                                  <Link to="/terms-and-conditions">
                                    Terms of Service
                                  </Link>{" "}
                                  and acknowledge the{" "}
                                  <Link to="/privacy-policy">
                                    Privacy Notice.
                                  </Link>
                                </p>
                              </div>
                              <div class="brd_liness mt-4">&nbsp;</div>

                              <div className="gpr_g_textss">
                                <img src="/images/trapy_gr.png" alt="" />
                                <div className="grp_txtx">
                                  <h6>Worldtrust protects your donation</h6>
                                  <p>
                                    We guarantee you a full refund for up to a
                                    year in the rare case that fraud occurs. See
                                    our Worldtrust Giving Guarantee.
                                  </p>
                                </div>
                              </div>
                            </form>
                          </div>
                        </TabPanel>

                        <TabPanel>
                          <div className="mang_und_listst">
                            <form>
                              <h6>Card Details</h6>
                              <div class="form-group">
                                <label>Card Type</label>
                                <select
                                  onChange={({ target }) =>
                                    setStripeData({
                                      ...stripeData,
                                      cardtype: target.value,
                                    })
                                  }
                                  className="slt_st fulls_d"
                                >
                                  <option value="">Choose card type</option>
                                  <option value="credit">Credit</option>
                                  <option value="debit">Debit</option>
                                </select>
                                {errorMessage.cardtype && (
                                  <div className="text-danger mt-1">
                                    {errorMessage.cardtype}
                                  </div>
                                )}
                              </div>

                              <div class="form-group">
                                <label>Card No.</label>
                                <CardNumberElement className="paymentInput" />
                                {errorMessage.cardNumber && (
                                  <div className="text-danger mt-1">
                                    {errorMessage.cardNumber}
                                  </div>
                                )}
                              </div>
                              <div class="form-group">
                                <div className="row">
                                  <div className="col-lg-5">
                                    <label>Expiry Date</label>
                                    {/* <div className="bothss_slts">
                                      <select className="slt_st">
                                        <option value="">Feb</option>
                                      </select>

                                      <select className="slt_st">
                                        <option value="">2022</option>
                                      </select>
                                    </div> */}

                                    <CardExpiryElement className="paymentInput" />
                                    {errorMessage.cardExpiry && (
                                      <div className="text-danger mt-1">
                                        {errorMessage.cardExpiry}
                                      </div>
                                    )}
                                  </div>

                                  <div className="col-lg-3">
                                    <label>CVV</label>
                                    {/* <input
                                      type="text"
                                      class="form-control"
                                      name=""
                                      placeholder="xxxx"
                                      required=""
                                    /> */}

                                    <CardCvcElement className="paymentInput " />
                                    {errorMessage.cardCvv && (
                                      <div className="text-danger mt-1">
                                        {errorMessage.cardCvv}
                                      </div>
                                    )}
                                  </div>

                                  <div className="col-lg-12">
                                    <div
                                      class="form-group check_txt ck_align mt-3"
                                      id="checkox"
                                    >
                                      <input
                                        id="check3"
                                        name=""
                                        type="checkbox"
                                        value="yes"
                                      />
                                      <label for="check3">
                                        Save card for later use
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <CardElement /> */}

                              <div class="brd_liness mt-2 mb-3">&nbsp;</div>

                              <div className="form-group">
                                <label>Your Full Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter your name"
                                  value={stripeData.name}
                                  onChange={({ target }) => {
                                    if (target.value.length <= 50) {
                                      setStripeData({
                                        ...stripeData,
                                        name: target.value,
                                      });
                                    }
                                  }}
                                  required
                                />
                                {errorMessage.name && (
                                  <div className="text-danger mt-1">
                                    {errorMessage.name}
                                  </div>
                                )}
                              </div>

                              <div class="form-group">
                                <label>Your Email</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name=""
                                  placeholder="Enter your email"
                                  value={stripeData.email}
                                  onChange={({ target }) => {
                                    if (target.value.length <= 50) {
                                      setStripeData({
                                        ...stripeData,
                                        email: target.value,
                                      });
                                    }
                                  }}
                                  readOnly={userdata?.email ? true : false}
                                  required
                                />
                                {errorMessage.email && (
                                  <div className="text-danger mt-1">
                                    {errorMessage.email}
                                  </div>
                                )}
                              </div>

                              {/* <div className="form-group">
                                <p className="by_textxt">
                                  You will receive an email receipt for your
                                  donation.{" "}
                                </p>
                              </div> */}

                              <div
                                class="form-group check_txt ck_align"
                                id="checkox"
                              >
                                <input
                                  id="check1"
                                  name=""
                                  type="checkbox"
                                  value={stripeData.hide_name}
                                  onChange={({ target }) => {
                                    if (target.checked === true) {
                                      setStripeData({
                                        ...stripeData,
                                        hide_name: 1,
                                      });
                                    } else {
                                      setStripeData({
                                        ...stripeData,
                                        hide_name: 0,
                                      });
                                    }
                                  }}
                                />
                                <div className="flex">
                                  <label for="check1">
                                    Hide name from everyone but fundraiser
                                  </label>
                                </div>
                              </div>

                              <div class="form-group mt-5 mb-4">
                                <button
                                  type="button"
                                  value="Donate Now"
                                  className="sub_mitess"
                                  onClick={handlePayment}
                                  disabled={buttonloader}
                                >
                                  {buttonloader ? (
                                    <ClipLoader color="#fff" size={"25"} />
                                  ) : (
                                    "Donate Now"
                                  )}
                                </button>
                              </div>

                              <div className="form-group">
                                <p className="by_textxt">
                                  By choosing the payment method above, you
                                  agree to the Worldtrust{" "}
                                  <a
                                    href="/terms-and-conditions"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Terms of Service
                                  </a>
                                  and acknowledge the{" "}
                                  <Link to="/privacy-policy">
                                    Privacy Notice.
                                  </Link>
                                </p>
                              </div>
                              <div class="brd_liness mt-4">&nbsp;</div>

                              <div className="gpr_g_textss">
                                <img src="/images/trapy_gr.png" alt="" />
                                <div className="grp_txtx">
                                  <h6>Worldtrust protects your donation</h6>
                                  <p>
                                    We guarantee you a full refund for up to a
                                    year in the rare case that fraud occurs. See
                                    our Worldtrust Giving Guarantee.
                                  </p>
                                </div>
                              </div>
                            </form>
                          </div>
                        </TabPanel>
                      </div>
                    </Tabs>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Donate;

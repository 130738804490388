import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { ClipLoader } from "react-spinners";
import Swal from "sweetalert2";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import axiosClient from "../../Services/Axios/axios";

const CommanImagePDf = ({
  name,
  setState,
  state,
  module,
  clearError,
  loading,
  setLoading,
  successMessage,
  setSuccessMessage,
}) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [image, setImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deviceType, setDeviceType] = useState("");
  const [stream, setStream] = useState(null);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
 
  const [isCapturing, setIsCapturing] = useState(false);
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const isMobileUserAgent =
      /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(
        userAgent
      );
    const isSmallScreen = window.innerWidth <= 768;

    if (isMobileUserAgent || isSmallScreen) {
      setDeviceType("Phone");
    } else {
      setDeviceType("Computer");
    }
  }, []);

  const startCamera = async (e) => {
    e.preventDefault();
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: "environment", // Use "user" for the front camera
          width: { ideal: 1280 },
          height: { ideal: 720 },
        },
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
      setStream(stream);
    } catch (error) {
      console.error("Error accessing the camera:", error);
      alert("Could not access the camera. Please check permissions.");
    }
  };

  const captureImage = async (e, name) => {
    e.preventDefault();
    setIsCapturing(true);
    setSuccessMessage("");
    setState((prevState) => ({
      ...prevState,
      [name]: null, // Clear the PDF name
    }));
    if (videoRef.current && canvasRef.current) {
      const context = canvasRef.current.getContext("2d");
      canvasRef.current.width = videoRef.current.videoWidth;
      canvasRef.current.height = videoRef.current.videoHeight;

      context.drawImage(videoRef.current, 0, 0);

      const imageData = canvasRef.current.toDataURL("image/png");

      const blob = await fetch(imageData).then((res) => res.blob());

      const formData = new FormData();
      formData.append("image", blob, "captured_image.png");

      try {
        const responce = await axiosClient.post("/users/uploadimg", formData);
        if (!responce.data.error) {
          // setState({
          //   ...state,
          //   [name]: responce.data.file,
          // });
          setState((prevState) => ({
            ...prevState,
            [name]: null, // Clear the PDF name
          }));
          setState((prevState) => ({
            ...prevState,
            [name]: responce.data.file,
          }));
          setIsCapturing(true);
          setTimeout(() => {
            setIsCapturing(false);
  
            Swal.fire({
              icon: "success",
              title: "Image capture",
            });
          }, 2000);
          setIsCapturing(false);
          clearError(name);
        }
      } catch (error) {
        console.error("Error uploading image:", error);

      }
    }
    setIsModalOpen(false);
    setImage(null);
    setIsCapturing(false);
  };
  

  // const (state[name]) = () => {
  //   if (state[name]) {
  //     saveAs(state[name], name || "document.pdf");
  //   }
  // };

  
  
  
  
  
  const handleChange1 = async (e, name, type) => {
    setSuccessMessage("");
    setLoading(true);
    try {
      const file = e.target.files[0];
      const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
      if (file.size > maxFileSize) {
        setLoading(true);
        setTimeout(() => {
          setLoading(false);

          Swal.fire({
            icon: "error",
            title: "File size exceeds 5MB",
            text: "Please upload a file smaller than 5MB.",
          });
        }, 2000);
        e.target.value = null;
        return;
      }

      const formData = new FormData();
      let responce;
      if (module === "kyc") {
        formData.append(`${type === "phone" ? "image" : "pdf_images"}`, file);
        try {
          responce = await axiosClient.post(
            `/users/${type === "phone" ? "uploadimg" : "uploadpdfImage"}`,
            formData
          );

          if (!responce.data.error) {
            setState((prevState) => ({
              ...prevState,
              [name]: responce.data.file,
            }));
            setSuccessMessage("Document uploaded successfully");
            clearError(name);
          } else {
            Swal.fire({
              icon: "error",
              title: "Upload failed",
              text: responce.data.message || "Something went wrong!",
            });
            e.target.value = null;
          }
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Upload failed",
            text: error.message || "Something went wrong!",
          });
          e.target.value = null;
        }
      } else {
        formData.append(`${type === "phone" ? "image" : "all_docs"}`, file);
        // setLoading(true);
        responce = await axiosClient.post(
          `/users/${
            type === "phone" ? "uploadimg" : "uploadpdfImageDocExcelCSV"
          }`,
          formData
        );
        if (!responce.data.error) {
          setState((prevState) => ({
            ...prevState,
            [name]: responce.data.file,
          }));
          
          setSuccessMessage("Document uploaded successfully");
          clearError(name);
        } else {
          Swal.fire({
            icon: "error",
            title: responce.data.message,
          });
        }
      }
      setLoading(false);
    } catch (error) {
      console.error("Error uploading image:", error);
      Swal.fire({
        icon: "error",
        title: error.response.data.message,
      });
      e.target.value = null;
    }
  };

  const handleClose1 = () => {
    setIsModalOpen(false);
    setImage(null);
    if (videoRef.current && stream) {
      // Stop all video tracks (this will stop the camera stream)
      stream.getTracks().forEach((track) => track.stop());
      videoRef.current.srcObject = null; // Remove the video source
    }
  };

  return (
    <>
      <div className="d-flex">
        <input
          type="file"
          className="form-control"
          name={name}
          onChange={(e) => handleChange1(e, name)}
          style={{ width: "80%" }}
        />

        {deviceType === "Phone" && (
          <label className="upload-button" style={{ width: "20%" }}>
            Camera
            <input
              type="file"
              capture="environment"
              name={name}
              onChange={(e) => handleChange1(e, name, "phone")}
              style={{ display: "none" }}
            />
          </label>
        )}
        {deviceType === "Computer" && (
          <label
            style={{ width: "20%", height: "45px", padding: "8px 10px" }}
            className="upload-button"
            onClick={(e) => {
              setIsModalOpen(true);
              startCamera(e);
            }}
          >
            Camera
          </label>
        )}

        <Modal
          className="all_supportss sm_sizess"
          id="capture"
          show={isModalOpen}
          onHide={handleClose1}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <canvas ref={canvasRef} style={{ display: "none" }} />
            <video ref={videoRef} style={{ width: "100%", height: "100%" }} />
            <button
              className="cap_imgess"
              onClick={(e) => captureImage(e, name)}
            >
              Capture Image
            </button>
            
            {image && (
              <div>
                <h3>Captured Image:</h3>
                <img src={image} alt="Captured" style={{ width: "300px" }} />
              </div>
            )}
          </Modal.Body>
        </Modal>
      </div>
      {isCapturing && <ClipLoader color="#000" size={15} />} 
      {loading && <ClipLoader color="#000" size={15} />} {/* Loader */}
      {successMessage && <p className="text-success">{successMessage}</p>}{" "}
      {/* Success message */}
      {state[name] ? (
        state[name].toLowerCase().endsWith(".pdf") ? (
          <a
            // target="_blank"
            // rel="noopener noreferrer"
            download
            href={state[name]}
            // download={name}
          >
            Download PDF
          </a>
        ) : (
      //     <a href={state[name]} target="_blank" rel="noopener noreferrer">
      //       <img
      //         src={state[name]}
      //         alt="Uploaded file preview"
      //         style={{ cursor: "pointer" }}
      //       />
      //     </a>
      //     // <img src={state[name]} alt="Uploaded file preview" />
      //   )
      <img
          src={state[name]}
          alt="Uploaded file preview"
          style={{ cursor: "pointer" }}
          onClick={() => setIsLightboxOpen(true)}
        />
      )
      ) : null}

  
    <Lightbox
      open={isLightboxOpen}
      close={() => setIsLightboxOpen(false)}
      slides={[{ src: state[name], title: "Uploaded Image" }]}
      
    />
    </>
  );
};

export default CommanImagePDf;

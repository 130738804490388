import React from "react";
import LogoIcon from "../../assets/Images/MiniLogo.png";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
import axiosClient from "../../Services/Axios/axios";
const AuthScreen = () => {
  const navigate = useNavigate();

  const firebaseConfig = {
    apiKey: "AIzaSyDvweZ-u_rORGyDoQ-lfvQ_BYnSkcNUYxk",
    authDomain: "world-ngo.firebaseapp.com",
    projectId: "world-ngo",
    storageBucket: "world-ngo.firebasestorage.app",
    messagingSenderId: "342829559045",
    appId: "1:342829559045:web:2ec8c6b1037e91485f9b6c",
    measurementId: "G-4KDR6QDD24",
  };

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const auth = getAuth(app);
  const googleProvider = new GoogleAuthProvider();
  const facebookProvider = new FacebookAuthProvider();
  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      const userData = {
        email: user.email,
        first_name: user.displayName.split(" ")[0],
        last_name: user.displayName.split(" ").slice(1).join(" "),
        profile_image: user.photoURL,
        signup_type: "2",
        role_type: 0,
        parent_referral_code: "",
      };

      const response = await axiosClient.post(
        "/users/social-media-register",
        userData
      );
      if (response.data && !response.data.error) {
        localStorage.setItem("userData", JSON.stringify(response.data.data));
        Swal.fire({
          icon: "success",
          title: "Login Successful",
          text: "Welcome to WorldTrust!",
          showConfirmButton: false,
          timer: 1000,
        }).then(() => {
          navigate("/");
        });
      } else {
        throw new Error(response.data.message || "Failed to login with Google");
      }
    } catch (error) {
      // Swal.fire({
      //   icon: "error",
      //   title: "Google Login Failed",
      //   text:
      //     error.message ||
      //     "An error occurred during Google login. Please try again.",
      // });
    }
  };

  const handleFacebookLogin = async () => {
    try {
      const result = await signInWithPopup(auth, facebookProvider);
      const user = result.user;
      const userData = {
        email: user.email,
        first_name: user.displayName.split(" ")[0],
        last_name: user.displayName.split(" ").slice(1).join(" "),
        profile_image: user.photoURL,
        signup_type: "1",
        role_type: 0,
        parent_referral_code: "",
      };

      const response = await axiosClient.post(
        "/users/social-media-register",
        userData
      );
      if (response.data && !response.data.error) {
        localStorage.setItem("userData", JSON.stringify(response.data.data));
        Swal.fire({
          icon: "success",
          title: "Login Successful",
          text: "Welcome to WorldTrust!",
          showConfirmButton: false,
          timer: 1000,
        }).then(() => {
          navigate("/");
        });
      } else {
        throw new Error(
          response.data.message || "Failed to login with Facebook"
        );
      }
    } catch (error) {
      // Swal.fire({
      //   icon: "error",
      //   title: "Facebook Login Failed",
      //   text:
      //     error.message ||
      //     "An error occurred during Facebook login. Please try again.",
      // });
    }
  };

  return (
    <div className="container-fluid vh-100 d-flex justify-content-center login-container manages_top">
      <div className="row w-100 h-100">
        <div className="col-md-6 d-none d-md-block" id="desk_top_hide">
          {/* <div className="h-100 d-flex flex-column justify-content-center align-items-center">
            <img src={LogoIcon} alt="WorldTrust" className="img-fluid" />
            <h5 className="mt-3">Helping with Donations</h5>
          </div> */}
        </div>

        <div className="col-md-6 d-flex flex-column justify-content-center align-items-center" id="cnt_managess_area">
          <div className="card p-4 w-100 h-100 d-flex flex-column justify-content-center align-items-center sc_lgo_allss">
            <div className="d-flex justify-content-center   ">
              <img src={LogoIcon} alt="WorldTrust" />
            </div>
            <h3 className="text-center">Welcome to WorldTrust</h3>
            {/* 
            <p className="text-center fw-bolder ">
              Register using Email Address
            </p>
			*/}

            <div className="align-items-center gap-2 mt-3 social_bntt both_partss" id="width_auto_al_screens">
              <button
                className="btn btn-danger social_icnn mb-3"
                onClick={handleGoogleLogin}
              >
                {" "}
                <img
                  src="/images/google_ic_n.png"
                  className="set_iconss"
                  alt=""
                />{" "}
                Use My Google Account
              </button>
              <button
                className="btn btn-facebook social_icnn"
                onClick={handleFacebookLogin}
              >
                <img
                  src="/images/face_bk_ic_n.png"
                  className="set_iconss"
                  alt=""
                />{" "}
                Use My Facebook Account
              </button>
            </div>

            {/* <form>
              <div className="form-group mb-3">
                <label>Email Address</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter email"
                />
              </div>
              <div className="form-group mb-3">
                <label>Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Enter password"
                />
              </div>
              <div className="d-grid">
                <button type="submit" className="btn btn-primary btn-block">
                  Register With Email
                </button>
              </div>
            </form> */}

            <p className="text-center mt-4 mb-1">
              If you're already registered, <Link to="/login">Login</Link>
            </p>

            <div className="text-center">Or continue with</div>

            <div className="d-flex justify-content-center mt-3 social_bntt both_partss">
              <button
                className="btn bg-natural social_icnn"
                onClick={() => navigate("/register")}
              >
                Register with Email
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthScreen;

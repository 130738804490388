import React, { useEffect, useState } from "react";
import LogoIcon from "../../assets/Images/MiniLogo.png";
import { Link, useNavigate } from "react-router-dom";

const PassswordMessage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  return (
    <>
      <div className="container-fluid vh-100 d-flex justify-content-center login-container manages_top">
        <div className="row w-100 h-100">
          {/* Left side image */}
          <div className="col-md-6 d-none d-md-block" id="desk_top_hide">
            {/* <div className="h-100 d-flex flex-column justify-content-center align-items-center">
            <img src={LogoIcon} alt="WorldTrust" className="img-fluid" />
            <h5 className="mt-3">Helping with Donations</h5>
          </div> */}
          </div>

          {/* Right side login form */}
          <div className="col-md-6 d-flex flex-column justify-content-center align-items-center"  id="cnt_managess_area">
            <div className="card p-4 w-100 h-100 d-flex flex-column justify-content-center align-items-center sc_lgo_allss">
              <div className="d-flex justify-content-center   ">
                <img src={LogoIcon} alt="WorldTrust" />
              </div>
              <h3 className="text-center">Reset Your Password</h3>
              <p className="text-center fw-bolder w-75 ">
                We have sent an email to{" "}
                <span className="text-primary">{email}</span> with password
                reset instructions. If you don't see it after a few minutes,
                check your spam folder.
              </p>
              <div className="d-flex flex-column align-items-center   w-50">
                <p className="text-start mt-3">
                  Return to <Link to="/login">Login</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PassswordMessage;

import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Modal } from "react-bootstrap";
import ScannerModal from "../Events/ScannerModal";
import axiosClient from "../../Services/Axios/axios";
import Swal from "sweetalert2";

const ProfileSidebar = () => {
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [showscan, setShowscan] = useState(false);
  const [userNewData, setUserNewData] = useState({});
  const [meriData, setMeriData] = useState();
  const navigation = useNavigate();

  // const userNewData = JSON.parse(localStorage.getItem("userData"));
  const cameracl = () => setShowscan(true);
  const handleClose1 = () => setShowscan(false);
  const videoshow = () => {
    setShow(true);
    localStorage.removeItem("userData");
  };
  const getMeritsData = async () => {
    const response = await axiosClient.get(
      "/users/getMeritListing?language=en&pageSize=10&pageNo=1"
    );
    if (!response.data.error) {
      setMeriData(response.data.MeritPoints);
    }
  };
  const handleLogout = () => {
    Swal.fire({
      title: "Do you want to logout?",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        navigation("/");
        localStorage.clear();
        Swal.fire({
          title: "Log out",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    });
  };
  useEffect(() => {
    getMeritsData();
    setUserNewData(JSON.parse(localStorage.getItem("userData")));
  }, []);
  return (
    <div className="my_profiless mb_view_hide">
      <div className="top_pro_ct_mg">
        <div className="pro_imgs">
          <img
            src={`${
              userNewData?.profile_image
                ? userNewData?.profile_image
                : "/images/user_dummy1.png"
            }`}
            alt=""
          />
        </div>
        <h4>{userNewData?.first_name} {userNewData?.last_name}</h4>
        <p>{userNewData?.email}</p>
      </div>
      <ul className="profile_menus">
        {(userNewData?.profile_complete === 1 ||
          userNewData?.role_type === 5 ||
          userNewData?.role_type === 7) && (
          <li>
            <Link
              to="/impact"
              className={`${location.pathname === "/impact" ? "act" : ""}`}
            >
              <img src="/images/side_m_ic1.png" alt="" /> Impact
            </Link>
          </li>
        )}

        <li>
          <Link
            to="/profile"
            className={`${location.pathname === "/profile" ? "act" : ""}`}
          >
            <img src="/images/side_m_ic2.png" alt="" /> Profile
          </Link>
        </li>
        <li>
          <Link
            to={
              userNewData?.role_type === 8 || userNewData?.role_type === 9
                ? "/kyb"
                : "/kyc"
            }
            className={`${location.pathname === "/kyc" ? "act" : ""}`}
          >
            <img src="/images/side_m_ic10.png" alt="" />{" "}
            {userNewData?.role_type === 8 || userNewData?.role_type === 9
              ? "KYB"
              : "KYC"}
          </Link>
        </li>
        {/* <li>
          <Link
            to="/tax-information"
            className={`${
              location.pathname === "/tax-information" ? "act" : ""
            }`}
          >
            <img src="/images/side_m_ic11.png" alt="" /> Tax Information
          </Link>
        </li> */}
        {(userNewData?.profile_complete === 1 ||
          userNewData?.role_type === 5 ||
          userNewData?.role_type === 7) && (
          <>
            {" "}
            <li>
              <Link
                to="/gratitude-system"
                className={`${
                  location.pathname === "/gratitude-system" ? "act" : ""
                }`}
              >
                <img src="/images/side_m_ic3.png" alt="" /> Gratitude
              </Link>
            </li>
          </>
        )}

        {(userNewData?.role_type === 8 || userNewData?.role_type === 9) && (
          <>
            {" "}
            <li>
              <Link
                to="/additional-information"
                className={`${
                  location.pathname === "/additional-information" ? "act" : ""
                }`}
              >
                <img src="/images/adit_infor1.png" alt="" /> Additional
                Information
              </Link>
            </li>
          </>
        )}

        {(userNewData?.profile_complete === 1 ||
          userNewData?.role_type === 5 ||
          userNewData?.role_type === 7) && (
          <li>
            <Link
              to="/my-events"
              className={`${location.pathname === "/my-events" ? "act" : ""}`}
            >
              <img src="/images/side_m_ic4.png" alt="" /> Events
            </Link>
          </li>
        )}

   {userNewData?.role_type === 5 ||
     userNewData?.role_type === 7 ||
        userNewData?.profile_complete === 2|| /*add by me */
        userNewData?.profile_complete === 0 ? null : (
          <>
            <li>
              <Link
                to="/campaign"
                className={`${location.pathname === "/campaign" ? "act" : ""}`}
              >
                <img src="/images/side_m_ic5.png" alt="" /> Campaigns
              </Link>
            </li>
            {/* <li>
              <Link
                to="/transaction-history"
                className={`${
                  location.pathname === "/transaction-history" ? "act" : ""
                }`}
              >
                <img src="/images/side_m_ic6.png" alt="" /> Transactions
              </Link>
            </li> */}
          </>
        )}
        {/* <li>
         <Link
           to="/wallet"
           className={`${location.pathname === "/wallet" ? "act" : ""}`}
         >
           <img src="/images/side_m_ic6.png" alt="" /> Wallet
         </Link>
       </li> */}

        <li>
          <Link
            to="/referral"
            className={`${
              location.pathname === "/referral" ||
              location.pathname === "/referral-list"
                ? "act"
                : ""
            }`}
          >
            <img src="/images/side_m_ic7.png" alt="" /> Referral
          </Link>
        </li>
        <li>
          <Link
            to="/notification"
            className={`${location.pathname === "/notification" ? "act" : ""}`}
          >
            <img src="/images/side_m_ic7.png" alt="" /> Notification
          </Link>
        </li>

        {/* {(userNewData?.profile_complete === 1 ||
          userNewData?.role_type === 5 ||
          userNewData?.role_type === 7) && (
          <li>
            <Link
              to="/settings"
              className={`${location.pathname === "/settings" ? "act" : ""}`}
            >
              <img src="/images/side_m_ic8.png" alt="" /> Settings
            </Link>
          </li>
        )} */}


          <li>
            <Link
              to="/settings"
              className={`${location.pathname === "/settings" ? "act" : ""}`}
            >
              <img src="/images/side_m_ic8.png" alt="" /> Settings
            </Link>
          </li>


        <li onClick={handleLogout}>
          <Link to="">
            <Button variant="primary">
              <img src="/images/side_m_ic9.png" alt="" /> Logout
            </Button>
          </Link>
        </li>
      </ul>

      {userNewData &&
        (userNewData?.profile_complete === 1 ||
          userNewData?.role_type === 5 ||
          userNewData?.role_type === 7) && (
          <>
            <div className="merit_point">
              <div className="pnt_logos">
                <img src="/images/point_logo.png" alt="" />
              </div>
              <div className="cnt_textxt">
                <h2>{meriData === null ? 0 : meriData}</h2>
                <p>Merit Points</p>
              </div>
            </div>

            <div className="scanss">
              <div className="scansses">
                <h5 onClick={cameracl}>
                  Scan Now{" "}
                  <span className="scaan">
                    <img src="/images/scanner.png" alt="" />
                  </span>
                </h5>
              </div>
              <p>Note : scan now for your events</p>
              {/* <p>{camscanData?.id}</p> */}
            </div>
          </>
        )}
      <ScannerModal open={showscan} onClose={handleClose1} />
    </div>
  );
};

export default ProfileSidebar;

import React, { useState } from "react";
import LogoIcon from "../assets/Images/MiniLogo.png";
import { Link, useNavigate } from "react-router-dom";
import axiosClient from "../Services/Axios/axios";
import Swal from "sweetalert2";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import ViewIcon from "../assets/Images/eye-open.png";
import HideIcon from "../assets/Images/eye-crossed.png";
import { BeatLoader } from "react-spinners";

import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDvweZ-u_rORGyDoQ-lfvQ_BYnSkcNUYxk",
  authDomain: "world-ngo.firebaseapp.com",
  projectId: "world-ngo",
  storageBucket: "world-ngo.firebasestorage.app",
  messagingSenderId: "342829559045",
  appId: "1:342829559045:web:2ec8c6b1037e91485f9b6c",
  measurementId: "G-4KDR6QDD24",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

const Login = () => {
  const navigate = useNavigate();
  const [loginInfo, setLoginInfo] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (evt) => {
    try {
      evt.preventDefault();

      // // Check for special character and text in password
      // const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(loginInfo.password);
      // const hasLetter = /[a-zA-Z]/.test(loginInfo.password);

      // if (!hasSpecialChar || !hasLetter) {
      //   Swal.fire({
      //     icon: "error",
      //     title: "Invalid Password",
      //     text: "Password must contain at least one special character and one letter",
      //   });
      //   return;
      // }

      const data = await axiosClient.post(`/users/login`, loginInfo);
      if (!data.data.error) {
        Swal.fire({
          icon: "success",
          title: "Login Successful",
          text: "Welcome back to WorldTrust!",
          showConfirmButton: false,
          timer: 1000,
        }).then(() => {
          localStorage.setItem("userData", JSON.stringify(data.data.data));
          navigate("/");
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Login Failed",
        text:
          error.response?.data?.message ||
          "An error occurred during login. Please try again.",
      });
    }
  };

  const handleGoogleLogin = async () => {
    setLoading(true);
    setErrorMessage("");
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      const userData = {
        email: user.email,
        first_name: user.displayName.split(" ")[0],
        last_name: user.displayName.split(" ").slice(1).join(" "),
        profile_image: user.photoURL,
        signup_type: "2",
        role_type: 0,
        parent_referral_code: "",
      };

      const response = await axiosClient.post(
        "/users/social-media-register",
        userData
      );
      if (response.data && !response.data.error) {
        console.log(response.data.data);
        localStorage.setItem("userData", JSON.stringify(response.data.data));
        Swal.fire({
          icon: "success",
          title: "Login Successful",
          text: "Welcome to WorldTrust!",
          showConfirmButton: false,
          timer: 1000,
        }).then(() => {
          navigate("/");
        });
      } else {
        throw new Error(response.data.message || "Failed to login with Google");
      }
    } catch (error) {
      // catch (error) {
      //   Swal.fire({
      //     icon: "error",
      //     title: "Google Login Failed",
      //     text:
      //       error.message ||
      //       "An error occurred during Google login. Please try again.",
      //   });
      // }

      console.log(error);
      // setErrorMessage(error.message || "An error occurred during Google login. Please try again.");
      // navigate("/login");
      if (error.code === "auth/popup-closed-by-user") {
        // User closed the popup
        setErrorMessage(
          "An error occurred during the process. Please try again.Feel free to reach out to our support team for assistance."
        );
        navigate("/login");
      } else {
        console.log("login Error", error);
        setErrorMessage(
          "An error occurred during Google login. Please try again."
        );
        navigate("/login");
      }

      // Swal.fire({
      //   icon: "error",
      //   title: "Google Login Failed",
      //   text:
      //     error.message ||
      //     "An error occurred during Google login. Please try again.",
      // });
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleFacebookLogin = async () => {
    setErrorMessage("");
    setLoading(true);
    try {
      const result = await signInWithPopup(auth, facebookProvider);
      const user = result.user;
      const userData = {
        email: user.email,
        first_name: user.displayName.split(" ")[0],
        last_name: user.displayName.split(" ").slice(1).join(" "),
        profile_image: user.photoURL,
        signup_type: "1",
        role_type: 0,
        parent_referral_code: "",
      };

      const response = await axiosClient.post(
        "/users/social-media-register",
        userData
      );
      if (response.data && !response.data.error) {
        localStorage.setItem("userData", JSON.stringify(response.data.data));
        Swal.fire({
          icon: "success",
          title: "Login Successful",
          text: "Welcome to WorldTrust!",
          showConfirmButton: false,
          timer: 1000,
        }).then(() => {
          navigate("/");
        });
      } else {
        throw new Error(
          response.data.message || "Failed to login with Facebook"
        );
      }
    } catch (error) {
      // catch (error) {
      //   Swal.fire({
      //     icon: "error",
      //     title: "Facebook Login Failed",
      //     text:
      //       error.message ||
      //       "An error occurred during Facebook login. Please try again.",
      //   });
      // }

      if (error.code === "auth/popup-closed-by-user") {
        setErrorMessage(
          "An error occurred during the process. Please try again.Feel free to reach out to our support team for assistance."
        );
        navigate("/login");
      } else {
        console.log("login Error", error);
        setErrorMessage(
          "An error occurred during Google login. Please try again."
        );
        navigate("/login");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid vh-100 d-flex justify-content-center login-container manages_top">
      {loading && (
        <>
          <div className="blur-background"></div>
          <div className="loader" style={{ zIndex: 1000, position: "fixed" }}>
            <BeatLoader color="#097C81" />
          </div>
        </>
      )}
      <div className="row w-100 h-100">
        {/* Left side image  */}
        <div className="col-md-6 d-none d-md-block" id="desk_top_hide">
          {/* <div className="h-100 d-flex flex-column justify-content-center align-items-center">
            <img src={LogoIcon} alt="WorldTrust" className="img-fluid" />
            <h5 className="mt-3">Helping with Donations</h5>
          </div> */}
        </div>

        {/* Right side login form   */}
        <div
          className="col-md-6 d-flex flex-column justify-content-center align-items-center"
          id="cnt_managess_area"
        >
          <div className="card p-4 w-100 h-100 d-flex flex-column justify-content-center align-items-center sc_lgo_allss">
            <div className="d-flex justify-content-center tb_vw_space">
              <img src={LogoIcon} alt="WorldTrust" />
            </div>
            <h3 className="text-center">Welcome to WorldTrust</h3>

            <p className="text-center fw-bolder ">
              Register using Email Address
            </p>

            <form
              className="w-50"
              id="width_auto_al_screens"
              onSubmit={handleSubmit}
            >
              <div className="form-group mb-2">
                <label className="mb-1">Email Address</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter email"
                  value={loginInfo.email}
                  onChange={({ target }) =>
                    setLoginInfo({ ...loginInfo, email: target.value })
                  }
                  required
                />
              </div>
              <div className="form-group mb-2">
                <label className="mb-1">Password</label>
                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Enter password"
                    value={loginInfo.password}
                    onChange={({ target }) =>
                      setLoginInfo({ ...loginInfo, password: target.value })
                    }
                    required
                  />
                  <button
                    type="button"
                    className="btn btn-outline-secondary LoginViewButton"
                    onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
                  >
                    {showPassword ? (
                      <img src={ViewIcon} alt="Hide" /> // Change to your hide icon
                    ) : (
                      <img src={HideIcon} alt="View" /> // Change to your view icon
                    )}
                  </button>
                </div>
                <p className="text-right mt-2">
                  <Link to="/forgot-password">Forgot Password?</Link>
                </p>
              </div>
              <div className="d-grid">
                <button type="submit" className="btn bg-natural btn-block">
                  Login
                </button>
              </div>
              <div className="d-grid">
                <button type="button" className="btn btn-primary btn-block">
                  Register With Email
                </button>
              </div>
            </form>
            {errorMessage && (
              <div className="alert alert-danger mt-3" role="alert">
                {errorMessage}
              </div>
            )}
            <p className="text-center">
              if you haven't registered ? <Link to="/register">Register</Link>
            </p>

            <div className="text-center">Or continue with</div>

            <div
              className="align-items-center gap-2 mt-3 social_bntt both_partss"
              id="width_auto_al_screens"
            >
              <button
                className="btn btn-danger social_icnn mb-3"
                onClick={handleGoogleLogin}
              >
                <img
                  src="/images/google_ic_n.png"
                  className="set_iconss"
                  alt=""
                />{" "}
                Use My Google Account
              </button>
              <button
                type="button"
                className="btn btn-facebook social_icnn"
                onClick={handleFacebookLogin}
              >
                <img
                  src="/images/face_bk_ic_n.png"
                  className="set_iconss"
                  alt=""
                />{" "}
                Use My Facebook Account
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

import { Pagination, PaginationItem } from "@mui/material";
import React from "react";

const CommanPagination = ({
  currentPage,
  setCurrentPage,
  limit,
  totalCounts,
  totalPage,
}) => {
  // Calculate the range of entries being shown
  const startEntry = (currentPage - 1) * limit + 1;
  const endEntry = Math.min(currentPage * limit, totalCounts);

  // Handle page change
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  return (
    <div className="pages_listst">
      <div className="row mt-4">
        <div className={totalCounts > 0 ? "col-lg-6" : "col-lg-12"}>
          <div className="list_cnts">
            {totalCounts > 0 ? (
              totalCounts > limit ? (
                `Showing ${startEntry} to ${endEntry} of ${totalCounts} entries`
              ) : null
            ) : (
              // If there are no entries
              <div className="no-entries">No data available</div>
            )}
          </div>
        </div>

        <div className="col-lg-6">
          {totalCounts > limit && (
            <Pagination
              count={Math.ceil(totalCounts / limit)}
              page={currentPage}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
              renderItem={(item) => (
                <PaginationItem
                  {...item}
                  components={{
                    previous: () => <span>Previous</span>,
                    next: () => <span>Next</span>,
                  }}
                />
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CommanPagination;

import { Link, useNavigate } from "react-router-dom";
import { React, useEffect, useState } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import { Modal, Button } from "react-bootstrap";
import "animate.css";
import ProfileSidebar from "./common/ProfileSidebar";
import CommanPagination from "./common/CommanPagination/CommanPagination";
import { gapi } from "gapi-script";
import axiosClient from "../Services/Axios/axios";
import Swal from "sweetalert2";
import dayjs from "dayjs";

const Campaign = () => {
  const [show, setShow] = useState(false);
  const [showscan, setShowscan] = useState(false);
  const handleClose = () => setShow(false);
  const videoshow = () => setShow(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [campaignData, setCampaignData] = useState();
  const [loading, setLoading] = useState(false);
  const handleClose1 = () => setShowscan(false);
  const cameracl = () => setShowscan(true);
  const navigate = useNavigate();

  const getAllEnrolledEvent = async () => {
    try {
      setLoading(true);
      const response = await axiosClient.get(
        `/campaigns/getMyCampaigns?language=en&pageSize=${limit}&pageNo=${currentPage}`
      );
      if (response.data && !response.data.error) {
        setCampaignData(response.data);
      } else {
        setCampaignData({
          data: { rows: [], totalElement: 0, totalPage: 0 },
        });
      }
    } catch (error) {
      console.error("Error fetching events:", error);
      setCampaignData({
        data: { rows: [], totalElement: 0, totalPage: 0 },
      });
    } finally {
      setLoading(false);
    }
  };
  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (result.isConfirmed) {
        setLoading(true);
        const response = await axiosClient.delete(
          `/campaigns/delete-campaign/${id}`
        );
        if (response.data && !response.data.error) {
          getAllEnrolledEvent(); // Refresh the data
          Swal.fire("Deleted!", response.data.message, "success");
        }
      }
    } catch (error) {
      console.error("Error fetching events:", error);
      setCampaignData({});
    } finally {
      setLoading(false); // Ensure loading is stopped in all cases
    }
  };

  useEffect(() => {
    getAllEnrolledEvent();
    window.scrollTo(0, 0);
  }, [currentPage, limit]);

 
  return (
    <>
      <div className="all_suport_bg manages_top">
        <section className="ser_v_araeea evnetsts profiles">
          <div className="container">
            <div className="bradcams mb_view_hide">
              <span className="bothss">
                <Link to="">My Account</Link>{" "}
                <img src="/images/brd_rowwo.png" alt="" /> Campaingns
              </span>
            </div>
            <div className="row">
              <div className="col-lg-3 mb_view_hide">
                <ProfileSidebar />
              </div>
              <div className="col-lg-9">
                <div className="entnts_tabss profff_eventss compearss">
                  <h4 className="mb-4">
                    Campaigns (
                    {campaignData?.data?.totalElement > 0
                      ? campaignData?.data?.totalElement
                      : 0}
                    ){" "}
                    <span className="ad_linksss">
                      <Link to="/add-campaign"> Create New Campaign</Link>
                    </span>
                  </h4>

                  <div className="content-wrapper">
                    {campaignData?.data?.rows.map((item, index) => {
                      console.log(item, "item");
                      return (
                        <div className="main_tabs_data mb-3" key={index}>
                          <div className="un_data_list" id="cam_list_parts">
                            <Link to="">
                              <div className="usr_mgss">
                                <img src={`${item.donation_images}`} alt="" />
                              </div>
                            </Link>
                            <div className="cntent_tx">
                              <div className="cam_dtl_contents">
                                <p>
                                  <strong>Title :</strong> {item.donation_title}
                                </p>
                                <p>
                                  <strong>Featured :</strong>{" "}
                                  {item.featured_status ? "Yes" : "No"}
                                </p>
                                <p>
                                  <strong>Goal :</strong> ${item.global_amount}
                                </p>
                                <p>
                                  <strong>Raised :</strong> $
                                  {item.till_donation_amount}
                                </p>
                                <p>
                                  <strong>Created At :</strong>{" "}
                                  {dayjs(item.created_at).format("DD-MMM-YYYY")}
                                </p>
                              </div>

                              <div className="cam_adress">
                                <div className="d_lists">
                                  <ul>
                                    <li>
                                      <Link
                                        to={""}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          navigate(
                                            `/update-campaign/${item.id}`,
                                            { state: item }
                                          );
                                        }}
                                      >
                                        {" "}
                                        <img
                                          src="/images/edit_views.png"
                                          alt=""
                                        />
                                      </Link>
                                    </li>
                                    {/* <li>
                                      <Link to="">
                                        {" "}
                                        <img
                                          src="/images/eye_views.png"
                                          alt=""
                                        />
                                      </Link>
                                    </li> */}

                                  {item.statusName === "Active" && (
                                      <li>
                                        <Link
                                          to={`/donate-details/${item.page_url}`} // Redirect to event detail page
                                        >
                                          <img
                                            src="/images/eye_views.png"
                                            alt=""
                                          />
                                        </Link>
                                      </li>
                                    )}
                                    <li>
                                      <Link
                                        to=""
                                        onClick={() => handleDelete(item.id)}
                                      >
                                        {" "}
                                        <img
                                          src="/images/ic_delets.png"
                                          alt=""
                                        />
                                      </Link>
                                    </li>
                                  </ul>
                                </div>

                                <div
                                  className={`ac_tvity green_tx_w ${
                                      item.statusName === "Rejected"
                                      ? "red_tx_w"
                                      : item.statusName === "Active"
                                      ? "green_tx_w"
                                      : item.statusName === "Pending"
                                      ? "goldan_tx_w"
                                      : ""
                                    }`}
                                >
                                  {item.statusName}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <CommanPagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      limit={limit}
                      setLimit={setLimit}
                      totalCounts={campaignData?.data?.totalElement}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Campaign;

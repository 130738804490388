import React, { useEffect, useRef, useState } from "react";

const TruncateLocation = ({ res }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showToggle, setShowToggle] = useState(false);
  const descriptionRef = useRef(null);

  useEffect(() => {
    if (descriptionRef.current) {
      
      if (descriptionRef.current.scrollHeight > 1) {
        setShowToggle(true);
      }
    }
  }, []);

  return (
    <>
      <div
        className={` ${isExpanded ? "" : "truncate1"}`} id="news_itemss"
        ref={descriptionRef}
        dangerouslySetInnerHTML={{
          __html: res.description,
        }}
      ></div>
      {showToggle && (
        <button
          className="see-more-btn"
          onClick={(e) => {
            e.stopPropagation(); // Prevent triggering the onClick of the parent
            setIsExpanded(!isExpanded);
          }}
        >
          {isExpanded ? "See Less" : "See More"}
        </button>
      )}
    </>
  );
};

export default TruncateLocation;

import { Link, useLocation, useNavigate } from "react-router-dom";
import { React, useEffect, useRef, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import CountUp from "react-countup";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Modal, Button } from "react-bootstrap";
import axiosClient from "../../Services/Axios/axios";
import { BeatLoader, ClipLoader } from "react-spinners";
import PhoneInput from "react-country-phone-input";
import { isValidPhoneNumber } from "libphonenumber-js";
import "react-phone-input-2/lib/style.css";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import FilterModal from "./FilterModal";
import moment from "moment-timezone";
import TruncateLocation from "../common/TruncateLocation";
const evntssslider = {
  margin: 0,
  responsiveClass: true,
  nav: true,
  dots: false,
  autoplay: false,
  navText: ["<", ">"],
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 1,
    },
    700: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};

const Login = () => {
  const [show, setShow] = useState(false);
  const location = useLocation();
  const handleClose = () => setShow(false);
  const videoshow = (event, id) => {
    event.preventDefault();
    setFormData({
      ...formData,
      event_id: id,
    });
    setShow(true);
  };

  const [bannerData, setBannerData] = useState([]);
  const [bannerImages, setBannerImages] = useState([]);
  const [tabInd, setTabInd] = useState(1);
  const [upcomingLimit, setUpcomingLimit] = useState(5);
  const [upcomingCount, setUpcomingCount] = useState(0);
  const [pastLimit, setPastLimit] = useState(2);
  const [pastCount, setPastCount] = useState(0);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState();
  const navigate = useNavigate();
  const [buttonloader, setButtonloader] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const todayDate = new Date().toISOString().split("T")[0];
  const [isAscendingOrder, setIsAscendingOrder] = useState();
  const [searchValue, setSearchValue] = useState();
  const fileInputRef = useRef();
  const [formData, setFormData] = useState({
    name: userData?.first_name ? userData?.first_name : "",
    email: userData?.email ? userData?.email : "",
    mobile: "",
    country_code: "+91",
    user_type: "1",
    communication_type: "1",
    event_id: "",
    user_id: userData ? userData?.id : "",
  });
  const [errors, setErrors] = useState({
    fname: "",
    email: "",
    phone: "",
  });
  const modalRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setFilterModalOpen(false);
    }
  };

  const handlePhoneChange = (value, country) => {
    setFormData({
      ...formData,
      fullPhone: value,
      mobile: value.slice(country.dialCode.length), // Extract mobile number without country code
      country_code: `+${country.dialCode}`,
      country: country.iso2, // Update the country code
    });
  };

  const hundleFilterModal = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setFilterModalOpen(false);
      // setFilterModalOpen(!filterModalOpen);
    }
  };
  const validate = () => {
    const errors = {};
    // Name validation
    if (!formData.name.trim()) {
      errors.fname = "Name is required.";
      setButtonloader(false);
    } else if (formData.name.length > 50) {
      errors.fname = "Name must be at least 50 characters.";
      setButtonloader(false);
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim()) {
      errors.email = "Email ID is required.";
      setButtonloader(false);
    } else if (!emailRegex.test(formData.email)) {
      errors.email = "Invalid email format.";
      setButtonloader(false);
    } else if (formData.email.length > 40) {
      errors.email = "Email must be at least 40 characters.";
      setButtonloader(false);
    }

    // Phone number validation
    if (!formData.mobile.trim() || !formData.country_code) {
      errors.phone = "Phone number and country code are required.";
      setButtonloader(false);
    }
    //  else if (
    //   !isValidPhoneNumber(
    //     `${formData.country_code}${formData.mobile}`,
    //     formData.country_code.replace("+", "")
    //   )
    // ) {
    //   errors.phone = "Invalid phone number for the selected country.";
    //   setButtonloader(false);
    // }

    setErrors(errors);

    return Object.keys(errors).length === 0; // Returns true if no errors
  };

  const handleProfile = async () => {
    try {
      const response = await axiosClient.get("/users/getByID");
      const userData = response?.data?.data;

      if (response?.data?.error || !userData) return;

      localStorage.setItem("userData", JSON.stringify(userData));
    } catch (error) {
      console.error("Error fetching profile:", error);
      localStorage.clear();
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonloader(true);
    if (validate()) {
      try {
        const userTimeZone = moment.tz.guess();
        const data = await axiosClient.post(`/events/booking`, {
          ...formData,
          user_type: Number(formData.user_type),
          communication_type: Number(formData.communication_type),
          timezone: userTimeZone,
        });
        if (!data.data.error) {
          Swal.fire({
            icon: "success",
            title: "Thanks for your booking",
            showConfirmButton: false,
            timer: 1500,
          });
          handleClose();
          setFormData({
            ...formData,
            name: "",
            email: "",
            mobile: "",
            fullPhone: "",
            country_code: "+91",
            user_type: "1",
            communication_type: "1",
          });
          if (fileInputRef.current) {
            fileInputRef.current.value = ""; // Clearing the file input
          }
          handleProfile();
        }
        setButtonloader(false);

        if (userData) {
          navigate("/my-events");
        }
      } catch (error) {
        console.log(error);
        setButtonloader(false);
        Swal.fire({
          icon: "error",
          title: error.response.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? (checked ? value : "") : value, // Update logic for checkboxes
    }));
  };

  const getAllData = async () => {
    try {
      setLoading(true);
      const getBannerContent = await axiosClient.get(
        `/events/getBannerEvents?language=en`
      );
      if (!getBannerContent.data.error) {
        setBannerData(getBannerContent.data.BannerContent);
        setBannerImages(getBannerContent.data.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getUpcomingData = async () => {
    try {
      const upcomingData = await axiosClient.get(
        `/events/getUpComingEvents?language=en&pageSize=${upcomingLimit}&pageNo=1`
      );
      if (!upcomingData.data.error) {
        setUpcomingCount(upcomingData.data.data.totalElement);
        setEvents(upcomingData.data.data.rows);
      } else {
        setEvents([]);
      }
    } catch (error) {
      console.log(error);
      setEvents([]);
    }
  };

  const getPastEvents = async () => {
    try {
      const pastData = await axiosClient.get(
        `/events/getPastEvents?language=en&pageSize=${pastLimit}&pageNo=1`
      );
      if (!pastData.data.error) {
        setPastCount(pastData.data.data.totalElement);
        setEvents(pastData.data.data.rows);
      } else {
        setEvents([]);
      }
    } catch (error) {
      console.log(error);
      setEvents([]);
    }
  };

  useEffect(() => {
    getAllData();
    const userData = localStorage.getItem("userData");
    if (userData !== null) {
      setUserData(JSON.parse(userData));
    }
  }, []);

  useEffect(() => {
    if (tabInd === 1 && upcomingLimit) {
      getUpcomingData();
    } else {
      getPastEvents();
    }
  }, [tabInd, upcomingLimit, pastLimit]);

  useEffect(() => {
    setFormData({
      ...formData,
      user_id: userData ? userData?.id : "",
      name: userData?.first_name
        ? `${userData?.first_name} ${
            userData?.last_name ? userData?.last_name : ""
          } `
        : "",
      email: userData?.email ? userData?.email : "",
      mobile: userData?.mobile ? userData?.mobile : "",
      fullPhone: userData?.mobile ? userData?.mobile : "",
    });
  }, [userData]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getSearchingData = async (e) => {
    e.preventDefault();
    setFilterModalOpen(false);
    setStartDate("");
    setEndDate("");
    setSearchValue("");
    setIsAscendingOrder("");

    try {
      // Build query string dynamically
      const queryParams = new URLSearchParams({
        language: "en",
        pageSize: 10,
        pageNo: 1,
        isAscendingOrder,
        ...(searchValue && { location: searchValue }),
        ...(startDate && { startDate }),
        ...(endDate && { endDate }),
      });

      const url = `/events/${
        tabInd === 1 ? "getUpComingEvents" : "getPastEvents"
      }?${queryParams.toString()}`;

      const data = await axiosClient.get(url);

      if (!data.data.error) {
        setEvents(data.data.data.rows);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (filterModalOpen) {
      document.addEventListener("mousedown", hundleFilterModal);
    } else {
      document.removeEventListener("mousedown", hundleFilterModal);
    }

    // Cleanup on component unmount
    return () => {
      document.removeEventListener("mousedown", hundleFilterModal);
    };
  }, [filterModalOpen]);

  if (loading) {
    return (
      <div className="loader">
        <BeatLoader color="#097C81" />
      </div>
    );
  }
  return (
    <>
      <Modal
        className="all_supportss sm_sizess"
        id="registeress"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="fromss_boxx">
            <div className="titalss">
              <h3>Register Now</h3>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="unfildss" id="rediiobnt">
                <p>Register now for this event as :</p>
                <div className="rdo_boxx">
                  <p>
                    <input
                      type="radio"
                      id="test1"
                      name="user_type"
                      value="1" // Set specific value
                      onChange={(e) => handleChange(e)}
                      checked={formData.user_type === "1"} // Bind checked state
                    />
                    <label htmlFor="test1">Participant</label>
                  </p>
                  <p>
                    <input
                      type="radio"
                      id="test2"
                      name="user_type"
                      value="2" // Set specific value
                      onChange={(e) => handleChange(e)}
                      checked={formData.user_type === "2"} // Bind checked state
                    />
                    <label htmlFor="test2">Volunteer</label>
                  </p>
                </div>
              </div>
              <div class="form-group">
                <label>
                  Name<span className="red">*</span>
                </label>
                <input
                  type="text"
                  class={`form-control ${errors.fname ? "input-error" : ""}`}
                  name="name"
                  placeholder="Enter Name"
                  value={formData.name}
                  onChange={(e) => {
                    setErrors({ ...errors, fname: "" });
                    handleChange(e);
                  }}
                />
                {errors.fname && <div className="error">{errors.fname}</div>}
              </div>

              <div class="form-group">
                <label>
                  Email ID<span className="red">*</span>
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.email ? "input-error" : ""
                  }`}
                  name="email"
                  placeholder="Enter Email ID"
                  value={formData.email}
                  onChange={(e) => {
                    setErrors({ ...errors, email: "" });
                    handleChange(e);
                  }}
                  readOnly={userData?.email ? true : false}
                />
                {errors.email && <div className="error">{errors.email}</div>}
              </div>

              <div class="form-group">
                <label>
                  Phone Number<span className="red">*</span>
                </label>
                <PhoneInput
                  className={` react-tel-input ${
                    errors.phone ? "input-error" : ""
                  }`}
                  country={formData.country || "in"} // Default to 'in' or use stored country
                  value={formData.fullPhone || ""}
                  onChange={(value, country) =>
                    handlePhoneChange(value, country)
                  }
                  placeholder="Enter Phone Number"
                  ref={fileInputRef}
                  inputProps={{
                    name: "phoneNumber",
                    required: true,
                    autoFocus: true,
                  }}
                />
                {errors.phone && <div className="error">{errors.phone}</div>}
              </div>
              <div className="form-group">
                <div className="ck_titalss">
                  <h3>Choose your best way for communication</h3>
                </div>
                <div className="row">
                  {["1", "2", "3"].map((value, index) => (
                    <div className="col-lg-4 col-4" key={index}>
                      <div className="check_txtx" id="checkox">
                        <input
                          id={`check${value}`}
                          name="communication_type"
                          type="checkbox"
                          value={value}
                          checked={formData.communication_type === value} // Single selection logic
                          onChange={(e) =>
                            setFormData((prevData) => ({
                              ...prevData,
                              communication_type: e.target.checked ? value : "", // Update state
                            }))
                          }
                        />
                        <label htmlFor={`check${value}`}>
                          {value === "1"
                            ? "Telegram"
                            : value === "2"
                            ? "Phone Call"
                            : "Email"}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div class="form-group mt-4">
                <button
                  type="submit"
                  value="Register"
                  class="sub_mitess"
                  disabled={buttonloader}
                >
                  {buttonloader ? (
                    <ClipLoader color="#fff" size={"25"} />
                  ) : (
                    "Register"
                  )}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      <section
        className="ser_v_araeea green_bg eventss_pgs manages_top"
        id="about_pgs"
      >
        <img src="/images/flo_partss.png" className="flo_partss" alt="" />
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="al_sprts_pages">
                {bannerData.name && <h3>{bannerData.name}</h3>}
              </div>
            </div>
            <div className="col-lg-6">
              {bannerData.sub_title && (
                <div className="cnt_datas mb_view_hide">
                  {bannerData.sub_title}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="ser_v_araeea abt_mgeess_bx evnt_slisder allss">
        <div className="container">
          <OwlCarousel
            className="slider-items owl-carousel"
            id="home_sliserss"
            {...evntssslider}
          >
            {bannerImages.map((res, i) => {
              const isPastEvent = dayjs(res.event_date).isBefore(
                dayjs(),
                "day"
              );
              return (
                <div style={{ cursor: "pointer" }}>
                  <Link to={`/events-details/${res.page_url}`}>
                    <div className="item" key={i}>
                      <div className="sld_partsss">
                        <div className="upcm_bxx">
                          {" "}
                          {isPastEvent ? "Past Events" : "Upcoming Events"}
                        </div>

                        <img src={res.image} className="mg_ngr" alt="" />

                        <div className="over_cntsst">
                          <div className="datss">
                            <img src="/images/uil_calender.png" alt="" />{" "}
                            {dayjs(res.event_date).format("DD, MMMM YYYY")}
                          </div>
                          <h2>{res.title}</h2>
                          <p>{res.short_title}</p>
                          <p>
                            <img
                              src="/images/ic_sharp-location-on.png"
                              alt=""
                            />
                            {/* {res.event_location} */}
                            {res.event_location.length > 60 ? (
                              <TruncateLocation
                                res={{ description: res.event_location }}
                              />
                            ) : (
                              res.event_location // Render the full location if it's 60 characters or less
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </OwlCarousel>
        </div>
      </section>

      <section className="ser_v_araeea pt-0 evnetsts">
        <div className="container">
          <div className="entnts_tabss">
            <Tabs>
              <div className="tabs_list">
                <TabList>
                  <Tab onClick={() => setTabInd(1)}>Upcoming Events</Tab>
                  <Tab onClick={() => setTabInd(2)}>Past Events</Tab>
                </TabList>
                <div
                  className="fillerss"
                  onClick={() => setFilterModalOpen(!filterModalOpen)}
                >
                  <img src="/images/mage_filter.png" alt="" />
                </div>
              </div>
              {filterModalOpen && (
                <div className="filtterr_boxxss" ref={modalRef}>
                  <div className="lst_araes" id="rediiobnt">
                    <div class="rdo_boxx">
                      <p>
                        <input
                          type="radio"
                          id="test1"
                          name="user_type"
                          value="1"
                          onChange={(e) => setIsAscendingOrder(e.target.value)}
                        />
                        <label for="test1">Sort By- Newest</label>
                      </p>
                      <p>
                        <input
                          type="radio"
                          id="test2"
                          name="user_type"
                          value="0"
                          onChange={(e) => setIsAscendingOrder(e.target.value)}
                        />
                        <label for="test2">Sort By- Last Update</label>
                      </p>
                    </div>
                  </div>

                  <div>
                    <div className="lst_araes">
                      <label>Start Date</label>
                      <input
                        type="date"
                        name="startDate"
                        className="tx_input_area"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        placeholder="Select Date"
                      />
                    </div>
                    <div className="lst_araes">
                      <label>End Date</label>
                      <input
                        type="date"
                        name="endDate"
                        className="tx_input_area"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        min={startDate} // Ensures endDate cannot be earlier than startDate
                        placeholder="Select Date"
                      />
                    </div>
                  </div>

                  <div className="lst_araes marge">
                    <label>Location</label>
                    <input
                      type="text"
                      name=""
                      className="tx_input_area"
                      placeholder="Search Location"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <img src="/images/search_g.png" alt="" />
                  </div>

                  <div className="lst_araes marge">
                    <button
                      type="submit"
                      onClick={getSearchingData}
                      className="ap_ly_btn"
                      value="Apply"
                    >
                      Apply
                    </button>
                  </div>
                </div>
              )}

              <div className="content-wrapper">
                <TabPanel>
                  <div className="main_tabs_data">
                    {events.map((res) => {
                      const findData = userData?.EventBooking?.some(
                        (item) => item.id === res.id
                      );

                      const userTimeZone = moment.tz.guess();
                      // Convert UTC time to user's local time in "10:00 AM" format
                      const eventStartTime = moment
                        .tz(
                          `${res.event_date} ${res.event_time}`,
                          "YYYY-MM-DD hh:mm A",
                          "UTC"
                        )
                        .tz(userTimeZone)
                        .format("hh:mm A");

                      const eventEndTime = moment
                        .tz(
                          `${res.event_date} ${res.event_time_end}`,
                          "YYYY-MM-DD hh:mm A",
                          "UTC"
                        )
                        .tz(userTimeZone)
                        .format("hh:mm A");

                      return (
                        <div className="un_data_list">
                          <Link to={`/events-details/${res.page_url}`}>
                            <div className="usr_mgss">
                              <img src={res.image} alt="" />
                            </div>
                          </Link>
                          <div className="cntent_tx">
                            <div className="dat_time">
                              <span className="datess">
                                <img src="/images/g_uil_calender.png" alt="" />
                                {dayjs(res.event_date).format("DD, MMMM YYYY")}
                              </span>
                              <span className="spacess">&nbsp;</span>
                              <span className="datess">
                                <img src="/images/tabler_clock.png" alt="" />{" "}
                                {eventStartTime} - {eventEndTime}
                              </span>
                            </div>
                            <Link to={`/events-details/${res.page_url}`}>
                              <h2>{res.title}</h2>
                            </Link>
                            <p>{res.short_title}</p>
                            <div className="adress">
                              <div className="adrss">
                                <img
                                  src="/images/g_ic_sharp-location-on.png"
                                  alt=""
                                />{" "}
                                {/* {res.event_location} */}
                                {res.event_location.length > 60 ? (
                                  <TruncateLocation
                                    res={{ description: res.event_location }}
                                  />
                                ) : (
                                  res.event_location // Render the full location if it's 60 characters or less
                                )}
                              </div>

                              <div className="red_mr">
                                {userData &&
                                (([8, 9].includes(userData.role_type) &&
                                  userData.profile_complete === 1) ||
                                  [5, 7].includes(userData.role_type)) ? (
                                  <Button
                                    variant="primary"
                                    onClick={(e) => videoshow(e, res.id)}
                                    disabled={findData}
                                  >
                                    {!findData ? (
                                      <Link to="" className="red_mr">
                                        Register Now{" "}
                                        <img
                                          src="/images/ar_right_icon.png"
                                          alt=""
                                        />
                                      </Link>
                                    ) : (
                                      <Link to="" className="red_mr">
                                        Already Registered
                                      </Link>
                                    )}
                                  </Button>
                                ) : (
                                  <Button
                                    variant="primary"
                                    onClick={(e) => {
                                      if (!userData) {
                                        videoshow(e, res.id);
                                      } else {
                                        Swal.fire({
                                          title:
                                            "KYB verification is required to register for this event. Please complete your KYB to proceed",
                                          icon: "warning",
                                          showCancelButton: false,
                                          showConfirmButton: true,
                                          // timer: 1500,
                                          confirmButtonColor: "#3085d6",
                                          cancelButtonColor: "#d33",
                                          confirmButtonText: "Ok",
                                        });
                                      }
                                    }}
                                  >
                                    <Link to="" className="red_mr">
                                      Register Now{" "}
                                      <img
                                        src="/images/ar_right_icon.png"
                                        alt=""
                                      />
                                    </Link>
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    {events && upcomingCount > upcomingLimit && (
                      <div className="col-lg-12 mt-5">
                        <div className="vei_als">
                          <Link
                            to=""
                            onClick={(e) => {
                              e.preventDefault();
                              setUpcomingLimit(upcomingLimit + 5);
                            }}
                          >
                            <span className="managess_bnt">View More </span>
                          </Link>
                        </div>
                      </div>
                    )}
                    {/* <CommanPagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      limit={limit}
                      setLimit={setLimit}
                      totalCounts={totalCounts}
                      totalPage={totalPage}
                    /> */}
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="main_tabs_data">
                    {events.map((res) => {
                      const userTimeZone = moment.tz.guess();
                      // Convert UTC time to user's local time in "10:00 AM" format
                      const eventStartTime = moment
                        .tz(
                          `${res.event_date} ${res.event_time}`,
                          "YYYY-MM-DD hh:mm A",
                          "UTC"
                        )
                        .tz(userTimeZone)
                        .format("hh:mm A");

                      const eventEndTime = moment
                        .tz(
                          `${res.event_date} ${res.event_time_end}`,
                          "YYYY-MM-DD hh:mm A",
                          "UTC"
                        )
                        .tz(userTimeZone)
                        .format("hh:mm A");
                      return (
                        <div className="un_data_list">
                          <Link to={`/events-details/${res.page_url}`}>
                            <div className="usr_mgss">
                              <img src={res.image} alt="" />
                            </div>
                          </Link>
                          <div className="cntent_tx">
                            <div className="dat_time">
                              <span className="datess">
                                <img src="/images/g_uil_calender.png" alt="" />{" "}
                                {dayjs(res.event_date).format("DD, MMMM YYYY")}
                              </span>
                              <span className="spacess">&nbsp;</span>
                              <span className="datess">
                                <img src="/images/tabler_clock.png" alt="" />{" "}
                                {eventStartTime} - {eventEndTime}
                              </span>
                            </div>
                            <Link to={`/events-details/${res.page_url}`}>
                              <h2>{res.title}</h2>
                            </Link>
                            <p>{res.short_title}</p>
                            <div className="adress">
                              <div className="adrss">
                                <img
                                  src="/images/g_ic_sharp-location-on.png"
                                  alt=""
                                />{" "}
                                {/* {res.event_location} */}
                                {res.event_location.length > 60 ? (
                                  <TruncateLocation
                                    res={{ description: res.event_location }}
                                  />
                                ) : (
                                  res.event_location // Render the full location if it's 60 characters or less
                                )}
                              </div>
                              <div className="red_mr">
                                <Button
                                  variant="primary"
                                  onClick={() => videoshow(res.id)}
                                >
                                  {/* <Link to="" className="red_mr">
                                  Register Now{" "}
                                  <img src="/images/ar_right_icon.png" alt="" />
                                </Link> */}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    {events && pastCount > pastLimit && (
                      <div className="col-lg-12 mt-5">
                        <div className="vei_als">
                          <Link
                            to=""
                            onClick={(e) => {
                              e.preventDefault();
                              setPastLimit(pastLimit + 2);
                            }}
                          >
                            <span className="managess_bnt">View More </span>
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                </TabPanel>
              </div>
            </Tabs>
          </div>
        </div>
      </section>

      {/* <FilterModal show={filterModalOpen} handleClose={hundleFilterModal} /> */}
    </>
  );
};

export default Login;
